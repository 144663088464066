/**
 * app.search
 *
 * This Module is used to manage the category listing page and handle the paging
 * of the product grid. Continuous scroll has been implemented and has replaced
 * any manual paging that has existed in the past.
 *
 * Needed Global Variables - itemsDisplayed - pageSize - loaded - startIndex
 *
 * @param app
 * @param $
 */
(function(app, $) {
	var $cache = {};
	var init = false;
	var pageFirstLoad = true;
	app.search = {	
		/**
		 * Initialization
		 * Init the cache, events, and any other modules that are dependent
		 * on this module. We also check to see if we need to load up any
		 * pages automatically by looking at the hash map params.
		 */
		init : function() {
			if(!init){
				
				init = true;
				$('.pagination').hide();
				app.search.initCache();
				app.search.initEvents();

				app.product.compare.init();
				app.product.tile.init();
				app.gridswitcher.init();

				var hashMap = app.search.getHash();

				/**
				 * If the hashmap has a variable named display and it is greater
				 * than the numbe of products loaded, lets load up the difference
				 */
				
				if (hashMap.display && hashMap.display > itemsDisplayed) {
					//app.search.loadInit(parseInt(hashMap.display) - itemsDisplayed);
				}
			}
			
		},

		/**
		 * Initialize the cache object
		 *
		 * The cache object contains attributes and properties that are used
		 * repeatedly throughout this module.
		 */
		initCache : function() {
			var productGrid = $('#search-result-items');
			var pageSize = productGrid.length && typeof productGrid.attr('data-page-size') !== 'undefined' ? parseInt(productGrid.attr('data-page-size')) : 50;
			
			$cache = {
				main : $('#main'), //Main location where the products are appended to
				loading : false, //Is this module currently loading products?
				backToTop : $('.back-to-top'), //Back to Top Button
				previousBodyHeight : parseInt($('body').height()), //Height of the body before the last product addition
				bodyHeight : parseInt($('body').height()), //Current Body Height
				backToTopButtonThreshold: 500, //Threshold for when to show the Back to Top button, pixels from the top
				pageSize: pageSize  //Number of items per page
			};
		},

		/**
		 * Initialize related events
		 */
		initEvents : function() {

			/**
			 * Start watching for page scroll to upload new products when needed
			 */
			app.search.observerInit();

			/**
			 * Any time the user scrolls we  need check to see if any
			 * actions need to occur.
			 */
			$(window).on('scroll', function() {
				var scroll = parseInt($(document).scrollTop());
				app.search.scrollEvent(scroll);
			});

			/**
			 * Reinit cache variables after document finished upload
			 *
			 */
			$(window).on('load', function() {
				app.search.initCache();
			});


			/**
			 * Checkboxes for the compare functionality
			 * On Click either add ore remove the items to be compared.
			 */
			$cache.main.on("click", "input[type='checkbox'].compare-check", function (e) {
				var cb = $(this);
				if($(".travel-guide-landing").length && $(this).parents(".travel-guide-landing").length){
					var tile = cb.parents(".product");
					var func = this.checked ? app.product.compare.addProduct : app.product.compare.removeProduct;
					var itemImg = tile.find("img").first();
					func({
						itemid : tile.data("itemid"),
						uuid : tile[0].id,
						img : itemImg,
						name : tile.find('a').attr('title')
					});
				} else {
					var tile = cb.closest(".product-tile");
					var func = this.checked ? app.product.compare.addProduct : app.product.compare.removeProduct;
					var itemImg = tile.find("div.product-image a img").first();
					func({
						itemid : tile.data("itemid"),
						uuid : tile[0].id,
						img : itemImg,
						name : tile.find('.name-link').attr('title')
					});
				}
				
			});


			/**
			 * Main Product click event,
			 * need to add the UUID to the hashmap so that we can
			 * find the product again when we come back to this page
			 */
			$cache.main.on("click", ".product-tile a:not('#quickviewbutton')", function (e) {
				var a = $(this);
				// get current page refinement values
				var wl = window.location;

				var params = (wl.hash.length > 1) ? app.util.getQueryStringParams(wl.hash.substr(1)) : {};

				// get the index of the selected item and save as start parameter
				var tile = a.closest(".product-tile");
				var idx = tile.data("idx") ? +tile.data("idx") : 0;

				if (!$(this).hasClass("swatch")) {
					// set the hash and allow normal action to continue
					params.uuid = tile.attr("id");
				}

				window.location.hash = $.param(params);
				a[0].hash = $.param(params);
			});

			/**
			 * Handle sorting change
			 * Do this by redirecting to the correct url, with new sorting attribute
			 * but remove the paging options
			 */
			$cache.main.on("change", ".sort-by select", function (e) {
				e.preventDefault();
				var refineUrl = $(this).find('option:selected').val();
				
				var trigger = false;
				
				// if(window.location.href.indexOf("nautica") > -1){
				// 	if( $(".plp-filter-features-list li a.active").length || $(".filters-wrapper .plp-filter-color-list li a.active").length || $(".refinement.size ul li a.active").length || ($(".js-plp-filter-category li a.active").length >= 1)){
				// 		trigger = true;
				// 	}
				// }
				
				// if(window.location.href.indexOf("kipling") > -1){
				// 	if( $(".refinement li.selected a").length || $(".plp-filter-features-list li.selected a").length || $(".filters-wrapper .plp-filter-color-list li.selected a") ||  $(".refinement.size ul li.selected a")  ||  ($(".js-plp-filter-category li.selected").length > 1) ) {
				// 		trigger = true;
				// 	}
				// }
				
				if (trigger) {
					$(document).trigger("startCategoryPlusRefinements");
					return false;
				} else {
					var uri = app.util.getUri(refineUrl);

					/**
					 * These params should be reset as they are controlled by
					 * continous scroll
					 */
					delete uri.queryParams['sz'];
					delete uri.queryParams['start'];

					document.location = uri.url + '?' + $.param(uri.queryParams);

					return;
				}
				
			})
			.on("change", ".items-per-page select", function (e) {
				var refineUrl = $(this).find('option:selected').val();
				var uri = app.util.getUri(refineUrl);
				window.location.hash = uri.query.substr(1);
				return false;
			});

			$cache.backToTop.on("click", function(e){
				e.preventDefault();
				$(window).scrollTop(0);
			});

		},

		/**
		 * Utility method to get the current Hash Map from the URL
		 */
		getHash : function() {
			var wl = window.location, hash = wl.hash;

			var hashParams = (hash.length > 1) ? app.util
					.getQueryStringParams(hash.substr(1)) : {};
			if (window.location.search.indexOf("isBackPackFinder=true") > -1) {
				hashParams["isBackPackFinder"] = "true";
			}
			return hashParams;
		},

		/**
		 * Load the initial set of products.
		 */
		loadInit: function (itemsNeeded) {

			if (itemsNeeded <= 0) {
				app.progress.hide();
				$cache.loading = false;
				return;
			}

			app.progress.show();

			$cache.loading = true;
			
			if (!$('.load-more-progress').length) {
				var loadingImage = $('<li></li>').addClass('grid-tile').addClass('factory-grid-tile').addClass('load-more-progress');
				$("#search-result-items").append(loadingImage);
			}

			var hashParams = app.search.getHash();

			if (hashParams.display != undefined || hashParams.sz != undefined) {
				var srule = hashParams.srule != undefined ? '&srule=' + hashParams.srule : '';
				hashParams.sz = (itemsNeeded < $cache.pageSize) ? itemsNeeded : $cache.pageSize;
			}

			$.ajax({
				type: 'GET',
				url: searchURL,
				data: 'trigger=loadmore&format=ajax&start=' + startIndex + '&' + $.param(hashParams),
				dataType: 'html',
				failure: function(){
					$cache.loading = false;
					app.progress.hide();
				},
				success: function(data){
					itemsDisplayed += $cache.pageSize;

					if (itemsDisplayed > itemTotal) {
						itemsDisplayed = itemTotal;
					}

					app.search.loadData(data, hashParams.uuid);
					app.search.loadInit(itemsNeeded - $cache.pageSize);
				}
			});

		},

		/**
		 *
		 */
		load : function(itemsDisplayedLocal, loaded) {
			if (itemsDisplayedLocal) {
				itemsDisplayed = itemsDisplayedLocal;
			}

			$cache.loading = true;
			app.search.observerStop();

			if (!$('.load-more-progress').length) {
				var loadingImage = $('<li></li>').addClass('grid-tile').addClass('factory-grid-tile').addClass('load-more-progress');
				$("#search-result-items").append(loadingImage);
			}

			loaded = loaded == undefined ? startIndex : loaded;

			var hashParams = app.search.getHash();

			if (hashParams.display != undefined || hashParams.sz != undefined) {
				var srule = hashParams.srule != undefined ? '&srule=' + hashParams.srule : '';
				hashParams.sz = itemsDisplayed - loaded < $cache.pageSize ? hashParams.display - loaded : $cache.pageSize;
				
				var searchParams = new URLSearchParams(window.location.search);

				searchParams.delete('start');
				searchParams.delete('sz');
				
				$.ajax({
					type: 'GET',
					url: searchURL,
					data: searchParams + '&trigger=loadmore&format=ajax&start=' + startIndex + '&' + $.param(hashParams),
					dataType: 'html',
					failure: function(){
						$cache.loading = false;
						app.search.observerStart();
					},
					success: function(data){
						app.search.loadData(data);
						$cache.loading = false;
						app.search.observerStart();
						//if lasy load triggered, remove last banner from first UL
						var lastProductBanner = $('.grid-tile-last');
						$(lastProductBanner[0]).remove();
					}
				});
			} else {
				$cache.loading = false;
				app.search.observerStart();
			}
		},

		loadData : function (data, uuid) {
			$('.load-more-progress').remove();
			
			$("#search-result-items").append($($.parseHTML(data)).find("ul").html());

			$(".product-image img").on('error', function(){
				$(this).attr('src', $(".js-no-image").val());
			});

			$('.pagination').hide();

			// re-initiate app.js script to find new elements in dom
			// only initialze on new product tiles
			var $container = $(".tiles-container").last();
			app.product.tile.init($container);

			index++;

			startIndex = startIndex + $cache.pageSize;
			itemsRemaining = itemTotal - itemsDisplayed;

			$('#search-end').html(itemsDisplayed);

			if (uuid != undefined && uuid != '') {
				app.search.scrollToProduct(uuid);
			}

			$cache.previousBodyHeight = $cache.bodyHeight;
			$cache.bodyHeight = parseInt($('body').height());

		},

		/**
		 *
		 */
		loadMore : function() {

			var hashParams = app.search.getHash();
			var searchParams = new URLSearchParams(window.location.search);
			var startPageIndex = parseInt(searchParams.get('start')) || 0;

			if (!pageFirstLoad && hashParams.display >= 0) {
				startIndex = parseInt(hashParams.display) + $cache.pageSize;
			} else {
				startIndex = startPageIndex + $cache.pageSize;
			}
			
			pageFirstLoad = false;
			
			itemsRemaining = itemTotal - startIndex;
			
			if(itemsRemaining > 0){
			
				itemsDisplayed = itemsDisplayed + (itemsRemaining > $cache.pageSize ? $cache.pageSize : itemsRemaining);
				
				hashParams.uuid = '';
				hashParams.display = startIndex;
				hashParams.sz = $cache.pageSize;
	
				window.location.hash = $.param(hashParams);
			
				app.search.load(itemsDisplayed);
			}

		},

		/**
		 * Intersection Observer scripts
		 */
		observer: null,
		observeElement: $('#more-search-results'),
        observerInit: function(){			
        	if(app.search.observeElement.length){
        		
        		var productGrid = $('#search-result-items');
        		var rootMargin = '100% 0px';
	    	 	
	    	 	app.search.observer = new IntersectionObserver(function(entry){
		            if(entry[0].isIntersecting){
		            	if(window.location.href.indexOf("kipling") == -1){
		    				if(!$(".js-plp-filter-category .active").length)
		    					app.search.loadMore();
		    			} else {
		    				if($(".js-plp-filter-category li.selected").length <= 1)
		    					app.search.loadMore();	
		    			}
		            }
		        },{
		            root: null,
		            rootMargin: rootMargin,
		            threshold: 0
		        });

		        app.search.observerStart();
	    	}
        },
        observerStart: function(){
        	if(app.search.observeElement.length){
	        	app.search.observer.observe(app.search.observeElement[0]);
	        }
        },
        observerStop: function(){
        	if(app.search.observeElement.length){
	        	app.search.observer.unobserve(app.search.observeElement[0]);
	        }
        },

		/**
		 *
		 */
		scrollEvent : function(scroll) {
			/**
			 * Show / Hide Back to Top Button when it's relevant
			 */
			if (scroll > $cache.backToTopButtonThreshold) {
				$cache.backToTop.show();
			} else {
				$cache.backToTop.hide();
			}
		},

		/**
		 *
		 */
		scrollToProduct : function(uuid) {
			var container = $('body'),
			scrollTo = $('#' + uuid);

			if (scrollTo.length > 0) {
				$(window).scrollTop(
				    parseInt(scrollTo.offset().top - $('#header').height() - container.offset().top)
				);
			}
		}
	};

}(window.app = window.app || {}, jQuery));
