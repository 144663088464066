(function () {
	//Override default document.write & document.writeln after DOM Ready to prevent malicious code from blanking page content.
	"use strict";
	document && $(function () {
		var body = $("body,html");

		document.write = function () {
			body && body.eq(0) && body.eq(0).append && $.each(arguments, function (i, v) {
				body.eq(0).append(v);
			});
		};
		document.writeln = document.write;
	});

	//Extend jQuery class with custom methods (IE jQuery.myMethod();)

	window.$ && $.fn.extend({
		"appendKeyValueToURL": function (address, keyN, valueN) {
			var args = Array.prototype.slice.call(arguments),
				url = (args && args[0]) || "",
				anchor = (url && /#/.test(url) && url.slice(url.indexOf("#") - url.length)) || "",
				values = args && args.slice && args.slice(1),
				params = values && (function () {
					var pairs = [];

					if (values.length % 2 !== 0) {
						values.push(null);
					}

					while (values.length) {
						pairs.push(values.splice(0, 2).join("="));
					}

					return pairs.join("&");
				}());
			return url.replace(/#.*?$/, "") + (url.indexOf("?") === -1 ? "?" : "&") + params + anchor;
		},
		"cookie": (function () {
			var enabled = window.navigator && navigator.cookieEnabled,
				Cookies = function () {
					return enabled;
				},
				_cache = {},
				_cacheString = "",
				_populateCache = function () {
					_cache = {};
					_cacheString = document.cookie;

					var cookiesArray = _cacheString.split("; ");
					for (var i = 0; i < cookiesArray.length; i++) {

						var index = cookiesArray[i].indexOf("=");
						var key = decodeURIComponent(cookiesArray[i].substr(0, index));
						var value = decodeURIComponent(cookiesArray[i].substr(index + 1));
						if (_cache[key] === undefined) {
							_cache[key] = value;
						}
					}
				};

			if (!enabled && /undefined/i.test(typeof enabled) && window.document) {
				document.cookie = "testCookie";
				enabled = /testCookie/.test(document.cookie);
			}

			Cookies.get = function (name) {
				document.cookie !== _cacheString && _populateCache();
				return _cache && _cache[name];
			};
			Cookies.set = function (options) {
				var completeCookie = "",
					c = $.extend(true, {
						"name": "testCookie",
						"value": "",
						"expires": options.value && (function () {
							var d = new Date();
							d.setDate(d.getDate() + 7);
							return d;
						}()) || -1,
						"permanent": false,
						"path": "/",
						"domain": "",
						"secure": false
					}, options);

				if (enabled) {
					completeCookie = c.name + "=" + (c.value + "").replace(/[^!#-+\--:<-[\]-~]/g, encodeURIComponent);

					completeCookie += c.path ? ";path=" + c.path : "";
					completeCookie += c.domain ? ";domain=" + c.domain : "";
					completeCookie += c.permanent ? ";expires=" + c.expires.toGMTString() : "";
					completeCookie += c.secure ? ";secure" : "";
					document.cookie = completeCookie;
				}
				return completeCookie;
			}
			Cookies.expire = function (name) {
				return this.set({
					"name": name
				});
			};

			return Cookies;
		}()),
		"unserialize": function (string) {
			/*	UNSERIALIZE function/method used to return object.
			 * 	ARGS:
			 * 		string		: key value sting written as url parameters. If url passed everything pre ? will be removed. Hash text is removed.
			 */
			if (typeof string != "string") {
				try {
					string = string.toString();
				} catch (err) {
					string = null;
				}
			}
			if (string == null || string.length <= 0) return false;
			else string = unescape(string);

			//Remove first character if is ? or space, replace subsequent ?'s, and split by "&"
			var paramArray = string.replace(/^[\?\s#]/gi, "").replace(/\?/gi, "&").split("&");
			var dataObj = {};
			$.each(paramArray, function (i, val) {
				try {
					var data = val.split("=");
					if (typeof data[0] != "undefined" && typeof data[1] != "undefined") dataObj[escape(data[0])] = data[1];
				} catch (err) {
					window.console && console.log("Unserialize method found bad pair. Url needs repair.",{
						"string": string,
						"paramArray": paramArray,
						"value": {
							i: i,
							val: val
						}
					});
				}
			});
			return dataObj;
		},
		"loader": function (addClass) {
			return $("<div>", {
				"class": "loading " + (addClass || "")
			})
		}
	});

	//Extend jQuery methods: $(selector).myMethod();
	window.$ && $.fn.extend({
		appendLoader: function (returnLoader) {
			var l = $.loader(),
				el = l && l.appendTo && l.appendTo(this);
			return returnLoader ? el : this;
		},
		write: function () {
			//Add write method to JQuery
			//Used to extend POWERREVIEWS object document write
			var $this = $(this);

			arguments.length && $.each(arguments, function (i, v) {
				$this.append(v);
			});
		}
	});



}());

//initialize global object if undefined
var _utils = (window && window._utils) || [];

(function () {
	"use strict";
	var i, queue = (_utils.slice && _utils.slice()) || [],
		u = function () {};

	//define initiators
	(function () {
		var features = function () {
				var success = [],
					props = this.features;

				connectElementsActions();

				//traverse and execute through all defined feature extensions
				for (i in props) {
					if (props.hasOwnProperty(i) && props[i].call && props[i].call()) {
						success.push(i);
					}
				}

				//window.console && console.log && console.log(success);
				return success;
			},
			w = {
				"$": $(document.documentElement),
				"x": null,
				"y": null,
				"listen": [],
				"abandon": function (index) {
					if (index !== null && index < w.listen.length) {
						var event = w.listen[index];
						event && event.fn && event.fn();
						w.listen[index] = null;
					} else {
						for (var x in w.listen) {
							if (w.listen.hasOwnProperty(x) && w.listen[x]) {
								if (w.listen[x].testFn({
									"x": w.x,
									"y": w.y
								})) {
									w.listen[x].fn();
									w.listen[x] = null;
								}
							}
						}
					}
					return true;
				},
				"onAbandon": function (testFn, cbFn) {
					i = null;
					if (testFn.call && cbFn.call) {
						i = w.listen.push({
							"testFn": testFn,
							"fn": cbFn
						}) - 1;
					}
					return i;
				}
			},
			body, system, connectElementsActions = function () {
				body = $("body, html");
				system = $("#system");
			};

		//PURPOSE: Use to group utils custom event listeners to window clicks rather then attaching multiple events to the window. Performance value.
		features.attachWindowClickListener = function () {
			var ns = ".utilsWindowClickListener";

			w && w.$ && w.$.off(ns).on("click" + ns, function (e) {
				w.x = e && e.pageX;
				w.y = e && e.pageY;

				w.x && w.y && w.abandon();
			});

			return true;
		};

		features.enableCheckedClass = function () {
			var ns = ".checkClass",
				selector ="[type=checkbox], [type=radio]",
				update = function () {
					$(selector).each(function() {
						var input = $(this);
						input.toggleClass("checked",input.is(":checked"));
					});
				};

			body.off(ns);
			body.on("change" + ns, selector, update);
			update();

			return !!body.find(selector).length;
		};

		//PURPOSE: Custom styled labels visible over input fields. Proformant and lightweight.
		features.enableOverLabels = function () {
			var ns = ".overlabel",
				ol = $(".ovlb"); //Target elements, parent of input fields

			if (ol && ol.length) {
				var inputs = ol.find("input[type=text], input[type=password], textarea, select"),
					labels = ol.find("label");

				//Remove previously attached events
				ol.off(ns);

				//Assign target element events
				ol.on("focusin" + ns, function () {
					$(this).addClass("focus");
				}).on("focusout" + ns, function () {
					$(this).removeClass("focus");
				}).on("mouseenter" + ns, function () {
					$(this).addClass("hover");
				}).on("mouseleave" + ns, function () {
					$(this).removeClass("hover");
				});

				//Assign general form events
				inputs.attr("autocomplete", "off").on("keyup" + ns + "keydown" + ns + " change" + ns + " blur" + ns, function () {
					var input = $(this),
						val = input.val();
					if (/^\s+|\s+$/i.test(val)) {
						val = val.replace(/^\s*|\s*$/i, "");
						input.val(val);
					}
				});

				//Assign specific form events
				ol.each(function () {
					var element = $(this),
						label = element.find("label"),
						input = element.find("input[type=text]:first, input[type=password]:first, textarea:first, select:first"),
						val = input && input.val && input.val();

					//Label events
					label.css({
						display: (val && val.length ? "none" : "block")
					}).on("contextmenu" + ns + " dblclick" + ns + " click" + ns, function (e) {
						e && e.preventDefault && e.preventDefault();
						input.focus();
					});
					input.on("keyup" + ns + " change" + ns + " blur" + ns, function () { //label visibility
						var val = $(this).val();
						label.css({
							"display": val.length ? "none" : "block"
						});
					});
				});
			}

			return true;
		};

		// PURPOSE: Navigation Menu Expander
		features.menuExpander = function () {
			var ns = ".menuExpander",
				nav = $("#navigation-menu"),
				hoverOutTimer,
				time = 500;

			nav.off(ns).on("mouseenter" + ns, "> li:has(.hoverMenu)", function () {
				clearTimeout(hoverOutTimer);
				nav.find(".hoverView").removeClass("hoverView");
				$(this).addClass("hoverView");
			}).on("mouseleave" + ns, "> li:has(.hoverMenu)", function () {
				var menu = $(this);
				hoverOutTimer = setTimeout(function() {
					menu.removeClass("hoverView");
				},time)
			});

			return !!nav.length;
		};

		//PURPOSE: Use RegExp to replace invalid values, can also pass replace with value
		features.autoInputFormatter = function () {
			var ns = ".autoInputFormatter",
				inputs = $(":input.replacePattern"),
				validator = function (exp, input) {
					var value = input && input.val(),
						modValue = exp && value && value.replace(exp, "");

					if (value && modValue !== value) {
						input.val(modValue);
					}
				};

			inputs.off(ns).each(function () {
				var input = $(this),
					pattern = input.attr("replace-pattern"),
					replaceVal = input.attr("replace-value") || "",
					regExp = pattern && new RegExp(pattern, "g"),

					time = 350,
					queue;

				regExp && input.on("change" + ns + " keyup" + ns, function () {
					clearTimeout(queue);
					queue = setTimeout(function () {
						validator(input, regExp, replaceVal);
					}, time);
				});
			});

			return !!inputs.length;
		};

		// PURPOSE: utility to add click action hiding of DOM elements
		features.contentCloser = function () {
			var ns = ".closer",
				action = $("<button>", {
					"class": "triggerCloser closerBtn",
					"aria-label": "Close button",
				}),
				generate = $(".dynamicCloser:not(:has(.triggerCloser))"),
				containers = $(".dynamicCloser"),
				targetClass = ".parentCloser",
				triggerClass = ".triggerCloser";

			//Button insertion when missing
			generate.each(function() {
				$(this).append(action.clone());
			});

			//Create actions
			containers.off(ns).each(function () {
				var container = $(this);

				container.on("click" + ns, triggerClass, function (e) {
					var button = $(this),
						parent = button.closest(targetClass);

					if (!parent || parent.length === 0) {
						parent = button.siblings(targetClass);
					}

					if (parent && parent.length) {
						if (parent.is(":visible")) {
							parent.slideUp();
							container.addClass("closed").removeClass("open");
						} else {
							parent.slideDown();
							container.addClass("open").removeClass("closed");
						}

						e.preventDefault();
					}
				});
			});

			return !!containers.length;
		};

		// PURPOSE: utility to add click action hiding of DOM elements
		features.systemControlsMenuExpand = function () {
			var ns = ".systemControlsMenuExpand",
				target = $("#pre-header"),
				trigger = ".systemControls > li:has(.menuTarget)",
				menuLis = $('#pre-header .systemControls li');
				
			//Create actions
			
			$(trigger).hover(function () {
				var li = $(this);
				
				menuLis.removeClass('menuHover');
				li.addClass("menuHover").addClass("js-hover");
			}, function () {
				var li = $(this);
				
				li.removeClass("js-hover");
				
				setTimeout(function () {
					if (!li.hasClass("js-hover")) {
						li.removeClass("menuHover");
					}
				}, 500);
			});
			
			target.off(ns).on("click" + ns, trigger + " > a:not(.ignore)", function(e) {
					e.preventDefault();
				}).on("click" + ns, ".closeTrigger", function(e) {
					$(this).closest("li").removeClass("menuHover");
				});

			target.find("li .closeTrigger").css("display","block");

			return !!target.length;
		};

		// PURPOSE: disable site search form when query is empty
		features.siteSearchValidation = function () {
			var ns = ".siteSearchValidation",
				forms = $("#header-search form");

			forms.off(ns).on("submit", function (e) {
				var form = $(this),
					hasVals = true;

				form.find("input").each(function () {
					var currentVal = $(this).val();
					hasVals = hasVals && !!(currentVal && currentVal.length);
				})

				!hasVals && e.preventDefault();
			});

			return !!forms.length;
		};

		// PURPOSE: utility to add click action hiding of DOM elements
		features.localeAutoSubmit = function () {
			var ns = ".localeAutoSubmit",
				target = $(".localeForm");

			target.off(ns).on("change", ":input", function () {
				var form = $(this).closest("form");

				form && form.submit();
			});

			target.find("button").remove();

			return !!target.length;
		};

		// PURPOSE: Flag header one page scroll
		features.pinHeader = function () {
			var ns = ".pinHeader",
				header = $("#header"),
				height = header.height(),
				offset = header && header.offset(),
				originalPadding = (offset && offset.top) || 0,
				classStr = "pinHeader";
			var isKipling = /kip/.test(window.location.href);
			if (!body.hasClass("noPin") && (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || isKipling)) {
				if(!$("#dwfrm_billing").length){
					u.windowPositionEvent({
						"position": {
							"top": 0,
							"bottom": (height || 0)
						},
						"trueFn": function () {
							//body.removeClass(classStr);
						},
						"falseFn": function () {
							//body.addClass(classStr);
						},
						"persistUntil": null
					});
				}
				
			}

			return !!header.length;
		};
		/*
		 * NOT USING Due to e-dialog having own validation
		 */
		features.enableSubscriptionValidation = function () {
			var ns = ".emailValidation",
				forms = $(".newsletterSubscriptionForm"),
				applyValidation = function () {
					var form = $(this);

					form && form.validate && form.validate({
						"onkeyup": false,
						"focusInvalid": false,
						"focusCleanup": true,
						"errorClass": "errorInvalid",
						"rules": {
							"email": {
								"required": true,
								"email": true
							}
						},
						"messages": {
							"email": {
								"required": "Please enter a valid email",
								"email": "Invalid Email Address"
							}
						},
						"showErrors": function () {
							this.defaultShowErrors();
							$('#emailHeaderSuccess').hide();
							$('.emailFooterSuccess').hide();

						}
					});
				};

//			forms.off(ns).each(function (i, form) {
//				_lazy.push(["loadReady",function () {
//					applyValidation.call(form);
//					$('#emailHeaderSuccess').hide();
//				}]);
//			}).on("submit" + ns, function (e) {
//				$('#emailHeaderError').hide();
//				$('#emailHeaderSuccess').hide();
//				$('.emailFooterError').hide();
//				$('.emailFooterSuccess').hide();
//
//				var form = $(this),
//					valid = form && form.valid && form.valid();
//
//				!valid && e && e.stopImmediatePropagation();
//			})

			return true;
		};
		 /**/
		features.enableSubscriptionAjax = function () {

			var errorMsg;
			var successMsg;
			var formsClass;
			var ns = "",
				forms = $(".newsletterSubscriptionForm"),
				xhr;

			forms.off(ns);
			forms.on("error" + ns, function () {
				//Setup subscription error notification
				$('#' + errorMsg +', .' + errorMsg).show();
				$('#' + successMsg +', .' + successMsg).hide();
				$('.' + formsClass + ' .ovlb').addClass('error');
			});

			forms.on("success" + ns, function () {
				//Setup subscription success notification
				$('#' + successMsg +', .' + successMsg).show();
				$('#' + errorMsg +', .' + errorMsg).hide();
				$('.emailAddress').val('');
				$('.' + formsClass + ' .ovlb').removeClass('error');
				$('.' + formsClass + ' .ovlb').removeClass('errorInvalid');
				//Letting Google Tag Manager now that we submitted the form successfully
				if (typeof dataLayer != "undefined"){
					dataLayer.push({'event': 'newsletter-success'});
				}
			});
			function successYes(){
				$('#' + successMsg +', .' + successMsg).show();
				$('#' + errorMsg +', .' + errorMsg).hide();
				$('.emailAddress').val('');
				$('.' + formsClass + ' .ovlb').removeClass('error');
				$('.' + formsClass + ' .ovlb').removeClass('errorInvalid');
				//Letting Google Tag Manager now that we submitted the form successfully
				if (typeof dataLayer != "undefined"){
					dataLayer.push({'event': 'newsletter-success'});
				}
			}
			forms.on("submit" + ns, function (event) {
				event.preventDefault()
				var form = $(this);
				//Determine if the form submission is in the header or footer to display appropriate message
				if($(this).hasClass('emailHeaderForm')){
					formsClass= 'emailHeaderForm';
					errorMsg = 'emailHeaderError';
					successMsg = 'emailHeaderSuccess';
				}else if($(this).hasClass('emailFooterForm')){
					formsClass= 'emailFooterForm';
					errorMsg = 'emailFooterError';
					successMsg = 'emailFooterSuccess';
				}
				xhr && xhr.abort && xhr.abort();
				//Setting the context as form will allow "this" to reference the current form as a jquery object.

				
				var button = $(this).find("button[type='submit']");
				if(button && button.data("recaptchav3") == true && typeof grecaptcha != "undefined"){
					 grecaptcha.ready(function() {
				          grecaptcha.execute(button.data("sitekey"), {action: 'submit'}).then(function(token) {
				        	  $("input[name='grecaptcharesponse']").val(token);
				        	  sendXHR(form)
				          });
				     });
				} else {
					sendXHR(form);
				}
				
				function sendXHR(form){
					$.ajax({
						"beforeSend": function () {
							if(!app.isMobileUserAgent)
							form.append(typeof $.fn.loader == "undefined" ? loader() : $.fn.loader());
						},
						"error": function (xhr, status) {
							status !== "abort" && form.trigger("error", [status]);
						},
						"success": function (json, status) {
							
							if(json.toString().indexOf('iframe') > -1){
								successYes();
							} else {
								if(typeof json == "string"){
									json = JSON.parse(json.trim());
								}	
								if (json && json.success === true){
									successYes();
								} else form.trigger("error", [(json && json.error) || status, json]);
							}
							
						},
						"complete": function () {
							if(!app.isMobileUserAgent)
							form.find(".loading").remove();
						},
						"cache": false,
						"context": form,
						"data": form.serialize(),					
						"timeout": 60000,
						"type": form.attr("method"),
						"url": typeof $.fn.appendKeyValueToURL == "undefined" ? appendKeyValueToURL(form.attr("action"), "format", "json") : $.fn.appendKeyValueToURL(form.attr("action"), "format", "json")
					});
				}

				function loader(addClass) {
					return $("<div>", {
						"class": "loading " + (addClass || "")
					})
				}
				function appendKeyValueToURL(address, keyN, valueN){
					var args = Array.prototype.slice.call(arguments),
					url = (args && args[0]) || "",
					anchor = (url && /#/.test(url) && url.slice(url.indexOf("#") - url.length)) || "",
					values = args && args.slice && args.slice(1),
					params = values && (function () {
						var pairs = [];

						if (values.length % 2 !== 0) {
							values.push(null);
						}

						while (values.length) {
							pairs.push(values.splice(0, 2).join("="));
						}

						return pairs.join("&");
					}());
					return url.replace(/#.*?$/, "") + (url.indexOf("?") === -1 ? "?" : "&") + params + anchor;
				}
				return false;
			});

			return !!forms.length;
		};

		// PURPOSE: Use the html5 data attributes to launch a modal box upon element click events
		features.html5DataModal = function () {
			var ns = ".html5DataModal",
				testArea = $("<div/>").css({
					position: 'fixed',
					top: '200%',
					left: '200%'
				}),
				targets = $(".modalLaunch"),
				targetsDL = $(".localeSelector"),
				xhr;

			var LaunchModal = function(e, options) {

				var _text = options.text,
				_el = options.el,
				_href = options.href,
				_url = options.url,
				_title = options.title,
				_buttons = options.buttons,
				_delayable = options.delayable,
				_impede = options.impede,
				_draggable = options.draggable,
				_resizable = options.resizable,
				_timeout = options.timeout,
				_timer = options.timer;

				xhr && xhr.abort();

				xhr = _url && $.ajax({
					"beforeSend": function () {
					},
					"complete": function () {
					},
					"context": _el,
					"data": {
						"format": "content"
					},
					"error": function () {
					},
					"success": function (data) {
						var _content = data && $("<div/>").append(data).appendTo(testArea);

						//format data with replacements
						_text && _text.length && _content.find(".replace").html($("<span/>", {"text": _text}));

						//format buttons
						if (_delayable) {
							_content.find(".cancelBtn").click(function () {
								clearTimeout(_timer);
							});
						}

						_content && _content.dialog({
							autoOpen: false,
							close: function (e, ui) {
							//	body.removeClass("noScroll");
								_content.dialog("destroy").remove();
							},
							dialogClass: (!_title || _title.length === 0 ? "ui-no-title " : ""),
							draggable: _draggable,
							modal: true,
							open: function () {
							//	body.addClass("noScroll");
								_content
									.off(ns)
									.on('click' + ns, '.cancelBtn, [data-modal-cancel]', function () {
										_content.dialog("close");
									})
									.on('click' + ns, '.continueBtn, [data-modal-continue]', function () {
										var href = _href;

										if (href) {
											window.location = href;
										}
									});
							},
							resizable: _resizable,
							title: _title,
							width: "auto"
						});

						//Jquery UI bug: correction for position calculation
						setTimeout(function () {_content.dialog('open');}, 100);
					},
					"timeout": parseInt(_timeout),
					"url": _url
				});

				e.preventDefault();

				if (_delayable) {
					_timer = setTimeout(function () {
						window.location = _href;
					},_timeout/2);
				}

			}

			targetsDL.off(ns).on("change" + ns, function(e) {

				var $select = $(this),
					$option = $select.find(':selected'),
					attribute = $option.attr('data-modal'),
					redirect = $option.attr('data-redirect'),
					elVal = attribute && attribute.split("|");

				if (elVal) {
					var _options = { };
					_options.el = $option;
					_options.href = elVal[1];
					_options.text = elVal[0];
					_options.url = elVal[2];
					_options.title = elVal[0];
					_options.buttons = [];
					_options.delayable = $option.hasClass("delayAction");
					_options.impede = $option.hasClass('impedeAction');
					_options.draggable = $option.attr("data-draggable") === "true";
					_options.resizable = $option.attr("data-resizeable") === "true";
					_options.timeout = $option.attr("data-timeout-milliseconds") || 8000;
					_options.timer = null;

					LaunchModal(e, _options);

					return false;
				}

				if (redirect) {
					window.location.href = redirect;
					return false;
				}
			});

			targets.off(ns)
				.on("click" + ns, function (e) {
					var el = $(this);

					var _options = { };
					_options.el = $(this);
					_options.href = el.attr("href");
					_options.text = el.text();
					_options.url = el.attr("data-href");
					_options.title = el.attr("data-title");
					_options.buttons = [];
					_options.delayable = el.hasClass("delayAction");
					_options.impede = el.hasClass('impedeAction');
					_options.draggable = el.attr("data-draggable") === "true";
					_options.resizable = el.attr("data-resizeable") === "true";
					_options.timeout = el.attr("data-timeout-milliseconds") || 8000;
					_options.timer = null;

					LaunchModal(e, _options);

					return false;
				});

			body.append(testArea);

			return !!targets.length;
		};

		u.features = features;
	}());

	//when scrolling reaches position (int), trigger callback.
	//windowPositionEvent & stickyFrameScroll
	(function () {
		var t = null,
			ns = ".windowPositionEvent",
			eQueue = [],
			dQueue = [],
			dQueueDelay = 125,
			w = {
				"$": $(window),
				"offset": {},
				"position": {
					"top": null,
					"bottom": null,
					"right": null,
					"left": null,
					"width": null,
					"height": null
				}
			},
			d = {
				"$": $(document),
				"width": function () {
					return this.$ && this.$.outerWidth();
				},
				"height": function () {
					return this.$ && this.$.outerHeight();
				}
			},
			body = $("body, html"),
			queueFn = function (queue, index) {
				w.offset = {
					"top": (window.pageYOffset === null || window.pageYOffset === undefined ? document.documentElement.scrollTop : window.pageYOffset),
					"left": (window.pageXOffset === null || window.pageXOffset === undefined ? document.documentElement.scrollLeft : window.pageXOffset),
					"width": (window.innerWidth === null || window.innerWidth === undefined ? document.documentElement.clientWidth : window.innerWidth),
					"height": (window.innerHeight === null || window.innerHeight === undefined ? document.documentElement.clientHeight : window.innerHeight)
				};
				w.position = {
					"top": w.offset.top,
					"bottom": w.offset.top + w.offset.height,
					"left": w.offset.left,
					"right": w.offset.left + w.offset.width,
					"width": w.offset.width,
					"height": w.offset.height
				};

				var e, eventTest = function (e, i) {
						if ((e.position.top < w.position.bottom && e.position.bottom > w.position.top) && (e.position.left < w.position.right && e.position.right > w.position.left)) {
							e.trueFn && e.trueFn.call && e.trueFn.call(e, w);
							if (e.persistUntil === true) {
								queue[i] = null;
							}
						} else {
							e.falseFn && e.falseFn.call && e.falseFn.call(e, w);
							if (e.persistUntil === false) {
								queue[i] = null;
							}
						}
					};
				//Perform Specific or All tests
				if (index >= 0) {
					e = queue && queue[index];
					e && eventTest(e, index);
				} else {
					for (i in queue) {
						if (queue.hasOwnProperty(i)) {
							e = queue[i];
							if (e) {
								//update position event if req
								if (e.update && e.update.call) {
									e.update.call(e);
								}
								eventTest(e, i);
							}
						}
					}
				}
			};

		//Scroll Trigger
		$(function () {
			w.$.on("scroll" + ns + " resize" + ns, function () {
				queueFn(eQueue);

				//reduce call load to every eighth of a second
				clearTimeout(t);
				t = setTimeout(function () {
					queueFn(dQueue);
				}, dQueueDelay);
			});
		});

		/*Window Position Event triggers true and fals functions when coordinates representing a box enter and exit the current browser window
		 * opts:
		 *	position = {top, bottom, right, left, positionU} (1 side required)
		 *	trueFn = function triggered when position visible (true or false fn required)
		 *	falseFn = function triggered when position not visible (true or false fn required)
		 *	persistUntil = Boolean, persist event test until true/false function fires. (default true)
		 */
		u.windowPositionEvent = function (opts) {
			var queue = opts && opts.delay ? dQueue : eQueue,
				i;

			if (opts && opts.position && (opts.position.top !== null || opts.position.bottom !== null || opts.position.right !== null || opts.position.left !== null) && (opts.trueFn || opts.falseFn)) {
				//extend defaults with arguments
				i = queue.push($.extend(true, {
					"position": {
						"top": -1,
						"bottom": d.height() + 1,
						"left": -1,
						"right": d.width() + 1
					},
					"persistUntil": true
				}, opts));
				//perform initial test
				queueFn(queue, i - 1);
			}
			return this;
		};

		//StickyFrameScroll easy way to call windowPositionEvent by just passing a JQuery DomElement representing the frame/coordinates to trigger the event.
		u.stickyFrameScroll = function (opts) {
			//target, frame (parent/coordinates)
			if (opts && (opts.target && opts.target.parent || opts.frame) && (opts.inFn || opts.outFn)) {
				var setup = {
					"position": {},
					"trueFn": function (w) {
						opts.inFn.call(opts.target, this.position, w.position);
					},
					"falseFn": function (w) {
						opts.outFn.call(opts.target, this.position, w.position);
					},
					"update": null,
					"persistUntil": null
				};

				opts.frame = opts.frame || opts.target.parent();

				if (opts.frame.top && opts.frame.bottom && opts.frame.right && opts.frame.left) {
					position = opts.frame;
				} else {
					setup.update = function () {
						var offset = opts.frame.offset();
						if (offset && this) {
							this.position = {
								"top": offset.top,
								"bottom": offset.top + opts.frame.height(),
								"right": offset.left + opts.frame.width(),
								"left": offset.left
							};
						}
					};
					setup.update();
				}

				u.windowPositionEvent(setup);
			}
			return this;
		};
	}());

	//Interface method used to maintain consistent call back functionality
	u.push = function () {
		for (var i = 0; i < arguments.length; i++) {
			//Allows a single call to traverse the search object to find the request method
			var req = arguments[i],
				prop = this,
				x = null;

			if (req) {
				if (req.name) {
					req.name = req.name.split(".").reverse();
					for (x = req.name.length - 1; x >= 0; x--) {
						prop = prop && req.name[x] ? prop[req.name[x]] : null;
					}
					if (prop) {
						prop.apply && prop.apply(this, req.args || []);
					} else {
						throw (new Error("property/method not found: " + req.name.reverse().join(".")));
					}
				} else if (req.length && typeof req[0] === "string") {

				}
			}
		}
		return this;
	};

	//onReady triggers callbacks once the object is complete & DOM Ready
	u.onReady = function (cb) {
		$ && $(function () {
			cb && cb.call && cb.call(u);
		});
		return this;
	};

	//reset global obj
	_utils = u;

	//Call init on DOM Ready
	window.$ && $(function () {
		u.features();
	});

	//trigger callbacks
	if (queue && queue.length) {
		u.push && u.push.apply && u.push.apply(u, queue);
	};


	return true;
}());
