$.fn.slickLazy = function(options){
 
    return this.each(function(){
        
    	var slider = $(this);

    	if(isIntersectionObserver){

			var observer = new IntersectionObserver(handleIntersect,{
				root: null,
				rootMargin: '0px 0px',
				threshold: 0
			});

			function handleIntersect(entry){
				if(entry[0].isIntersecting){
					try {
						slider.find('[loading="lazy"]').each(function(){
							if(!this.complete){
								var img = new Image();
								var picture = $(this).parent('picture');
								var webP = picture.find('source[type="image/webp"]');
								if(picture.length && isWebP && webP.length){
									img.src = webP.attr('srcset');
								} else {
									img.src = this.src;
								}
							}
						});
					} catch(e) {
						return false;
					}
					observer.unobserve(slider[0]);
				}
			};
			
			slider.on('init',function(event,slick){
				observer.observe(slider[0]);
			});

		}
		
		slider.slick(options);

    });
 
};