$(document).ready(function () {
	try {
        if ($("#lincWebChatConf").length > 0) {
            var lincWebChatEl = $("#lincWebChatConf").first(),
            	options = $(lincWebChatEl).data("options"); 

            // Identify loaded page
            var pageCategory = "",
            	currHref = window.location.href,
            	tempvalue1, tempvalue2;
            if ($("#main-homepg[role='main']").length > 0) {
                pageCategory = "home";
                options.pageContext = {
                    "pageCategory": pageCategory,
					"keywords" : keywords.split(" ")
                }
            } else if ($(".pt_product-search-result .primary-search-content.search-grid-main-content").length > 0) {
                pageCategory = "product_collection";
                tempvalue1 = $(".sort-container > .sort-li[selected='selected'");
                tempvalue2 = $(".breadcrumb-result-text .searchterm");
                options.pageContext = {
                    "pageCategory" : pageCategory,
                    "searchFilters" : [],
                    "sortBy" : ($(tempvalue1).length > 0) ? $(tempvalue1).first().text() : "",
                    "collectionId" : ($(tempvalue2).length > 0) ? $(tempvalue2).first().text().slice(1,-2) : "",
        			"keywords" : keywords.split(" "),
                };

                var filters = [],
                    filtersContainer = $(".col-left--searchoptions .refinement");
                for (var i = 0; i < filtersContainer.length; i++) {
                    var field = $(filtersContainer[i]).find("> ul li > a").first().data("refid");
                    if (!(field === null) && !(field === undefined)) {
                        var values = [];
                        $(filtersContainer[i]).find("> ul li > a").each(function(){values.push($(this).data("refval"));});
                        filters.push({
                            "field": field,
                            "values": values
                        });
                    }
                }
                options.pageContext.searchFilters = filters;
            } else if ($(".pt_product-search-result .primary-search-content").length > 0) {
                pageCategory = "product_category";
                tempvalue1 = $(".sort-container > .sort-li[selected='selected'");
                tempvalue2 = $(".cat-content-block");
                options.pageContext = {
                    "pageCategory" : pageCategory,
                    "searchFilters" : [],
                    "sortBy" : ($(tempvalue1).length > 0) ? $(tempvalue1).first().text() : "",
                    "categoryId" : ($(tempvalue2).length > 0) ? $(tempvalue2).first().data("id") : "",
                    "collectionId" : "",
        			"keywords" : keywords.split(" "),
                }

                var filters = [],
                    filtersContainer = $(".col-left--searchoptions .refinement");
                for (var i = 0; i < filtersContainer.length; i++) {
                    var field = $(filtersContainer[i]).find("> ul li > a").first().data("refid");
                    if (!(field === null) && !(field === undefined)) {
                        var values = [];
                        $(filtersContainer[i]).find("> ul li > a").each(function(){values.push($(this).data("refval"));});
                        filters.push({
                            "field": field,
                            "values": values
                        });
                    }
                }
                options.pageContext.searchFilters = filters;
                tempvalue1 = $(".breadcrumb > a:not(:last)");
                var array = ($(tempvalue1).length > 0) ? $(tempvalue1).last().attr("href").split("/") : [];
            	for (var i = array.length-1; i > 1;i--) {
            	    if (array[i].length > 0) {
            	    	options.pageContext.collectionId = (array[i] === "entry") ? "" : array[i];
            	        break;
            	    }  
            	};
            } else if ($(".pt_product-details").length > 0) {
            	pageCategory = "product_detail";
            	tempvalue1 = $("input[name='pid_master']");
            	tempvalue2 = $("input[name='pid']");
            	var tempvalue3 = $(".breadcrumb .last"),
            		tempvalue4 = $(".breadcrumb .pdp");
                options.pageContext = {
                    "pageCategory": pageCategory,
					"productsInfo" : [{
						"variantId" : ($(tempvalue1).length > 0) ? $(tempvalue1).first().attr("value") : "",
						"productId" : ($(tempvalue2).length > 0) ? $(tempvalue2).first().attr("value") : "",
						"name" :  ($(tempvalue3).length > 0) ? $(tempvalue3).first().text() : "",
						"category" : ($(tempvalue4).length > 0) ? $(tempvalue4).last().attr("title") : ""
					}],
					"categoryId" : "",
					"keywords" : keywords.split(" "),
                };
                
                var array = ($(tempvalue4).length > 0) ? $(tempvalue4).last().attr("href").split("/") : [];
            	for (var i = array.length-1; i > 1;i--) {
            	    if (array[i].length > 0) {
            	    	options.pageContext.categoryId = array[i];
            	        break;
            	    }  
            	};
            } else if (currHref.indexOf("/order-history") > -1) {
            	pageCategory = "order_history";
            	options.pageContext = {
                    "pageCategory": pageCategory,
					"orderIds" : [],
					"keywords" : keywords.split(" "),
                };
            	
            	if ($(".form-row.order-detail-options").length > 0) {
					pageCategory = "order_detail";
					options.pageContext.pageCategory = pageCategory;
					$(".order-information .order-number .value").each(function(index){
	            		options.pageContext.orderIds.push(parseInt(($(this).text())));
	            	});
				} else {
					$(".search-result-items .order-number .value").each(function(index){
	            		options.pageContext.orderIds.push(parseInt(($(this).text())));
	            	});
				}
            } else if (currHref.indexOf("/cart") > -1) {
            	pageCategory = "cart";
            	options.pageContext = {
                    "pageCategory": pageCategory,
					"keywords" : keywords.split(" "),
                };
            } else if (currHref.indexOf("/contactus") > -1) {
            	pageCategory = "customer_service";
            	options.pageContext = {
                    "pageCategory": pageCategory,
					"keywords" : keywords.split(" "),
                };
            } else if (currHref.indexOf("/stores") > -1) {
            	pageCategory = "stores";
            	options.pageContext = {
                    "pageCategory": pageCategory,
					"keywords" : keywords.split(" "),
                };
            } else if (currHref.indexOf("/our-story/") > -1) {
            	pageCategory = "about_us";
            	options.pageContext = {
                    "pageCategory": pageCategory,
					"keywords" : keywords.split(" "),
                };
            } else if (currHref.indexOf("/giftcard") > -1) {
            	pageCategory = "gift_card";
            	options.pageContext = {
                    "pageCategory": pageCategory,
					"keywords" : keywords.split(" "),
                };
            } else if (currHref.indexOf("/share") > -1) {
            	pageCategory = "friend_referral";
            	options.pageContext = {
                    "pageCategory": pageCategory,
					"keywords" : keywords.split(" "),
                };
            } else if (currHref.indexOf("/account") > -1) {
            	pageCategory = "my_account";
            	options.pageContext = {
                    "pageCategory": pageCategory,
					"keywords" : keywords.split(" "),
                };
            } else if (currHref.indexOf("/terms-and-conditions/") > -1) {
            	pageCategory = "terms_and_conditions";
            	options.pageContext = {
                    "pageCategory": pageCategory,
					"keywords" : keywords.split(" "),
                };
            } else if (currHref.indexOf("/privacy-policy-kip/") > -1) {
            	pageCategory = "privacy_policy";
            	options.pageContext = {
                    "pageCategory": pageCategory,
					"keywords" : keywords.split(" "),
                };
            } else if (currHref.indexOf("/frequently-asked-questions/") > -1) {
            	pageCategory = "faq";
            	options.pageContext = {
                    "pageCategory": pageCategory,
					"keywords" : keywords.split(" "),
                };
            }
            
            
            // 'linc_tracking', 'catalog' are not used as pageCategory value
            // Identify loaded page
            
            if (pageCategory.length > 0) {
            	var greetingsIntro = JSON.parse(options.greeting.intro),
	                greetingIntro = (Boolean(greetingsIntro[pageCategory]) && greetingsIntro[pageCategory].length > 0) ? greetingsIntro[pageCategory] : greetingsIntro["home"],
	                greetingsButtton = JSON.parse(options.greeting.get_started_button),
	                greetingButtton = (Boolean(greetingsButtton[pageCategory]) && greetingsButtton[pageCategory].length > 0) ? greetingsButtton[pageCategory] : greetingsButtton["home"]; 

	            options.greeting = {
	                "intro" : greetingIntro,
	                "get_started_button" : greetingButtton,
	            };
	            
	            //console.log("LincWebChatOptions", options);
            
				window.lincWebChatAsyncInit = function() {
					LincWebChat.init(options);
				};
                var loadOnce = false;
                function handleStart() {
                    if (loadOnce) {
                    return;
                    }
                    loadOnce = true;
                    if ('ontouchstart' in window) {
                    window.removeEventListener("touchstart", handleStart);
                    } else {
                    window.removeEventListener("mousemove", handleStart);
                    }
                    ( function (d, s, id) {
                        var js, ljs = d.body.children[d.body.children.length - 1];
                        if (d.getElementById(id)) { return ; }
                        js = d.createElement(s); js.id = id;
                        js.src = String($(lincWebChatEl).data("js-src"));
						js.defer = true;
                        ljs.parentNode.insertBefore(js, ljs);
                    }( document , "script" , "linc-web-chat-js" ));
                }
                if ('ontouchstart' in window) {
                    window.addEventListener("touchstart", handleStart);
                } else {
                    window.addEventListener("mousemove", handleStart);
                }
			}
        }
	}catch(e){}
});