//    app.js 2.0.0
//    (c) 2009-2012 Demandware Inc.
//    Subject to standard usage terms and conditions
//    Relies on jQuery, jQuery UI, jQuery validate, ...
//    For all details and documentation:
//    https://github.com/Demandware/Site-Genesis

// All java script logic for the application.
// The code relies on the jQuery JS library to be also loaded.

// semi-colon to assure functionality upon script concatenation and minification

// if jQuery has not been loaded, load from google cdn
if (!window.$) {
	var s = document.createElement("script");

	s.setAttribute("src", "//ajax.googleapis.com/ajax/libs/jquery/1.9.1/jquery.min.js");
	s.setAttribute("type", "text/javascript");
	document.getElementsByTagName("head")[0].appendChild(s);
}

// Application singleton and namespace object
// ------------------------------------------


window.removeExtraChars = function(val){
	if(val && val != null && val.length){
		return val.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '').replace(/([#0-9]\u20E3)|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?|[\u20E3]|[\u26A0-\u3000]|\uD83E[\udd00-\uddff]|[\u00A0-\u269F]/g, '');
	} else {
		return val;
	}
};

window.elementReady = function (selector) {
	return new Promise(function (resolve, _reject) {
		var el = document.querySelector(selector);
		if (el) {
			resolve(el);
			return;
		}
		new MutationObserver(function (_mutationRecords, observer) {
			Array.from(document.querySelectorAll(selector)).forEach(function (element) {
				resolve(element);
				observer.disconnect();
			});
		}).observe(document.documentElement, {
			childList: true,
			subtree: true
		});
	});
};

window.dataLayer = window.dataLayer || [];
var dataLayer = window.dataLayer;

var app = (function (app, $) {
	document.cookie="dw=1";
	/******** private functions & vars **********/

	// cache dom elements accessed multiple times
	// app.ui holds globally available elements
	function initUiCache() {
		app.ui = {
			searchContainer : $("#navigation .header-search"),
			printPage		: $("a.print-page"),
			reviewsContainer: $("#pwrwritediv"),
			main			: $("#main"),
			primary			: $("#primary"),
			secondary		: $("#secondary"),
			// elements found in content slots
			slots : {
				subscribeEmail : $(".subscribe-email")
			}
		};
	}
	
	
	function bfResponse (e) {
		if (e && e.data && typeof e.data == "string" && e.data.indexOf('clear-cart') >= 0) {
			// clear cart contents
			$('.mini-cart').addClass('mini-cart-empty');
			$('.mobile-bar-title').html('0');
			$('.mini-cart-content').remove();
		}
	}
	if (window.addEventListener) {
		// For standards-compliant web browsers
		window.addEventListener("message", bfResponse, false);
	}
	else {
		window.attachEvent("onmessage", bfResponse);
	}

	function initializeEvents() {
		var ns = ".initEvents",
			controlKeys = ["8", "13", "46", "45", "36", "35", "38", "37", "40", "39"];
		// apply dialogify event handler to all elements that match
		// one or more of the specified selectors
		$("body").off(ns).on("click" + ns, ".dialogify, [data-dlg-options], [data-dlg-action]", app.util.setDialogify)
		.on("keydown", "textarea[data-character-limit]", function(e) {
			var text = $.trim($(this).val()),
				charsLimit = $(this).data("character-limit"),
				charsUsed = text.length;

				if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
					e.preventDefault();
				}
		})
		.on("change keyup mouseup", "textarea[data-character-limit]", function(e) {
			var text = $.trim($(this).val()),
				charsLimit = $(this).data("character-limit"),
				charsUsed = text.length,
				charsRemain = charsLimit - charsUsed;

			if(charsRemain < 0) {
				$(this).val( text.slice(0, charsRemain) );
				charsRemain = 0;
			}

			$(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
		});

		//initialize search suggestions
		app.searchsuggest.init(app.ui.searchContainer, app.resources.SIMPLE_SEARCH);

		// print handler
		app.ui.printPage.off(ns).on("click" + ns, function () { window.print(); return false; });

		// add show/hide navigation elements
		$('.secondary-navigation .toggle').click(function(){
			$(this).toggleClass('expanded').next('ul').toggle();
		});

		// add generic toggle functionality
		$('.toggle').next('.toggle-content').hide();
		$('.toggle').click(function(){
			$(this).toggleClass('expanded').next('.toggle-content').toggle();
		});

		if($(".js-term-and-conditions").length){
			$("input[name$='addtoloyaltysystem']").each(function (i, elem) {
				if ($(elem).closest('.welcome-lightbox-reg').length == 0) {
					$(elem).parent().find('label').html($(".js-term-and-conditions").html());
				}
			});
		}
		// subscribe email box
		if (app.ui.slots.subscribeEmail.length > 0)	{
			app.ui.slots.subscribeEmail.focus(function () {
				var val = $(this.val());
				if(val.length > 0 && val !== app.resources.SUBSCRIBE_EMAIL_DEFAULT) {
					return; // do not animate when contains non-default value
				}

				$(this).animate({ color: '#999999'}, 500, 'linear', function () {
					$(this).val('').css('color','#333333');
				});
			}).blur(function () {
				var val = $.trim($(this.val()));
				if(val.length > 0) {
					return; // do not animate when contains value
				}

				$(this).val(app.resources.SUBSCRIBE_EMAIL_DEFAULT)
					   .css('color','#999999')
					   .animate({color: '#333333'}, 500, 'linear');
			});
		}

		try {
			$("#navigation-menu .shopcategory").each(function() {
				var $parent = $(this);
				if ($parent.find("li:not(.view-all)").length > 8) {
					$("<div class='shopcategory'><ul></ul></div>").insertAfter($parent);
					$parent.find("li:not(.view-all)").slice("8").appendTo($parent.next(".shopcategory").find("ul"));
				}
			});

			$(".skip-to-main-content").on('click keyup', function(e) {
				event.preventDefault();
				if ((e.type == "click") || (e.keyCode == 13)) {
					var $target = $(this.getAttribute('href'));
					if( $target.length ) {
						$('html, body').stop().animate({
							scrollTop: ($target.offset().top - $("#header").height()*1.2)
						}, 1000);
						$target.focus();
					}
				}
			});
			$(document).on("focusin", "#main", function(){
				$("#navigation-menu").trigger("mouseout");
			});
		} catch (e) {}

		//Check if browser being used is IE and check if IE Compatibility Mode is on
		var IEData = new IECompatibility();
	    //alert('IsIE: ' + IEData.IsIE + '\nVersion: ' + IEData.Version + '\nCompatability On: ' + IEData.IsOn);

	   	//if ($('html').hasClass('ie7')) {
	    if( IEData.IsIE && IEData.Version === '7') {
			if (document.cookie.indexOf("ie7message") === -1) {
				document.cookie="ie7message=1;domain=" + window.location.host + ";path=/";
				var options = {
					width: 620,
					title: ""
				};
				app.dialog.open({url:app.util.appendParamsToUrl(app.urls.pageInclude, {cid:'ie7-deprecated'}), options:options});
			}
			$("#ie7-wrapper").show();
		}

	    /**
	     * Open quick view handler
	     */



	    var ajaxFilter = {
	    	 categoryHtml: [],

	   		 start : function(url){

	   			if(window.location.href.indexOf("isBackPackFinder") > -1 && url.indexOf("isBackPackFinder") == -1){
	   				url = app.util.appendParamsToUrl(url,{"isBackPackFinder" : true});
	   			}

	   			window.history.pushState('page2', $("title").val(), url);
	   			app.progress.show( $("#main"));
	   			app.ajax.load({url:url, target: $("#main"), callback : this.after});
	   		 },


	   		startCategoryPlusRefinements: function () {
	            var urls = [];
	            var params = this.getPlusRefinementsParams();
	            this.startCategoryMode(params);
	        },
	        startCategoryMode: function (params) {

	            app.progress.show($("#main"));

	            this.categoryHtml = [];
	            var urls = [];
	            if(window.location.href.indexOf("isBackPackFinder") > -1){
	            	var params = params || {'categoryFilter': true, 'isBackPackFinder' : true};
	            } else {
	            	var params = params || {'categoryFilter': true};
	            }

	            var that = this;

	            if (!$(".js-plp-filter-category li.selected").length) {
	                var url = window.location.href;
	                if ($(".refinement li.selected a").length || $(".plp-filter-features-list li.selected a").length || $(".filters-wrapper .plp-filter-color-list li.selected a") ||  $(".refinement.size ul li.selected a")) {
	                    url = app.util.appendParamsToUrl(url, this.getPlusRefinementsParams());
	                }

	                
	                $.get(url, function (data) {
	                    data = /<body.*?>([\s\S]*)<\/body>/.exec(data)[1];
	                    $("#main").empty().append($($($.parseHTML(data))).find("#main").html());
	                    that.after();
	                });
	            } else {
	            	 $(".js-plp-filter-category li.selected > a").each(function () {
	                     var url = $(this).attr('href');
	                     url = app.util.appendParamsToUrl(url, params);
	                     urls.push(url);
	                 });
	                this.getCategoryHtml(urls);
	            }
	        },

	        getCategoryHtml: function (urls) {
	            var requests = [], that = this;
	            for (var i = 0; i < urls.length; i++) {
	                var deffered = $.ajax(urls[i]).done(function (data) {
	                    that.categoryHtml.push(data);
	                });
	                requests.push(deffered);
	            }
	            $.when.apply($, requests).then(function () {
	                that.renderCategoryHtml();
	            });
	        },

	        renderCategoryHtml: function () {

	            var resultBlocks = {};
	            $(".search-result-options .category-header").hide();
	            var k = 0;
	            var allProducts = {};

	            $.each(this.categoryHtml, function (i, v) {
	                v = /<body.*?>([\s\S]*)<\/body>/.exec(v)[1];
	                $($.parseHTML(v)).find(".search-result-items .grid-tile").each(function (ii, vv) {
	                    var productId = $(vv).find(".product-tile").data('itemid');
	                    if(typeof allProducts[productId] == "undefined"){
	                    	resultBlocks[k] = vv;
	                        k++;
	                        allProducts[productId] = true;
	                    }

	                });
	            });

	            $(".search-result-items").empty();

	            $.each(resultBlocks, function (i, v) {
	                $(".search-result-items").first().append(v);
	            });

	            var type = $(".sort-container option:selected").data('rule');

	            if(type == "price-low-to-high" || type == "price-high-to-low" || type == "product-name-ascending" || type == "product-name-descending"){
	            	var elm = $(".search-result-items .grid-tile");
	        	    var store = [];
	        	    for(var i=0, len=elm.length; i<len; i++){
						var row = elm[i];
						var sortnr = null;
	        	        if(type == "price-low-to-high" || type == "price-high-to-low"){
	        	        	sortnr = parseFloat($(elm[i]).find(".product-sales-price").text().replace("$", ""));
	        	        } else {
	        	        	sortnr = $(elm[i]).find(".name-link").attr("title");
	        	        }
	        	        store.push([sortnr, row]);
	        	    }

								if(type == "product-name-ascending") {
										store = store.sort();
								} else if(type == "product-name-descending") {
										store = store.sort().reverse();
								} else if(type == "price-low-to-high") {
										store = store.sort(function(a, b){return a[0] - b[0]});
								} else if(type == "price-high-to-low") {
										store = store.sort(function(a, b){return b[0] - a[0]});
								} else {
										store = store.sort();
								}

	        	    for(var j=0, len=store.length; j<len; j++){
	        	    	$(".search-result-items").first().append(store[j][1]);
	        	    }
	            }


	            var length = $(".grid-tile").length;
	            if($(".refinement .selected").length == 0){
	            	if($(".js-plp-filter-category .selected > a").length){
	            		length = 0;
		            	$(".js-plp-filter-category .selected > a").each(function(){
		            		var text = parseInt($(this).text().replace( /\D+/g, ''));
		            		if(text > 0)
		            			length += text;
		            	});
		            	if(length == 0){
		            		length = $(".grid-tile").length;
		            	}
	            	}

	            }

	            this.updateFilterClear();
	            this.after(true);
	        },
	        updateFilterClear : function(){

	        	if($(".refinement li.selected a").length){
	        		var html = "";
	        		$(".refinement li.selected a").each(function () {
	        			html += '<span class="breadcrumb-refinement-value '+$(this).parents(".refinement").find(".plp-toggle-header-name").text()+' ">';
	        			html += '<span class="filtered-ref-attribute">'+$(this).parents(".refinement").find(".plp-toggle-header-name").text()+'</span>';
	        			html += '<span class="filtered-ref-result">'+$(this).data("refval")+'</span>';
	        			html += '<a data-refid="'+$(this).data("refid")+'" data-refval="'+$(this).data("refval")+'" class="breadcrumb-relax" href="#"><div class="mobile-hidden selected-filters--cross"></div></a>';
	        			html += '</span>';
	        		});
	        		if(!$(".plp-secondary-fullwidth .refinedbreadcrumbs").length){
	        			html = '<div class="col-left--refinebreadcrumbs clearfix"><div class="breadcrumb refinedbreadcrumbs" itemprop="breadcrumb">'+html+'</div></div>';
	        			$(".col-left--filter-header").after(html);
	        		} else {
	        			$(".plp-secondary-fullwidth .refinedbreadcrumbs").empty().append(html);
	        		}

	        	}

	        },

	   		 after : function(isCategoryAfter){
	   			 	var isCategoryAfter = isCategoryAfter || false;
	   			 	app.progress.hide();
	   			 	app.product.tile.init();
	   				var tiles = $(".search-result-content").find(".product-tile");
	   				if (tiles.length===0) { return; }

		             if ((!isCategoryAfter && !app.isMobileUserAgent) || $(".search-result-options .sort-wrapper .select2-container").length < 1) {
		                     $.select2();
		             }

		             setTimeout(function () {
		            	 //imagesLoaded($(".search-result-content").find(".product-tile"), function(){
			   					$(".search-result-content").find(".product-tile")
			   						//.syncHeight()
			   						.each(function (idx) {
			   							$(this).data("idx",idx);
			   						});
			   				//});
		             }, 500);
		             
		             if ($('.js-plp-filter-category > li').length > 0) {
		            	 var title = '';
		            	 var breadcrumbs = '';
		            	 
		            	 $('.js-plp-filter-category > li.selected').each(function (i, elem) {
		            		//collect breadcrumbs
		            		var text = $(elem).data('breadcrumbs-link-text'),
		            			link = $(elem).data('breadcrumbs-link-url');
		            			 
		            		if (text !== undefined && text !== '' && link !== undefined && link !== '') {
		            			breadcrumbs += '<a href="'+link+'" class="last">'+text+'</a>';
		            		}
		            			 
		            		//collect titles
		            		var catTitle = $(elem).data('title-text').trim();
		            			 
		            		if (catTitle !== undefined && catTitle !== '') {
		            			title += '<span class="categoryTitleWrapInline">' + catTitle + '<span class="header-deli">|</span></span>';
		            		}
		            	 });
		            	 
		            	 if ($('.js-plp-filter-category .level3').length > 0) {
		            		 var breadcrumbsThirdLevel = '';
		            		 $('.js-plp-filter-category .level3 > li.selected').each(function (i, elem) {
			            		//collect breadcrumbs
			            		var text = $(elem).data('breadcrumbs-link-text'),
			            			link = $(elem).data('breadcrumbs-link-url');
			            			 
			            		if (text !== undefined && text !== '' && link !== undefined && link !== '') {
			            			breadcrumbsThirdLevel += '<a href="'+link+'" class="last">'+text+'</a>';
			            		}
			            			 
			            		//collect titles
			            		var catTitle = $(elem).data('title-text').trim();
			            			 
			            		if (catTitle !== undefined && catTitle !== '') {
			            			title += '<span class="categoryTitleWrapInline">' + catTitle + '<span class="header-deli">|</span></span>';
			            		}
			            	 });
		            		 
		            		 if (breadcrumbsThirdLevel !== '' && $('#main > .breadcrumb').length > 0) {
			            		 $('#main > .breadcrumb .last').remove();
			            		 $('#main > .breadcrumb').append(breadcrumbsThirdLevel);
			            	 } else if (breadcrumbs !== '' && $('#main > .breadcrumb').length > 0) {
			            		 $('#main > .breadcrumb .last').remove();
			            		 $('#main > .breadcrumb').append(breadcrumbs);
			            	 }
		            	 } else {
			            	 if (breadcrumbs !== '' && $('#main > .breadcrumb').length > 0) {
			            		 $('#main > .breadcrumb .last').remove();
			            		 $('#main > .breadcrumb').append(breadcrumbs);
			            	 }
		            	 }
		            	 
		            	 if (title !== '') {
		            		 if ($('.category-info--title').length > 0) {
		            			 $('.category-info--title').html(title).addClass('small-cat-header');
		            		 } else if ($('.category-header').length > 0) {
		            			 $('.category-header').html(title).addClass('small-cat-header');
		            		 }
		            		 $('#plp-category-info p').hide();
		            	 }
		             }
		             
		             $('.js-remove-after-ajax').hide();
		             
					 if($('#more-search-results').length){
						app.search.observeElement = $('#more-search-results');
						app.search.observerInit();
					 }

	   		 },
	   		 addUrlParam : function(url, param, paramVal){
	   			 var newAdditionalURL = "";
	   			    var tempArray = url.split("?");
	   			    var baseURL = tempArray[0];
	   			    var additionalURL = tempArray[1];
	   			    var temp = "";
	   			    if (additionalURL) {
	   			        tempArray = additionalURL.split("&");
	   			        for (var i=0; i<tempArray.length; i++){
	   			            if(tempArray[i].split('=')[0] != param){
	   			                newAdditionalURL += temp + tempArray[i];
	   			                temp = "&";
	   			            }
	   			        }
	   			    }

	   			    var rows_txt = temp + "" + param + "=" + paramVal;
	   			    return baseURL + "?" + newAdditionalURL + rows_txt;
	   		},
	   	 getPlusRefinementsParams: function () {
	   		 	if(window.location.href.indexOf("isBackPackFinder") > -1){
	   		 		var params = [];
	   		 		params["isBackPackFinder"] = true;
	   		 	} else {
	   		 		var params = [];
	   		 	}

	            params.categoryFilter = true;
	            params.srule = $(".sort-container option:selected").data('rule');

	            var k = 1;
	            $(".refinement").each(function () {
	                var $el = $(this);
	                if ($el.find("li.selected").length) {
	                    var prefName = "prefn" + k;
	                    var prefValues = "prefv" + k;
	                    var refName = $el.find("li.selected a").first().data('refid');

	                    params[prefName] = refName;

	                    var prefValuesSelected = [];
	                    var selectedValues = $el.find("li.selected a");
	                    selectedValues.each(function () {
	                        prefValuesSelected.push($(this).data('refval'));
	                    });
	                    params[prefValues] = prefValuesSelected.join("|");
	                    k++;
	                }
	            });
	            return params;
	        }
	   	};

	    $(document).on('click', '.js_quickviewbutton', function(e){
	    	var productId = $(this).attr("data-product-id");
	    	var productUrl = $(this).attr("data-product-url") || $(this).attr("href");
	    	if (!productId && !productUrl) return false;

	    	e.preventDefault();
	    	if (!productUrl && productId) {
	    		productUrl = app.util.appendParamToURL(app.urls.getProductUrl, 'pid', productId);
	    	}
	    	app.quickView.show({
				url : productUrl,
				source : "quickview"
			});
	    });

	    $(document).on('click', '.refinement li a, .plp-filter-features-list li a, .filters-wrapper .plp-filter-color-list li a, .refinement.size ul li a, .clear-refinement a, .refinedbreadcrumbs .breadcrumb-refinement-value a', function(e){
			e.preventDefault();
			var url = $(this).attr('href');

			 if ($(this).parents(".clear-refinement").length && ($(".js-plp-filter-category li.selected").length > 1 ||  ($(".js-plp-filter-category li.selected").length == 1 && window.location.href.indexOf("view") > -1) )  ) {
		            window.location.href = url;
		            return;
		        }
		        if ($(".js-plp-filter-category a").length && ($(".js-plp-filter-category li.selected").length > 1 || ($(".js-plp-filter-category li.selected").length == 1  && window.location.href.indexOf("view") > -1) ) )  {
		            if ($(this).parents("li").hasClass('selected')) {
		                $(this).parents("li").removeClass("selected");
		                if ($(this).find("i").length) {
		                    $(this).find("i").removeClass('fa-check-square-o').addClass('fa-square-o');
		                }
		            } else {
		                $(this).parents("li").addClass('selected');
		                if ($(this).find("i").length) {
		                    $(this).find("i").removeClass('fa-square-o').addClass('fa-check-square-o');
		                }
		            }


		            if($(this).parents(".refinedbreadcrumbs").length){
		            	$(this).parents(".breadcrumb-refinement-value").remove();
		            	$(".refinement a[data-refid='"+$(this).data("refid")+"'][data-refval='"+$(this).data("refval")+"']").parents("li").removeClass("selected");
		            }
		            ajaxFilter.startCategoryPlusRefinements();
		        } else {
		            ajaxFilter.start(url);
		        }

		});



	    $(document).on('click', '.js-plp-filter-category li a', function (e) {
	        e.preventDefault();
	        $('html, body').animate({scrollTop: 0}, 500);
	        if ($(this).closest("li").hasClass('selected')) {
 	            $(this).closest("li").removeClass('selected').removeClass('current');
 	            $(this).find("i").removeClass('fa-check-square-o').addClass('fa-square-o');
 	        } else {
 	        	$(this).find("i").removeClass('fa-square-o').addClass('fa-check-square-o');
 	            $(this).closest("li").addClass('selected').addClass('current');
 	        }

	        if ($(".refinement li.selected a").length || $(".plp-filter-features-list li.selected a").length || $(".filters-wrapper .plp-filter-color-list li.selected a") ||  $(".refinement.size ul li.selected a")) {
	            ajaxFilter.startCategoryPlusRefinements();
	        } else {
	            ajaxFilter.startCategoryMode();
	        }

	    });

	    $(document).on('startCategoryPlusRefinements', function(){
	    	ajaxFilter.startCategoryPlusRefinements();
	    });

	    //klarna events
	    if ($('#pdp-klarna').length > 0 || $('#cart-klarna').length > 0) {
	    	var detectedIframe = false;
		    var detector = setInterval(function() {
		        var elem = document.activeElement;
		        
		        if (elem && elem.tagName == 'IFRAME' && $(elem).hasClass('klarna-upstream-interstitial-frame')) {
		        	if ($('#pdp-klarna').length > 0) {
		        		if (!detectedIframe) {
		        			//pdp click
		        			dataLayer.push({'event':'pdp-click-klarna'});
		        			detectedIframe = true;
		        		}
		        	}
		        	if ($('#cart-klarna').length > 0) {
		        		if (!detectedIframe) {
		        			//pdp click
		        			dataLayer.push({'event':'cart-click-klarna'});
		        			detectedIframe = true;
		        		}
		        	}
		        } else {
		        	detectedIframe = false;
		        }
		    }, 100);
	    }
	}

	function IECompatibility() {
		var agentStr = navigator.userAgent;
		this.IsIE = false;
		this.IsOn = undefined;  //defined only if IE
		this.Version = undefined;

		if (agentStr.indexOf("MSIE 7.0") > -1) {
			this.IsIE = true;
			this.IsOn = true;
			if (agentStr.indexOf("Trident/6.0") > -1) {
				this.Version = '10';
			} else if (agentStr.indexOf("Trident/5.0") > -1) {
				this.Version = '9';
			} else if (agentStr.indexOf("Trident/4.0") > -1) {
				this.Version = '8';
			} else {
				this.IsOn = false; // compatability mimics 7, thus not on
				this.Version = '7';
			}
		} //IE 7
	}

	function initializeDom() {
		// add class to html for css targeting
		$('html').addClass('js');

		// load js specific styles
		app.util.limitCharacters();
	}

	// _app object
	// "inherits" app object via $.extend() at the end of this seaf (Self-Executing Anonymous Function
	var _app = {
		containerId		: "content",
		ProductCache	: null,  // app.Product object ref to the current/main product
		ProductDetail	: null,
		clearDivHtml	: '<div class="clear"></div>',
		currencyCodes	: app.currencyCodes || {}, // holds currency code/symbol for the site

		/**
		 * @name init
		 * @function
		 * @description Master page initialization routine
		 */
		init: function () {

			if (document.cookie.length===0) {
				$("<div/>").addClass("browser-compatibility-alert").append($("<p/>").addClass("browser-error").html(app.resources.COOKIES_DISABLED)).appendTo("#browser-check");
			}

			// init global cache
			initUiCache();

			// init global dom elements
			initializeDom();

			// init global events
			initializeEvents();

			// init specific global components
			app.tooltips.init();
			app.minicart.init();
			app.validator.init();
			app.components.init();
			app.searchplaceholder.init();
			app.wishlist.init();
			app.miniwishlist.init();
			//app.jcarousel.init();

			/* IE8 ONLY - This is to display the category carousels properly instead of using css3-
			 * the template this script pertains to is in content/contentClickCarousel*/
			if( jQuery(".clickCarousel").length > 0  && $('html').hasClass('ie8')) {

				//set the selected styles on the first label and div
				var checked = $('.clickCarousel div.asset').first();
				$(checked).addClass("checkedit");
				var checkedLabel = $(".clickCarousel label").first();
				$(checkedLabel).addClass("selectedLabel");

				$(".clickCarousel label").click(function() {

						//add or remove styles to label
						if(!$(this).hasClass("selectedLabel")){
							$(checkedLabel).removeClass('selectedLabel');
						}
						$(this).addClass('selectedLabel');
						checkedLabel = $(this);

						//find the right div via the input ID that corresponds to the label's 'for' attribute value
						$(checked).removeClass("checkedit");
						assetName = $(this).attr('for');
						assetDiv = $('#' + assetName).next('div');

						//add or remove styles to the div
						if(!$(assetDiv).hasClass("checkedit")){
							$(checked).removeClass("checkedit");
						}
						$(assetDiv).addClass("checkedit");
						checked = $(assetDiv);
				});
			}

			//remove dropdown "remember me" check box if duplicates are on the page (account-show signin page)
			if(( jQuery("#primary #dwfrm_login_rememberme").length > 0 &&  jQuery(".systemControls #dwfrm_login_rememberme").length > 0 ) || ( jQuery("#secondary #dwfrm_login_rememberme").length > 0 &&  jQuery(".systemControls #dwfrm_login_rememberme").length > 0  )) {
				$(".systemControls #dwfrm_login_rememberme").parent().remove();
			}

			//Add "checked" class for international header dropdown input class for IE8
			if( jQuery(".localeGroup").length > 0 && $('html').hasClass('ie8')) {
				$(".localeGroup label").click(function() {
					if($(".localeList").hasClass("checked")){
						$(".localeGroup label").removeClass('checked');
						$(".localeList").removeClass("checked");
					}
					$(this).addClass('checked');
					$(this).next('div').addClass('checked');
				});
			}

			// execute page specific initializations
			var ns = app.page.ns;
			if (ns && app[ns] && app[ns].init) {
				app[ns].init();
			}
			
			function setCookie(name, value, options) {
			        options = options || {};
			        var expires = options.expires;

			        if (typeof expires == "number" && expires) {
			            var d = new Date();
			            d.setTime(d.getTime() + expires * 1000);
			            expires = options.expires = d;
			        }
			        if (expires && expires.toUTCString) {
			            options.expires = expires.toUTCString();
			        }

			        value = encodeURIComponent(value);

			        var updatedCookie = name + "=" + value;

			        for (var propName in options) {
			            updatedCookie += "; " + propName;
			            var propValue = options[propName];
			            if (propValue !== true) {
			                updatedCookie += "=" + propValue;
			            }
			        }

			        document.cookie = updatedCookie;
			};
			var akamai = "userLogin="+($(".user-account").length ? "true" : "false") +"&currentPage="+app.page.ns;
			setCookie('akamaiCaching', akamai, {expires: 5184000000, path: '/'});
		}
	};

	return $.extend(app, _app);
}(window.app = window.app || {}, jQuery));

// Tooltips singleton and namespace object
// ---------------------------------------
// app.tooltips
(function (app, $) {
	var $cache = {};
	app.tooltips = {

		init : function () {
			$('.tooltip').tooltip({
				track: true,
				showURL: false,
			    bodyHandler: function() {
					// add a data attribute of data-layout="some-class" to your tooltip-content container if you want a custom class
					var tooltipClass = "";
					if( tooltipClass = $(this).find('.tooltip-content').data("layout") ) {
						tooltipClass = " class='" + tooltipClass + "' ";
					}
		        	return "<div " + tooltipClass + ">" + $(this).find('.tooltip-content').html() + "</div>";
				}
			}).click(function(e){
				var toolTipCont = $(this).find('.tooltip-content');
				if(app.isMobileUserAgent && $(this).hasClass('bonus-product-tooltip')){
					if($(toolTipCont).hasClass('not-hover')){
						$(toolTipCont).removeClass('not-hover');
					}
					else if($(toolTipCont).hasClass('not-show')){
						$(toolTipCont).removeClass('not-show');
					}
					else{
						$(toolTipCont).addClass('not-hover');
					}
				}
				e.preventDefault();
			});
		}
	};

}(window.app = window.app || {}, jQuery));

/**
 @class app.product
 */
(function (app, $) {
	var $cache;

	var isTangibleeAddToCart = false;

	/*************** app.product private vars and functions ***************/
	function loadProductNavigation() {
		var pidInput = $cache.pdpForm.find("input[name='pid']").last();
		var navContainer = $("#product-nav-container");
		// if no hash exists, or no pid exists, or nav container does not exist, return
		if (window.location.hash.length <= 1 || pidInput.length===0 || navContainer.length===0) {
			return;
		}

		var pid = pidInput.val();
		var hashParams = window.location.hash.substr(1);
		if (hashParams.indexOf("pid="+pid) < 0) {
			hashParams+="&pid="+pid;
		}

		var url = app.urls.productNav+(app.urls.productNav.indexOf("?") < 0 ? "?" : "&")+hashParams;
		app.ajax.load({url:url, target: navContainer});
	}

	//creates product recommendation carousel using jQuery jcarousel plugin
	function loadRecommendations() {
		var carousel = $("#carousel-recomendations");
		if(!carousel || carousel.length === 0 || carousel.children().length === 0) {
			return;
		}

		//carousel.jcarousel(app.components.carouselSettings);
	}

	/**
	 @description Sets the main image attributes and the href for the surrounding <a> tag
	 @param {Object} atts Simple object with url, alt, title and hires properties
	 */
	function setMainImage(atts) {
		var mainProductImg = $cache.pdpMain.find('a.cloud-zoom img');

		mainProductImg.one("load", function() {
  			app.progress.hide();
		});

		if (mainProductImg.length > 0 && atts.url && atts.url != 'null') {
			app.progress.show($cache.imageContainer);
			mainProductImg.attr('src', atts.url);
		}
	}

	/**
	 @description helper function for swapping main image on swatch hover
	 @param {Element} element DOM element with custom data-lgimg attribute
	 */
	function swapImage(element) {
		var newImg = $(element).data('lgimg');
		var mainProductImg = $cache.pdpMain.find('a.cloud-zoom img');
		mainProductImg.attr('mainImg', mainProductImg.attr('src'));

		setMainImage(newImg);
	}

	function loadZoom() {
		if(app.quickView.isActive() || !app.zoomViewerEnabled) { return; }

		//zoom properties
		var options = {
			zoomType: 'standard',
			alwaysOn : 0, // setting to 1 will load load high res images on page load
			zoomWidth : 575,
			zoomHeight : 349,
			position:'right',
			preloadImages: 0, // setting to 1 will load load high res images on page load
			xOffset: 30,
			yOffset:0,
			showEffect : 'fadein',
			hideEffect: 'fadeout'
		};

		// Added to prevent empty hires zoom feature (if images don't exist)
		var mainImage = $cache.pdpMain.find("a.main-image");
		var hiresImageSrc = mainImage.attr("href");
		if( hiresImageSrc && hiresImageSrc != '' && hiresImageSrc.indexOf('noimagelarge')<0 ) {
			mainImage.removeData("jqzoom").jqzoom(options);
		}
	}

	function replaceImages() {
		var newImages = $("#update-images");
		var imageContainer = $cache.pdpMain.find("div.product-image-container");

		imageContainer.html(newImages.html());
		newImages.remove();
		setMainImageLink();

		loadZoom();
	}

	function setMainImageLink() {
		if (app.quickView.isActive() || app.isMobileUserAgent) {
			$cache.pdpMain.find("a.main-image").removeAttr("href");
		}
		else {
			$cache.pdpMain.find("a.main-image").addClass("image-zoom");
		}
	}

	function setTangibleeEvents() {
		window.elementReady('.tangiblee-cta').then(function() {
			if (window.tangibleeScriptLoaded) {
				window.tangiblee('onCtaClicked', function(_e) {
					dataLayer.push({
						event: 'open_tangiblee_tool',
					});
				});
			}
		});
	}

	function removeImageZoom() {
		$cache.pdpMain.find("a.main-image").removeClass("image-zoom");
	}

	function initializeDom() {
		$cache.pdpMain.find('div.product-detail .product-tabs').tabs();

		if($('#pwrwritediv').length > 0) {
			var options = $.extend(true, {}, app.dialog.settings, {
				autoOpen : true,
				height : 750,
				width : 650,
				dialogClass : 'writereview',
				title : 'Product Review',
				resizable : false
			});

			app.dialog.create({
				target : '#pwrwritediv',
				options : options
			});
		}

		loadRecommendations($cache.container);
		loadProductNavigation();
		setMainImageLink();
		setTangibleeEvents();

		if ($cache.productSetList.length>0) {
			var unavailable = $cache.productSetList.find("form").find("button.add-to-cart[disabled]");
			if (unavailable.length > 0) {
				$cache.addAllToCart.attr("disabled", "disabled");
				//$cache.addToCart.attr("disabled", "disabled"); // this may be a bundle
			}
		}

		app.tooltips.init();
	}

	function initializeCache() {
		$cache = {
			productId : $("input[name='pid']"),
			pdpMain : $("#pdp-main"),
			productContent : $("#product-content"),
			thumbnails : $("#thumbnails"),
			bonusProductGrid : $(".bonusproductgrid"),
			imageContainer : $(".product-primary-image"),
			productSetList : $("#product-set-list"),
			addToCart : $(".add-to-cart"),
			addAllToCart : $(".add-all-to-cart"),
			colorMessaging : $(".pleaseSelectColor"),
			sizeMessaging : $(".pleaseSelectSize"),
			size : $(".variation-select"),
			hiddenBtn : $(".hiddenBtn")
		};
		$cache.detailContent = $cache.pdpMain.find("div.detail-content");
		$cache.pdpForm = $cache.pdpMain.find("form.pdpForm");
		$cache.swatches = $cache.pdpMain.find("ul.swatches");
		$cache.mainImageAnchor = $cache.imageZoom = $cache.imageContainer.find("a.main-image");
		$cache.mainImage = $cache.mainImageAnchor.find("img.primary-image");
	}

	function initializeEvents() {
		//Disable addThis init
		//app.product.initAddThis();

		// add or update shopping cart line item
		app.product.initAddToCart();
		app.product.initNotifyMe();


    	$(document).on('click', '.tangiblee-button', function(){
    		isTangibleeAddToCart = true;
    	});



		$cache.pdpMain.on("change keyup", "form.pdpForm input[name='Quantity']", function (e) {
			var availabilityContainer = $cache.pdpMain.find("div.availability");
			app.product.getAvailability(
				$(this).closest("input[name='pid']").val(),
				$(this).val(),
				function (data) {
					if (!data) {
						$cache.addToCart.removeAttr("disabled");
						availabilityContainer.find(".availability-qty-available").html();
						availabilityContainer.find(".availability-msg").show();
						return;
					}else{
						var avMsg = null;
						var avRoot = availabilityContainer.find(".availability-msg").html('');

						// Look through levels ... if msg is not empty, then create span el
						if( data.levels.IN_STOCK> 0 ) {
							avMsg = avRoot.find(".in-stock-msg");
							if (avMsg.length===0) {
								avMsg = $("<p/>").addClass("in-stock-msg").appendTo(avRoot);
							}
							if( data.levels.PREORDER==0 && data.levels.BACKORDER==0 && data.levels.NOT_AVAILABLE==0 ) {
								// Just in stock
								avMsg.text(app.resources.IN_STOCK);
							} else {
								// In stock with conditions ...
								avMsg.text(data.inStockMsg);
							}
						}
						if( data.levels.PREORDER> 0 ) {
							avMsg = avRoot.find(".preorder-msg");
							if (avMsg.length===0) {
								avMsg = $("<p/>").addClass("preorder-msg").appendTo(avRoot);
							}
							if( data.levels.IN_STOCK==0 && data.levels.BACKORDER==0 && data.levels.NOT_AVAILABLE==0 ) {
								// Just in stock
								avMsg.text(app.resources.PREORDER);
							} else {
								avMsg.text(data.preOrderMsg);
							}
						}
						if( data.levels.BACKORDER> 0 ) {
							avMsg = avRoot.find(".backorder-msg");
							if (avMsg.length===0) {
								avMsg = $("<p/>").addClass("backorder-msg").appendTo(avRoot);
							}
							if( data.levels.IN_STOCK==0 && data.levels.PREORDER==0 && data.levels.NOT_AVAILABLE==0 ) {
								// Just in stock
								avMsg.text(app.resources.BACKORDER);
							} else {
								avMsg.text(data.backOrderMsg);
							}
						}
						if( data.inStockDate != '' ) {
							avMsg = avRoot.find(".in-stock-date-msg");
							if (avMsg.length===0) {
								avMsg = $("<p/>").addClass("in-stock-date-msg").appendTo(avRoot);
							}
							avMsg.text(String.format(app.resources.IN_STOCK_DATE,data.inStockDate));
						}
						if( data.levels.NOT_AVAILABLE> 0 ) {
							avMsg = avRoot.find(".not-available-msg");
							if (avMsg.length===0) {
								avMsg = $("<p/>").addClass("not-available-msg").appendTo(avRoot);
							}
							if( data.levels.PREORDER==0 && data.levels.BACKORDER==0 && data.levels.IN_STOCK==0 ) {
								avMsg.text(app.resources.NOT_AVAILABLE);
							} else {
								avMsg.text(app.resources.REMAIN_NOT_AVAILABLE);
							}
						}
						return;
					}

				});

		});

		// Add to Wishlist and Add to Gift Registry links behaviors
		$cache.pdpMain.on("click", "a.wl-action", function (e) {
			e.preventDefault();

			var data = app.util.getQueryStringParams($("form.pdpForm").serialize());
			if (data.cartAction) {
				delete data.cartAction;
			}
			var url = app.util.appendParamsToUrl(this.href, data);
			url = this.protocol + "//" + this.hostname + ((url.charAt(0)==="/") ? url : ("/"+url));
			window.location.href = url;
		});

		// color swatch mouseenter
		$cache.pdpMain.on("mouseenter", "div.product-detail .emptyswatch a.swatchanchor", function (e) {
			swapImage(this);
		});

		// color swatch mouseleave
		$cache.pdpMain.on("mouseleave", "div.product-detail .emptyswatch a.swatchanchor", function (e) {
			var mainProductImg = $cache.pdpMain.find('a.cloud-zoom img');
			mainProductImg.attr('src', mainProductImg.attr('mainImg'));
		});

		// productthumbnail.onclick()
		$cache.pdpMain.on("click", "img.productthumbnail", function () {
			var lgImg = $(this).data("lgimg");

			// switch indicator
			$cache.pdpMain.find("div.product-thumbnails li.selected").removeClass("selected");
			$(this).closest("li").addClass("selected");

			setMainImage(lgImg);
			// load zoom if not quick view
			if( lgImg.hires != '' && lgImg.hires.indexOf('noimagelarge')<0 ){
				setMainImageLink();
				loadZoom();
			} else {
				removeImageZoom();
			}
		});

		// dropdown variations
		$cache.pdpMain.on("change", ".product-options select", function (e) {
			var salesPrice = $cache.pdpMain.find("div.product-add-to-cart .price-sales");

			var selectedItem = $(this).children().filter(":selected").first();
			var combinedPrice = selectedItem.data("combined");
			salesPrice.text(combinedPrice);
		});

		// prevent default behavior of thumbnail link and add this Button
		$cache.pdpMain.on("click", ".thumbnail-link, .addthis_toolbox a", false);
		$cache.pdpMain.on("click", "li.unselectable a", false);

		// handle drop down variation attribute value selection event
		$cache.pdpMain.on("change", ".variation-select", function(e){

			var itemContainer = null;
			if ($cache.productSetList.length > 0){
				itemContainer = $(this).closest(".product-set-item");
			}else{
				itemContainer = $cache.pdpMain;
			}

			if ($(this).val().length===0) {
				$(itemContainer).find(".availability").hide();
				$(itemContainer).find("button.add-to-cart").attr("disabled","disabled");
				addInvisibleButton($(itemContainer));
				return;
			} else {
				$(itemContainer).find(".availability").show();
				$(itemContainer).find("button.add-to-cart").removeAttr("disabled","disabled");
			}

			var qty = $(itemContainer).find("input[name='Quantity']").first().val(),
				listid = $(itemContainer).find("input[name='productlistid']").first().val(),
				productSet = $(this).closest('.subProduct'),
				params = {
					Quantity : isNaN(qty) ? "1" : qty,
					format : "ajax",
					productSet : $cache.productSetList.length > 0,
					altView : (productSet.data('view') == 'alt')
				};
			if( listid ) params.productlistid = listid;
			var target = (productSet.length > 0 && productSet.children.length > 0) ? productSet : $cache.productContent;
			var url = app.util.appendParamsToUrl($(this).val(), params);
			app.progress.show($cache.pdpMain);

			app.ajax.load({
				url: url,
				callback : function (data) {
					target.html(data);
					$(itemContainer).find(".product-price").html(target.find("#variant-price .product-price").html());
					$(itemContainer).find(".product-number").html(target.find("#variant-number").html());
					initializeCache();
					//app.product.initAddThis();
					app.product.initAddToCart();
					$("#update-images").remove();
					app.tooltips.init();
					app.stickvogel.init();
					
					window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
					window.KlarnaOnsiteService.push({eventName: 'refresh-placements'});
					
					if(!app.isMobileUserAgent){
						$.select2();
					}
					checkVariationsChosen($(itemContainer));
				}
			});
		});

		// swatch anchor onclick()
		$cache.pdpMain.on("click", "div.product-detail a[href].swatchanchor", function (e) {
			isTangibleeAddToCart = false;
			e.preventDefault();
			var el = $(this);
			if( el.parents('li').hasClass('unselectable') ) return;

			var isColor = el.closest("ul.swatches").hasClass("Color");

			var anchor = el,
				qty = $cache.pdpForm.find("input[name='Quantity']").first().val(),
				listid = $cache.pdpForm.find("input[name='productlistid']").first().val(),
				productSet = $(anchor).closest('.subProduct'),
				params = {
						Quantity : isNaN(qty) ? "1" : qty,
						delayDisplayRender : "true",
						productSet : $cache.productSetList.length > 0,
						altView : (productSet.data('view') == 'alt')
				};
			if( listid ) params.productlistid = listid;

			var itemContainer = null;
			if ($cache.productSetList.length > 0){
				itemContainer = $(this).closest(".product-set-item");
			}else{
				itemContainer = $cache.pdpMain;
			}

			
			
			var target = (productSet.length > 0 && productSet.children.length > 0) ? productSet : $cache.productContent;
			var url = app.util.appendParamsToUrl($(this).data("href"), params);
			app.progress.show($cache.pdpMain);

			app.ajax.load({
				url: url,
				dataType: "html",
				callback : function (data) {
					$(".product-primary-image").remove();
					target.html(data);
					var jsPdpImages = $('.product-primary-image');
					$(".product-image-container").prepend(jsPdpImages);
					$(itemContainer).find(".product-price").html(target.find("#variant-price .product-price").html());
					$(itemContainer).find(".product-number").html(target.find("#variant-number").html());
					$(itemContainer).find(".description-content").html(target.find(".js_description-tab").html());
					$(itemContainer).find(".features-content").html(target.find(".js_features-tab").html());

					var brand = target.find(".js_product-brand").text(),
						productName = target.find(".js_product-name").text(),
						productDescription = target.find(".js_description-tab").html(),
						productFeatures = target.find(".js_features-tab").html(),
						productColor = $(".colorValueLabel").text();

					$(".pdp-brand-wrapper").text(brand);
					$(".pdp-description-wrapper").html(productDescription);
					$(".pdp-features-wrapper").html(productFeatures);

					document.title = [productName, "-", productColor, "|", app.resources.SITE_NAME].join(" ");

					initializeCache();
					//app.product.initAddThis();
					app.product.initAddToCart();
					if (isColor) {
						replaceImages();
					}
					app.tooltips.init();

					app.stickvogel && app.stickvogel.init();
					
					window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
					window.KlarnaOnsiteService.push({eventName: 'refresh-placements'});
					
					if(!app.isMobileUserAgent){
						$.select2();
					}
					checkVariationsChosen($(itemContainer));
					if(target.find("#stickvogel-config").length && app.isMobileUserAgent){
						$(".js-monogram-mobile").show();
						$(".box-inventory").addClass('active');
					}
					app.wishlist.updateContainersState($('.js-w-items-list').text());
				}
			});
		});

		$cache.productSetList.on("click", "div.product-set-item li a[href].swatchanchor", function (e) {
			e.preventDefault();
			// get the querystring from the anchor element
			var params = app.util.getQueryStringParams($(this).data("href"));
			var psItem = $(this).closest(".product-set-item");
			var productSet = $(this).closest('.subProduct');

			// set quantity to value from form
			var qty = psItem.find("form").find("input[name='Quantity']").first().val();
			params.Quantity = isNaN(qty) ? "1" : qty;
			params.productSet = $cache.productSetList.length > 0;

			params.altView = (productSet.data('view') == 'alt');

			var url = app.urls.getSetItem + "?" + $.param(params);

			// get container
			var ic = $(this).closest(".product-set-item");
			ic.load(url, function () {
				app.progress.hide();

				if ($cache.productSetList.find("button.add-to-cart[disabled]").length>0) {
					$cache.addAllToCart.attr("disabled","disabled");
					$cache.addToCart.attr("disabled","disabled"); // this may be a bundle
				}
				else {
					$cache.addAllToCart.removeAttr("disabled");
					$cache.addToCart.removeAttr("disabled"); // this may be a bundle
				}

				app.product.initAddToCart(ic);
				app.tooltips.init();
				if(!app.isMobileUserAgent){
					$.select2();
				}
				app.stickvogel.init();
				
				window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
				window.KlarnaOnsiteService.push({eventName: 'refresh-placements'});
				 
				checkVariationsChosen($(psItem));
			});
		});
		//Check to see if all variations have been been choosen, if not display message
		function checkVariationsChosen(itemContainer){
			if($(itemContainer).find(".variation-select").length != 0 && $(itemContainer).find(".variation-select :selected").text() == ""){
				$(itemContainer).find(".pleaseSelectSize").show();
				$(itemContainer).find(".Size").addClass("error");
			} else{
				$(itemContainer).find(".pleaseSelectSize").hide();
				$(itemContainer).find(".Size").removeClass("error");
			}

			if($(itemContainer).find('.swatches li.selected').length == 0){
				$(itemContainer).find(".pleaseSelectColor").show();
				$(itemContainer).find("button.add-to-cart").attr("disabled","disabled");
			} else {
				$(itemContainer).find(".pleaseSelectColor").hide();
			}

			addInvisibleButton($(itemContainer));

		}

		//add button over disabled "add to cart" to fire error messages
		function addInvisibleButton(itemContainer){
			if ($(itemContainer).length >0){
				itemContainer = $(itemContainer);
			}else{
				itemContainer = $cache.pdpMain;
			}

			if($(itemContainer).find("button.add-to-cart[disabled]").length > 0){
				$(itemContainer).find('.hiddenBtn').show();
			}else {
				$(itemContainer).find('.hiddenBtn').hide();
			}

			$(itemContainer).find('.hiddenBtn').on("click", function(){
				checkVariationsChosen($(itemContainer));
			});

			//Product Set add All control
			if ($cache.productSetList.length > 0 && $cache.pdpMain.find("button.add-to-cart[disabled]").length > 0){
				$cache.addAllToCart.attr("disabled","disabled");
			} else{
				$cache.addAllToCart.removeAttr("disabled","disabled");
			}
		}

		$cache.addAllToCart.on("click", function (e) {
			e.preventDefault();
			var psForms = $cache.productSetList.find("form").toArray(),
				miniCartHtml = "",
				addProductUrl = app.util.ajaxUrl(app.urls.addProduct);

			// add items to cart
			function addItems() {
				var form = $(psForms.shift());
				var itemid = form.find("input[name='pid']").val();
				
				if (typeof dataLayer != "undefined"){
					dataLayer.push({
						'event': 'add-to-card',
						'bloomreachAddToCart': {
							"prod_id" : form.find("input[name='pid']").val(),
						    "sku": ''
						}
					});
				}

				$.ajax({
					dataType : "html",
					url: addProductUrl,
					data: form.serialize()
				})
				.done(function (response) {
					// success
					miniCartHtml = response;
				})
				.fail(function (xhr, textStatus) {
					// failed
					var msg = app.resources.ADD_TO_CART_FAIL;
					$.validator.format(msg, itemid);
					if(textStatus === "parsererror") {
						msg+="\n"+app.resources.BAD_RESPONSE;
					} else {
						msg+="\n"+app.resources.SERVER_CONNECTION_ERROR;
					}
					window.alert(msg);
				})
				.always(function () {
					if (psForms.length > 0) {
						addItems();
					}
					else {
						app.quickView.close();
						app.minicart.show(miniCartHtml);
					}
				});
			}
			addItems();
			
			if (typeof dataLayer != "undefined"){
				var eventType = window.matchMedia('(max-width: 960px)').matches ? 'mobile' : 'desktop';
				dataLayer.push({
					'event': 'add-all-to-cart-' + eventType,
					'productSetID': $('.product-name').attr('data-id')
				});
			}

			return false;
		});

		app.sendToFriend.initializeDialog($cache.pdpMain, "a.send-to-friend");

		$cache.pdpMain.find("button.add-to-cart[disabled]").attr('title', $cache.pdpMain.find(".availability-msg").html() );

		if ($cache.productSetList.find("button.add-to-cart[disabled]").length > 0){
			$cache.addAllToCart.attr("disabled","disabled");
			var i,len=$cache.productSetList.find("button.add-to-cart[disabled]").length;
			for (i=0;i<len;i++) {
				//var btn = $cache.productSetList.find("button.add-to-cart[disabled]");
				var itemContainer = $cache.productSetList.find("button.add-to-cart[disabled]").closest(".product-set-item");
				addInvisibleButton($(itemContainer));
			}
		} else{
			$cache.addAllToCart.removeAttr("disabled","disabled");
			addInvisibleButton();
		}
	}

	function setAddToCartHandler(e) {
		e.preventDefault();
		e.stopPropagation();
		var form = $(this).closest("form");
		var qty = form.find("input[name='Quantity']");
		var isSubItem = $(this).hasClass("sub-product-item");
		var isWishlistQV = $(this).closest('.wishlist-qv').length > 0;
		if(qty.length === 0 || isNaN(qty.val()) || parseInt(qty.val(), 10) === 0) {
			qty.val("1");
		}

		var data = form.serialize();
		app.cart.update(data, function (response) {
			var uuid = form.find("input[name='uuid']");
			if (uuid.length > 0 && uuid.val().length > 0) {
				app.cart.refresh();
			}
			else {
				if (isWishlistQV) {
					dataLayer.push({
						event: 'wishlist_plp_add_to_cart',
						wishlist_item_id: form.find("input[name='pid']").val(),
					});
				}
				//Letting Google Tag Manager now that we added product to cart
				if (typeof dataLayer != "undefined"){
					dataLayer.push({
						'event': 'add-to-card',
						'bloomreachAddToCart': {
						      "prod_id" : form.find("input[name='pid']").val(),
						      "sku": ''
						}
					});

					if(isTangibleeAddToCart){
						if(app.isMobileUserAgent){
							dataLayer.push({
					               'event':'add-to-cart-tangiblee-mobile'
			               });
						} else {
							dataLayer.push({
					               'event':'add-to-cart-tangiblee-desktop'
			               });
						}
					}
				}
				if (!isSubItem) {
					app.quickView.close();
				}

				if(!$("body").hasClass('nopopup'))
					app.minicart.show(response);
			}
		});

	}

	/*************** app.product public object ***************/
	app.product = {
		init : function () {
			initializeCache();
			initializeDom();
			initializeEvents();
			loadZoom();

			// if a variation is selected on load then reload the product info
			var selectedVariant = $(".variation-select option:selected").val();
			if (selectedVariant && selectedVariant.length > 0) {
				$(".variation-select").change();
			}
		},
		readReviews : function(){
			$('.product-tabs').tabs('select','#tab4');
			$('body').scrollTop($('#tab4').offset().top);
		},
		get : function (options) {
			// loads a product into a given container div
			// params
			//		containerId - id of the container div, if empty then global app.containerId is used
			//		source - source string e.g. search, cart etc.
			//		label - label for the add to cart button, default is Add to Cart
			//		url - url to get the product
			//		id - id of the product to get, is optional only used when url is empty
			var target = options.target || app.quickView.init();
			var source = options.source || "";
			var iswishlistl = options.iswishlistl || "";
			var productListID = options.productlistid || "";

			var productUrl = options.url || app.util.appendParamToURL(app.urls.getProductUrl, "pid", options.id);
			if(source.length > 0) {
				productUrl = app.util.appendParamToURL(productUrl, "source", source);
			}
			if(productListID.length > 0) {
				productUrl = app.util.appendParamToURL(productUrl, "productlistid", productListID);
			}
			if (iswishlistl.length > 0) {
				productUrl = app.util.appendParamToURL(productUrl, "iswishlistl", iswishlistl);
			}

			// show small loading image
			//app.progress.show(app.ui.primary);
			app.ajax.load({
				target : target,
				url : productUrl,
				data : options.data || "",
				// replace with callback passed in by options
				callback : options.callback || app.product.init
			});
		},
		getAvailability : function (pid, quantity, callback) {
			app.ajax.getJson({
				url: app.util.appendParamsToUrl(app.urls.getAvailability, {pid:pid, Quantity:quantity}),
				callback: callback
			});
		},
		initAddThis : function () {
			var addThisServices = ["compact","facebook","myspace","google","twitter"],
				addThisToolbox = $(".addthis_toolbox"),
				addThisLinks="";

			var i,len=addThisServices.length;

			var prodURL = addThisToolbox.data("produrl");
			var addthisurl = "";
			var host = window.location.hostname;
			if (prodURL != null) {
				addthisurl = 'addthis:url="' + host + prodURL + '"';
			}

			for (i=0;i<len;i++) {
				if (addThisToolbox.find(".addthis_button_"+addThisServices[i]).length==0) {
					addThisLinks += '<a class="addthis_button_'+addThisServices[i]+'" ' + addthisurl + '></a>';
				}
			}
			if (addThisLinks.length===0) { return; }

			addThisToolbox.html(addThisLinks);
			addthis.toolbox(".addthis_toolbox");
		},
		initAddToCart : function (target) {
			app.product.initNotifyMe();
			if (!$cache) {
				app.product.init();
			}
			$(".add-to-cart.disabled-during-init").removeClass("disabled-during-init").removeAttr("disabled");
			if (target) {
				target.off("click", ".add-to-cart", setAddToCartHandler);
				target.on("click", ".add-to-cart", setAddToCartHandler);
			} else {
				
				$cache.addToCart.off("click", setAddToCartHandler);
				$(document).off('click','.add-to-cart', setAddToCartHandler).on('click','.add-to-cart', setAddToCartHandler);
			}			
		},
		initNotifyMe : function () {
			var $notifyMeForms = $('.js-notifyme');
			$notifyMeForms.each(function(){
				var $form = $(this);
				var $email = $form.find('[name $= _notifyme_email]');
				var $pid = $form.find('#notifyme-pid');
				var $formStatus = $form.find('.notifyme-status');
				$form.off('submit').on('submit', function(e){
					e.preventDefault();
					$.ajax({
						method: 'post',
						url: app.urls.notifyme,
						data: {
							'email' : $email.val(),
							'pid' : $pid.val()
						},
						dataType: 'json',
						success: function(res){
							if (res && 'success' in res && res.success){
								notifySuccess(res.message);
								$email.val('');
								if ($('.primary-content.wishlist-page').length) {
									$('.wishlist-btn-quickview[data-product-id='+ $pid.val() +']').addClass('disabled');
								}
							} else {
								notifyError();
							}
						},
						error: function () {
							notifyError();
						}
					});

					function notifySuccess(msg) {
						$form.children().hide();
						$('<span/>', {class:'esuccess pdp-waitlist-added',text: msg}).appendTo($form);
					}

					function notifyError(msg) {
						$('<span/>', {class:'error',text: msg}).insertAfter($email);
					}
				});
			});
		}
	};

}(window.app = window.app || {}, jQuery));

// app.product.tile
(function (app, $) {
	var $cache = {};

	function initializeDom() {
		var tiles = $cache.container.find(".product-tile");
		if (tiles.length===0) { return; }
		//imagesLoaded($cache.container.find(".product-tile"), function(){
			$(".pt_product-search-result").find(".product-tile, .grid-tile>.content-asset")
				//.syncHeight()
				.each(function (idx) {
					$(this).data("idx",idx);
				});
			// $(tiles).each(function(){
			// 	($(this).find(".swatch.selected").data("mono-av")) ? $(this).find(".plp-monogram-img").show() : $(this).find(".plp-monogram-img").hide();
			// });
		//});

		$('.js_subcategory').each(function() {
			$(this).find('.js_subcategory_name').html($(this).attr('data-subcategoryname'));
		});

	}

	function initializeEvents() {
		if (app.siteSettings.QUICKVIEW_ENABLED && !app.isMobileUserAgent) {
			app.quickView.initializeButton($cache.container, ".product-image");
		}

		var decodeElement = document.createElement("div");
		function HtmlDecode(s) {
			return $(decodeElement).html(s).text();
		}

		function updateBadge(badge, tile){
			if(badge == ""){
				tile.find(".plp--label__img").hide();
			} else {
				if(tile.find(".plp--label__img").length == 0){
					$('<img />', {src: app.urls.configKipBadges[badge]}).appendTo(tile.find(".product--label__container").empty());
				} else {
					tile.find(".plp--label__img").show();
					tile.find(".plp--label__img").attr('src', app.urls.configKipBadges[badge]);
				}

			}
		}

		$('.bonus-remove').click(function(){
			var productsLength = $('.cart-row').length - 1;
			document.cookie = "bonusPopupShow=false";
			document.cookie = "productsLength="+productsLength;
		});

		$cache.container.on("mouseleave", ".swatch-list", function(e){
			// Restore current thumb image
			var tile = $(this).closest(".grid-tile");
			var thumb = tile.find(".product-image a.thumb-link img").filter(":first");
			var data = thumb.data("current");
			if( data && data.src != 'undefined'){
				thumb.attr({
					src : data.src,
					alt : HtmlDecode(data.alt),
					title : HtmlDecode(data.title)
				});
			}
			// ($(this).find(".swatch.selected").data("mono-av")) ? tile.find(".plp-monogram-img").show() : tile.find(".plp-monogram-img").hide();
		});




		$cache.container.on("click", ".swatch-list a.swatch", function (e) {

			e.preventDefault();
			if ($(this).hasClass("selected")) { return; }

			var tile = $(this).closest(".grid-tile");
			$(this).closest(".swatch-list").find(".swatch.selected").removeClass("selected");
			$(this).addClass("selected");
			tile.find("a.thumb-link").attr("href", $(this).attr("href"));
			tile.find("a.name-link").attr("href", $(this).attr("href"));
			// ($(this).data("mono-av")) ? tile.find(".plp-monogram-img").show() : tile.find(".plp-monogram-img").hide();

			var swatchImg = $(this).children("img").filter(":first");
			var data = swatchImg.data("thumb");
			var thumb = tile.find(".product-image a.thumb-link img").filter(":first");
			var currentAtts = {
				src : data.src,
				alt : HtmlDecode(data.alt),
				title : HtmlDecode(data.title)
			};
			thumb.attr(currentAtts);
			thumb.data("current", currentAtts);

			//Alternate view set url on link send data to parent to use later on rollover
			var thumbLink = tile.find(".product-image a.thumb-link");
			var altAtts = {
					src : data.srcAlt,
					alt : data.alt,
					title : data.title
			};
			thumbLink.data("thumb", altAtts);

			var dd = null;
			if($(this).parents('.product_recommender').length){
				dd = $(this).data('product-variation-id');
				$(this).parents('.product_recommender-item').find('.recommendationtocart').attr('data-pid', dd);
			}

			updateBadge($(this).data("badge"), tile);

			if($(this).parents('.bonus-product-list').length){
				dd = $(this).data('product-variation-id');
				$(this).parents('.bonus-product-item').find('.submit-button .pid').val(dd);
				$(this).parents('.bonus-product-item').find('.submit-button .isSelected').val('true');
			}

			return false;

		}).on("mouseenter", ".swatch-list a.swatch", function (e) {
			//if ($(this).hasClass("selected")) { return; }

			// get current thumb details
			var tile = $(this).closest(".grid-tile");
			var thumb = tile.find(".product-image a.thumb-link img").filter(":first");
			var swatchImg = $(this).children("img").filter(":first");
			var data = swatchImg.data("thumb");
			var current = thumb.data('current');

			// If this is the first time, then record the current img
			if(!current) {
			    thumb.data('current',{src:thumb[0].src, alt:thumb[0].alt, title:thumb[0].title});
			}

			// Set the tile image to the values provided on the swatch data attributes
			thumb.attr({
				src : data.src,
				alt : HtmlDecode(data.alt),
				title : HtmlDecode(data.title)
			});

			updateBadge($(this).data("badge"), tile);

			// ($(this).data("mono-av")) ? tile.find(".plp-monogram-img").show() : tile.find(".plp-monogram-img").hide();


			//swatchImg.data("thumb", currentAtts);
		});


		//Alternate Image rollover
		$cache.container.on("mouseenter", ".product-image", function (e) {
			
			var box = $(this);
			var image = box.find('.product-image-rollover img');
			
			if(!box.hasClass('rollover-loaded') && !box.hasClass('rollover-is-loading') && image.length){
				var img = new Image();
				img.src = isWebP ? image.attr('data-webp-src') : image.attr('data-src');
				box.addClass('rollover-is-loading');
				img.onload = function(){
					image.removeAttr('data-webp-src').removeAttr('data-src').attr('src',img.src);
					box.removeClass('rollover-is-loading').addClass('rollover-loaded');
					setTimeout(function(){
						box.addClass('rollover-completed');
					},300);
				};
			}

		});
	}

	/*************** app.product.tile public object ***************/
	app.product.tile = {
		init : function ($target) {
			var $container = $target || $(".tiles-container");
			$cache = {
				container : $container
			};
			initializeEvents();
			initializeDom();
		}
	};

}(window.app = window.app || {}, jQuery));

// app.product.compare
(function (app, $) {
	var $cache = {},
		_currentCategory = "",
		_isClearing = false,
		MAX_ACTIVE = app.resources.COMPARE_MAX_AMOUNT,
		CI_PREFIX = "ci-";

	/************** private ****************/
	function toggleCompareWidgetView() {
		var $toggleWidgetButton = $('.toggle-compare-products-button'),
			$widgetBody = $('.comparebar-container'),
			$widgetWrapper = $('.compareitems'),

			hideWidget = function() {
				$widgetBody.hide();
				$('#compare-items').removeClass('active');
			},

			showWidget = function() {
				console.log(123);
				$widgetBody.show();
				$('#compare-items').addClass('active');
			},

			onToggleButtonClick = function() {
				$toggleWidgetButton.unbind('click').bind('click', function() {
					if($widgetBody.is(':visible')) {
						hideWidget();
					} else {
						showWidget();
					}
				});
			},

			init = function() {
				onToggleButtonClick();
			};

			init();
	};

	function refreshContainer() {
		if (_isClearing) { return; }

		var ac = $cache.compareContainer.find(".active").length;

		if (ac < 2) {
			$cache.compareButton.attr("disabled", "disabled");

			if ( $(window).width() <= 750 ) {
					$cache.addToCompareButtons.css('visibility', 'visible');
			}
		}
		else {
			$cache.compareButton.removeAttr("disabled");

			if ( $(window).width() <= 750 ) {
					$cache.addToCompareButtons.css('visibility', 'hidden');
			}
		}

		// update list with sequential classes for ui targeting
		var compareItems = $cache.compareContainer.find('.compare-item');
		for( i=0; i < compareItems.length; i++ ){
			compareItems.removeClass('compare-item-' + i);
			$(compareItems[i]).addClass('compare-item-' + i);
		}

		if (ac > 0) {
			$cache.compareContainer.show();
			$('#compare-items').addClass('active');
		} else {
			$cache.compareContainer.hide();
			$('#compare-items').removeClass('active');
		}

		// logic to show and hide the Compare widget view
		toggleCompareWidgetView();
	}

	function addToList(data) {
		// get the first compare-item not currently active
		var item = $cache.compareContainer.find(".compare-item").not(".active").first();
		if (item.length===0) { return; } // safety only

		// if already added somehow, return
		if ($("#"+CI_PREFIX+data.uuid).length > 0) {
			return;
		}
		// set as active item
		item.addClass("active")
			.attr("id", CI_PREFIX+data.uuid).attr('data-itemid', data.itemid);
			// .data("itemid", data.itemid);


			console.log(data);
		// replace the item image
		var itemImg = item.find("img.compareproduct").first();
		itemImg.attr({src : $(data.img).attr("src"), alt : $(data.img).attr("alt")});

		var itemCompareName = item.find('.comparebar--product-name').first();
		itemCompareName.text(data.name);

		// refresh container state
		refreshContainer();

		var tile = $("#"+data.uuid);
		if (tile.length===0) { return; }

		// ensure that the associated checkbox is checked
		tile.find(".compare-check")[0].checked = true;
	}

	function removeFromList(uuid) {
		var item = $("#"+CI_PREFIX+uuid);
		if (item.length===0) { return; }

		// replace the item image
		var itemImg = item.children("img.compareproduct").first();
		itemImg.attr({src : app.urls.compareEmptyImage, alt : app.resources.EMPTY_IMG_ALT});

		// remove class, data and id from item
		item.removeClass("active")
			.removeAttr("id")
			.removeAttr("data-itemid")
			.data("itemid", "");

		// use clone to prevent image flash when removing item from list
		var cloneItem = item.clone();
		item.remove();
		cloneItem.appendTo($cache.comparePanel);
		refreshContainer();
		// ensure that the associated checkbox is not checked
		var tile = $("#"+uuid);
		if (tile.length === 0 ) { return; }

		tile.find(".compare-check")[0].checked = false;
	}

	function initializeCache() {
		$cache = {
			primaryContent : $("#primary"),
			compareContainer : $("#compare-items"),
			compareButton : $("#compare-items-button"),
			clearButton : $("#clear-compared-items"),
			comparePanel : $("#compare-items-panel"),
			addToCompareButtons : $('.product-compare')
		};
	}

	function initializeDom() {
		_currentCategory = $cache.compareContainer.data("category") || "";
		var active = $cache.compareContainer.find(".compare-item").filter(".active");
		active.each(function () {
			var uuid = this.id.substr(CI_PREFIX.length);
			var tile = $("#"+uuid);
			if (tile.length === 0 ) { return; }

			tile.find(".compare-check")[0].checked = true;
		});
		// set container state
		refreshContainer();
	}

	function initializeEvents() {
		// add event to buttons to remove products
		$cache.primaryContent.on("click", ".compare-item-remove", function (e, async) {
			var item = $(this).closest(".compare-item");
			var uuid = item[0].id.substr(CI_PREFIX.length);
			var tile = $("#"+uuid);
			var args = {
				itemid : item.data("itemid"),
				uuid : uuid,
				cb :  tile.length===0 ? null : tile.find(".compare-check"),
				async : async
			};
			app.product.compare.removeProduct(args);
			refreshContainer();
		});

		// Button to go to compare page
		$cache.primaryContent.on("click", "#compare-items-button", function () {
			window.location.href = app.util.appendParamToURL(app.urls.compareShow, "category", _currentCategory);
		});

		// Button to clear all compared items
		$cache.primaryContent.on("click", "#clear-compared-items", function () {
			_isClearing = true;
			$cache.compareContainer.hide()
								   .find(".active .compare-item-remove")
								   .trigger("click", [false]);
			_isClearing = false;

		});
	}

	/*************** app.product.compare public object ***************/
	app.product.compare = {
		init : function () {
			initializeCache();
			initializeDom();
			initializeEvents();
		},
		initCache : initializeCache,
		addProduct : function (args) {
			var items = $cache.compareContainer.find(".compare-item");
			var cb = $(args.cb);
			var ac = items.filter(".active").length;
			if(ac===MAX_ACTIVE) {
				if(!window.confirm(app.resources.COMPARE_CONFIRMATION)) {
					cb[0].checked = false;
					return;
				}

				// remove product using id
				var item = items.first();

				// safety check only. should never occur.
				if (item[0].id.indexOf(CI_PREFIX)!==0) {
					cb[0].checked = false;
					window.alert(app.resources.COMPARE_ADD_FAIL);
					return;
				}
				var uuid = item[0].id.substr(CI_PREFIX.length);
				app.product.compare.removeProduct({
					itemid: item.data("itemid"),
					uuid: uuid,
					cb: $("#"+uuid).find(".compare-check")
				});
			}

			app.ajax.getJson({
				url : app.urls.compareAdd,
				data : { 'pid' : args.itemid, 'category' : _currentCategory },
				callback : function (response) {
					if (!response || !response.success) {
						// response failed. uncheck the checkbox return
						cb.checked = false;
						window.alert(app.resources.COMPARE_ADD_FAIL);
						return;
					}

					// item successfully stored in session, now add to list...
					addToList(args);
				}
			});
		},

		removeProduct : function (args) {
			if (!args.itemid) { return; }
			var cb = args.cb ? $(args.cb) : null;
			app.ajax.getJson({
				url : app.urls.compareRemove,
				data : { 'pid' : args.itemid, 'category' : _currentCategory },
				async: args.async,
				callback : function (response) {
					if (!response || !response.success) {
						// response failed. uncheck the checkbox return
						if (cb && cb.length > 0) { cb[0].checked = true; }
						window.alert(app.resources.COMPARE_REMOVE_FAIL);
						return;
					}

					// item successfully removed session, now remove from to list...
					removeFromList(args.uuid);
				}
			});
		}
	};

}(window.app = window.app || {}, jQuery));

// app.compare
(function (app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			compareTable : $(".compare-page--container"),
			categoryList : $("#compare-category-list")
		};
	}

	function initializeDom() {
		app.product.tile.init();
	}

	function initializeEvents() {
		$cache.compareTable.on("click", ".remove-link", function (e) {
			e.preventDefault();
			app.ajax.getJson({
				url : this.href,
				callback : function (response) {
					app.page.refresh();
				}
			});
		})
		.on("click", ".open-quick-view", function (e) {
			e.preventDefault();
			var form = $(this).closest("form");
			app.quickView.show({
				url:form.attr("action"),
				source:"quickview",
				data:form.serialize()
			});
		});

		$cache.categoryList.on("change", function () {
			$(this).closest("form").submit();
		});
	}

	/*************** app.compare public object ***************/
	app.compare = {
		init : function () {
			initializeCache();
			initializeDom();
			initializeEvents();
			app.product.initAddToCart();
		}
	};


}(window.app = window.app || {}, jQuery));

// send to friend
(function (app, $) {
	var $cache = {},
		initialized=false;
	function initializeEvents() {
		app.util.limitCharacters();
		if (initialized) {return; }
		$cache.dialog.on("click", ".preview-button, .send-button, .edit-button", function (e) {
			e.preventDefault();
			$cache.form.validate();
			if (!$cache.form.valid()) {
				return false;
			}
			var requestType = $cache.form.find("#request-type");
			if (requestType.length>0) {
				requestType.remove();
			}
			$("<input/>").attr({id:"request-type", type:"hidden", name:$(this).attr("name"), value:$(this).attr("value")}).appendTo($cache.form);
			var data = $cache.form.serialize();
			app.ajax.load({url:$cache.form.attr("action"),
				   data: data,
				   target: $cache.dialog,
				   callback: function() {
						app.validator.init();
						app.util.limitCharacters();
						$cache.form = $("#send-to-friend-form");
						$(".ui-dialog-content").dialog("option", "position", "center");
				   }
			});
		})
		.on("click", ".cancel-button, .close-button", function (e) {
			e.preventDefault();
			$cache.dialog.dialog("close");
		});
		initialized=true;
	}

	/*************** app.sendToFriend public object ***************/
	app.sendToFriend = {
		init : function () {
			$cache = {
				form: $("#send-to-friend-form"),
				dialog: $("#send-to-friend-dialog"),
				pdpForm: $("form.pdpForm")
			};
			initializeEvents();
		},
		initializeDialog : function (eventDelegate, eventTarget) {
			$(eventDelegate).on("click", eventTarget, function (e) {
				e.preventDefault();
				var dlg = app.dialog.create({target:"#send-to-friend-dialog", options:{
					width:800,
					height:'auto',
					open:function() {
						app.sendToFriend.init();
						app.validator.init();
					}
				}});

				var data = app.util.getQueryStringParams($("form.pdpForm").serialize());
				if (data.cartAction) {
					delete data.cartAction;
				}
				var url = app.util.appendParamsToUrl(this.href, data);
				url = this.protocol + "//" + this.hostname + ((url.charAt(0)==="/") ? url : ("/"+url));
				app.ajax.load({
					url:app.util.ajaxUrl(url),
					target:dlg,
					callback: function () {
						dlg.dialog("open");	 // open after load to ensure dialog is centered
					}
				});
			});
		}
	};

}(window.app = window.app || {}, jQuery));

// app.bonusProductsView
(function (app, $) {
	var $cache = {};
	var selectedList = [];
	var maxItems = 1;
	var bliUUID = "";

	function getBonusProducts() {
		var o = {};
		o.bonusproducts = [];

		var i, len;
		for (i=0, len=selectedList.length;i<len;i++) {
			var p = { pid : selectedList[i].pid,	qty : selectedList[i].qty, options : {} };
			var a, alen, bp=selectedList[i];
			for (a=0,alen=bp.options.length;a<alen;a++) {
				var opt = bp.options[a];
				p.options = {optionName:opt.name,optionValue:opt.value};
			}
			o.bonusproducts.push({product:p});
		}
		return o;
	}

	function updateSummary() {
		if (selectedList.length===0) {
			$cache.bonusProductList.find("li.selected-bonus-item").remove();
		}
		else {
			var ulList = $cache.bonusProductList.find("ul.selected-bonus-items").first();
			var itemTemplate = ulList.children(".selected-item-template").first();
			var i, len;
			for (i=0, len=selectedList.length;i<len;i++) {
				var item = selectedList[i];
				var li = itemTemplate.clone().removeClass("selected-item-template").addClass("selected-bonus-item");
				li.data("uuid", item.uuid).data("pid", item.pid);
				li.find(".item-name").html(item.name);
				li.find(".item-qty").html(item.qty);
				var ulAtts = li.find(".item-attributes");
				var attTemplate = ulAtts.children().first().clone();
				ulAtts.empty();
				var att;
				for (att in item.attributes) {
					var attLi = attTemplate.clone();
					attLi.addClass(att);
					attLi.children(".display-name").html(item.attributes[att].displayName);
					attLi.children(".display-value").html(item.attributes[att].displayValue);
					attLi.appendTo(ulAtts);
				}
				li.appendTo(ulList);
			}
			ulList.children(".selected-bonus-item").show();
		}

		// get remaining item count
		var remain = maxItems - selectedList.length;
		$cache.bonusProductList.find(".bonus-items-available").text(remain);
		if (remain <= 0) {
			$cache.bonusProductList.find("button.button-select-bonus").attr("disabled", "disabled");
		}
		else {
			$cache.bonusProductList.find("button.button-select-bonus").removeAttr("disabled");
		}
		$cache.bonusProductList.find("button.button-select-bonus").removeAttr("disabled");
	}
	/********* public app.bonusProductsView object *********/
	app.bonusProductsView = {
		init : function () {
			$cache = {
				bonusProduct : $("#bonus-product-dialog"),
				resultArea : $("#product-result-area")
			};
		},
		show : function (url) {
			// add element to cache if it does not already exist
			if(!$cache.bonusProduct) {
				app.bonusProductsView.init();
			}

			// create the dialog
			$cache.bonusProduct = app.dialog.create({
				target : "#bonus-product-dialog",
				options : {
					width: 795,
					dialogClass : 'quickview',
					title : app.resources.BONUS_PRODUCTS
				}
			});

			// load the products then show
			app.ajax.load({
				target : $cache.bonusProduct,
				url : url,
				callback : function () {
					$cache.bonusProduct.dialog('open');
					app.bonusProductsView.initializeGrid();
					app.product.tile.init();

					$cache.bonusProduct.on('dialogclose', function(event) {
						var closeCount = sessionStorage.getItem('bonusPopupCloseCount');
						if(closeCount){
							closeCount ++;
						}
						else{
							closeCount = 1;
						}
						sessionStorage.setItem('bonusPopupCloseCount', closeCount);
					});
				}
			});

		},
		// close the quick view dialog
		close : function () {
			$cache.bonusProduct.dialog('close');
		},
		loadBonusOption : function () {
			$cache.bonusDiscountContainer = $(".bonus-discount-container");
			if ($cache.bonusDiscountContainer.length===0) { return; }

			$cache.bonusDiscountContainer = app.dialog.create({
				target : ".bonus-discount-container",
				options : {
					height : 'auto',
					width : 350,
					dialogClass : 'quickview',
					title : app.resources.BONUS_PRODUCT
				}
			});

			$cache.bonusDiscountContainer.dialog('open');

			// add event handlers
			$cache.bonusDiscountContainer.on("click", ".select-bonus-btn", function (e) {
				e.preventDefault();
				var uuid = $cache.bonusDiscountContainer.data("lineitemid");
				var url = app.util.appendParamsToUrl(app.urls.getBonusProducts,
													 {
														bonusDiscountLineItemUUID : uuid,
														source : "bonus"
													 });

				$cache.bonusDiscountContainer.dialog('close');
				app.bonusProductsView.show(url);
			}).on("click", ".no-bonus-btn", function (e) {
				$cache.bonusDiscountContainer.dialog('close');
			});
		},
		initializeGrid : function () {
			$cache.bonusProductList = $("#bonus-product-list"),
				bliData = $cache.bonusProductList.data("line-item-detail");

			/*
			maxItems = bliData.maxItems;

			bliUUID = bliData.uuid;

			if (bliData.itemCount>=maxItems) {
				$cache.bonusProductList.find("button.button-select-bonus").attr("disabled", "disabled");
			}*/

			var cartItems = $cache.bonusProductList.find(".selected-bonus-item");

			cartItems.each(function() {
				var ci = $(this);

				var product = {
					uuid : ci.data("uuid"),
					pid : ci.data("pid"),
					qty : ci.find(".item-qty").text(),
					name : ci.find(".item-name").html(),
					attributes: {}
				};
				var attributes = ci.find("ul.item-attributes li");
				attributes.each(function(){
					var li = $(this);
					product.attributes[li.data("attributeId")] = {
						displayName:li.children(".display-name").html(),
						displayValue:li.children(".display-value").html()
					};
				});
				selectedList.push(product);
			});


			$cache.bonusProductList.on("click", "div.bonus-product-item a[href].swatchanchor", function (e) {
				e.preventDefault();

				var anchor = $(this),
					bpItem = anchor.closest(".bonus-product-item"),
					bpForm = bpItem.find("form.bonus-product-form"),
					qty = bpForm.find("input[name='Quantity']").first().val(),
					params = {
						Quantity : isNaN(qty) ? "1" : qty,
						format : "ajax",
						source : "bonus",
						bonusDiscountLineItemUUID : bliUUID
					};

				var url = app.util.appendParamsToUrl(this.href, params);

				app.progress.show(bpItem);
				app.ajax.load({
					url: url,
					callback : function (data) {
						bpItem.html(data);
						if(!app.isMobileUserAgent){
							$.select2();
						}
					}
				});
			})
			.on("click", "div.simple-bonus-item .add-to-cart-bonus", function(e) {
				$cache.bonusProductList.find(".remove-link").click();
				$cache.bonusProductList.find("button.button-select-bonus").click();
			})
			.on("click", "button.button-select-bonus", function (e) {
				e.preventDefault();
				/*if (selectedList.length>=maxItems) {
					$cache.bonusProductList.find("button.button-select-bonus").attr("disabled", "disabled");
					$cache.bonusProductList.find("bonus-items-available").text("0");
					return;
				}*/
				var form = $(this).closest("form.bonus-product-form"),
					detail = $(this).closest(".product-detail");
					uuid = form.find("input[name='productUUID']").val();

				var product = {
					uuid : uuid,
					pid : form.find("input[name='pid']").val(),
					qty : "1",
					name : detail.find(".product-name").text(),
					attributes : detail.find(".product-variations").data("current"),
					options : []
				};

				var optionSelects = form.find("select.product-option");

				optionSelects.each(function (idx) {
					product.options.push({
						name : this.name,
						value : $(this).val(),
						display : $(this).children(":selected").first().html()
					});
				});
				selectedList.push(product);
				updateSummary();
			})
			.on("click", ".remove-link", function(e){
				e.preventDefault();
				var container = $(this).closest("li.selected-bonus-item");
				if (!container.data("uuid")) { return; }

				var uuid = container.data("uuid");
				var i, len = selectedList.length;
				for(i=0;i<len;i++) {
					if (selectedList[i].uuid===uuid) {
						selectedList.splice(i,1);
						break;
					}
				}
				updateSummary();
			})
			.on("click", ".add-to-cart-bonus", function (e) {


				var form = $(this).closest("form.bonus-product-form"),
					detail = $(this).closest(".product-detail"),
					uuid = form.find("input[name='productUUID']").val(),
					selected = $(this).parents('.product-wrapper').find('.swatch-list a.selected').data('product-variation-id');

				form.find("input[name='pid']").val(selected);

				var product = {
					uuid : uuid,
					pid : form.find("input[name='pid']").val(),
					qty : "1",
					name : detail.find(".product-name").text(),
					attributes : detail.find(".product-variations").data("current"),
					options : []
				};

				var optionSelects = form.find("select.product-option");

				optionSelects.each(function (idx) {
					product.options.push({
						name : this.name,
						value : $(this).val(),
						display : $(this).children(":selected").first().html()
					});
				});
				selectedList.push(product);
				updateSummary();




				if(!bliUUID){
					bliUUID = $(this).closest(".bonus-product-form").find('.bliUUID').val();
				}
				e.preventDefault();
				var url = app.util.appendParamsToUrl(app.urls.addBonusProduct, {bonusDiscountLineItemUUID:bliUUID});
				var bonusProducts = getBonusProducts();

				// make the server call
				$.ajax({
					type : "POST",
					dataType : "json",
					cache	: false,
					contentType : "application/json",
					url : url,
					data : JSON.stringify(bonusProducts),
					success: function () {
						window.location.reload();
					}
				})
				.done(function (response) {
					// success
					app.page.refresh();
				})
				.fail(function (xhr, textStatus) {
					// failed
					if(textStatus === "parsererror") {
						window.alert(app.resources.BAD_RESPONSE);
					} else {
						window.alert(app.resources.SERVER_CONNECTION_ERROR);
					}
				})
				.always(function () {
					$cache.bonusProduct.dialog("close");
				});
			});
		}
	};

}(window.app = window.app || {}, jQuery));

///app.giftcert
(function (app, $) {
	var $cache;

	function setAddToCartHandler(e) {
		e.preventDefault();
		e.stopImmediatePropagation();
		var form = $(this).closest("form");

		var options = {
			url : app.util.ajaxUrl(form.attr("action")),
			method : "GET",
			cache: false,
			contentType : "application/json",
			data : form.serialize()
		};

		$.ajax(options).done(function (response) {
			if( response.success ) {
				app.ajax.load({
					url : app.urls.minicartGC,
					data :{lineItemId : response.result.lineItemId},
					callback : function(response){
						app.minicart.show(response);
						form.find("input,textarea").val("");
					}
				});
			} else {
				form.find("span.error").hide();
				for(var id in response.errors.FormErrors ) {
					var error_el = $("#"+id).addClass("error").removeClass("valid").next(".error");
					if( !error_el || error_el.length===0 ) {
						error_el = $("<span for=\""+id+"\" generated=\"true\" class=\"error\" style=\"\"></span>");
						$("#"+id).after(error_el);
					}
					error_el.text(response.errors.FormErrors[id].replace(/\\'/g,"'")).show();
				}
				window.console && console.log(JSON.stringify(response.errors));
			}
		}).fail(function (xhr, textStatus) {
			// failed
			if(textStatus === "parsererror") {
				window.alert(app.resources.BAD_RESPONSE);
			} else {
				window.alert(app.resources.SERVER_CONNECTION_ERROR);
			}
		});
	}

	function initializeCache() {
		$cache = {
			addToCart: $("#AddToBasketButton")
		};
	}

	function initializeEvents() {
		$cache.addToCart.on('click', setAddToCartHandler);
	}

	app.giftcert = {
		init : function(){
			initializeCache();
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));

//app.giftcard
(function (app, $) {

	app.giftcard = {
		checkBalance : function (id, pin, callback) {
			// load gift card details
			app.ajax.getJson({
				url: app.urls.giftCardCheckBalance,
				callback: callback,
				type: "POST",
				data: {
					giftCertCode: id,
					giftCertPin: pin
				}
			});
		}
	};
}(window.app = window.app || {}, jQuery));

// app.checkout
(function (app, $) {
	var $cache = {},
		isShipping = false,
		shippingMethods = null,
		addressSuggested = false;

	/**
	 * Helper method which constructs a URL for an AJAX request using the
	 * entered address information as URL request parameters.
	 */
	/**
	 * int_clearpath: Include - code/app.js - 1
	 *
	 * Changed how the stateCode is selected, pulling the visible value
	 */
	function getShippingMethodURL(url, params) {
		var countryCode = $cache.countryCode.val(),
			params = params || {},
			newUrl = app.util.appendParamsToUrl(url,
			$.extend({
				address1: $cache.address1.val(),
				countryCode: $cache.countryCode.val(),
				stateCode: $cache.stateCode.val() != "OTHER" ? $cache.stateCode.val() : $cache.stateCodeInput.val(),
			 	postalCode: $cache.postalCode.val(),
			 	city: $cache.city.val()
			}, params),
			true);
		return newUrl;
	}

	//updates the order summary based on a possibly recalculated
	//basket after a shipping promotion has been applied
	function updateSummary() {

		var url = app.urls.summaryRefreshURL;
		var summary = $("#secondary .summary");
		// indicate progress
		app.progress.show(summary);

		// load the updated summary area
		summary.load( url, function(response, status) {
			// hide edit shipping method link
			//app.progress.hide();
			summary.fadeIn("fast");
			summary.find('.checkout-mini-cart .minishipment .header a').hide();
			summary.find('.order-totals-table .order-shipping .label a').hide();
		});
	}

	//selects a shipping method for the default shipment
	//and updates the summary section on the right hand side
	function selectShippingMethod(shippingMethodID) {
		// nothing entered
		if(!shippingMethodID) {
			return;
		}
		// attempt to set shipping method
		var url = getShippingMethodURL(app.urls.selectShippingMethodsList, {shippingMethodID: shippingMethodID});

		 app.ajax.getJson({
			url: url,
			callback: function (data) {
				updateSummary();
				if(!data || !data.shippingMethodID) {
					window.alert("Couldn't select shipping method.");
					return false;
				}

				if (window.ga4Data && window.ga4Data.shippingMethod) {
					window.ga4Data.shippingMethod = [data.shippingMethodID];
					window.ga4Data.shippingAmount = [parseFloat($('label[for="shipping-method-'+ data.shippingMethodID + '"] span').last().data('value')) + ''];
				}

				// display promotion in UI and update the summary section,
				// if some promotions were applied
				$(".shippingpromotions").empty();
				if(data.shippingPriceAdjustments && data.shippingPriceAdjustments.length > 0) {
					var i,len=data.shippingPriceAdjustments.length;
					for(i=0; i<len; i++) {
						var spa = data.shippingPriceAdjustments[i];
					}
				}
			}
		});
	}

	/**
	 * Make an AJAX request to the server to retrieve the list of applicable shipping methods
	 * based on the merchandise in the cart and the currently entered shipping address
	 * (the address may be only partially entered).  If the list of applicable shipping methods
	 * has changed because new address information has been entered, then issue another AJAX
	 * request which updates the currently selected shipping method (if needed) and also updates
	 * the UI.
	 */
	function updateShippingMethodList () {
		if (window.updateShippingMethodListInProgress === true) {
			return;
		} else {
			window.updateShippingMethodListInProgress = true;
		}
		/*
		if (!$cache.shippingMethodList || $cache.shippingMethodList.length === 0) { return; }
		
		if (!($cache.countryCode.val() && (!$cache.stateCode.is(':visible') || $cache.stateCode.val()) && $cache.city.val() && $cache.postalCode.val())) {
			return;
		}
		*/
		if($(".payment-methods").length){
			return;
		}
		// indicate progress
        app.progress.show($cache.checkoutForm);

		var url = getShippingMethodURL(app.urls.shippingMethodsJSON);
		 app.ajax.getJson({
			url: url,
			callback: function (data) {
                app.progress.hide();
				window.updateShippingMethodListInProgress = false;

				if(!data) {
					window.alert("Couldn't get list of applicable shipping methods.");
					return false;
				}
				if ($cache.countryCode.val()=="US" && shippingMethods && shippingMethods.toString() === data.toString())
				{
					// No need to update the UI for domestic shipments.  The list has not changed.
					return true;
				}

				// indicate progress
				app.progress.show($cache.shippingMethodList);

				// We need to update the UI.  The list has changed.
				// Cache the array of returned shipping methods.
				shippingMethods = data;

				var smlUrl = getShippingMethodURL(app.urls.shippingMethodsList);

				// load the shipping method form
				if ($cache.shippingMethodList) {
					$cache.shippingMethodList.load( smlUrl, function () {
						$cache.shippingMethodList.fadeIn("fast");
						// rebind the radio buttons onclick function to a handler.
						$cache.shippingMethodList.find("[name$='_shippingMethodID']").click(function () {
							selectShippingMethod($(this).val());
						});

						// update the summary
						updateSummary();
						app.progress.hide();
						app.tooltips.init();
					});
				} else {
					app.progress.hide();
					app.tooltips.init();
				}
			}
		});
	}

	//shipping page logic

	var  initShippingAddressEventsInited = false;
	//EditAddress and AddAdress
	function initShippingAddressEvents() {
		
		app.checkout.googleAutoField();
		
		var addresses = $("#shipping-address-actions");
		if (addresses.length===0) { return; }

		var shippingAddressForm = $("form[id $= '_shippingAddress']");
		var originalFormUrl = shippingAddressForm.attr('action');

		
		addresses.on("click", "a#add-new-address", function(e){
			 //clear form
			$("#add-address-form input[type=text], textarea").val("");

			if(!app.isMobileUserAgent){
				$("#add-address-form select").select2("val", '');
				$cache.countryCode.select2("val", "US");
			}else {
				$("#add-address-form select").val('');
				$cache.countryCode.val("US");
			}



			//Country code not set, so set to US as default

			app.util.updateStateOptions($cache.countryCode);

			app.clearpath.init();

			 $('#add-address-form, #add-address-form .add-address-header').show();
			 $('#default-address, #add-address-form .edit-address-header').hide();

			 //add name to button
			 $("#add-address-button").attr('name', 'addCustomerAddress');

			 $("button[name $= '_shippingAddress_save']").hide();
			 $("#shipping-method-list").hide();

			shippingAddressForm.attr('action', app.util.appendParamsToUrl(app.urls.shippingSaveCustomerAddress, {action:'addCustomerAddress'}));
		});
		addresses.on("click", "a#edit-address", function(e){
		     $cache.addressList.trigger('change');

			 $('#add-address-form, #add-address-form .edit-address-header').show();
			 $('#default-address, #add-address-form .add-address-header').hide();

			 //add name to button
			 $("#add-address-button").attr('name', 'editCustomerAddress');

			 $("button[name $= '_shippingAddress_save']").hide();
			 $("#shipping-method-list").hide();

			shippingAddressForm.attr('action', app.util.appendParamsToUrl(app.urls.shippingSaveCustomerAddress, {action:'editCustomerAddress'}));
		});

		$("#add-address-button").on("click keyup",function(e){
			if ((e.type == "click") || (e.keyCode == 13)) {
				e.preventDefault();
				shippingAddressForm.submit();
			}
		});

		$("#cancel-address-button").on("click keyup",function(e){
			if ((e.type == "click") || (e.keyCode == 13)) {
			    $cache.addressList.trigger('change');
	
				$('#add-address-form').hide();
				$('#default-address').show();
	
				$("button[name $= '_shippingAddress_save']").show();
				$("#shipping-method-list").show();
	
				shippingAddressForm.attr('action', originalFormUrl);
			}
		});
	}

	function initCustomerAccountCheck() {
		if ($cache.existingAccountMessage.length > 0) {
			$(document).on('change', $cache.pseudoEmailAddress, function(){
				if (window.initCustomerAccountCheckInProgress === true) {
					return;
				} else {
					window.initCustomerAccountCheckInProgress = true;
				}
				$cache.existingAccountMessage.removeClass('active');
				var email = $cache.pseudoEmailAddress.val();
				var emailRegex = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
				if (emailRegex.test(email)) {
					var url = app.urls.checkUserName;
					$.ajax({
						method: 'get',
						url: url,
						data: {email:email},
						success : function(data){
							if (data.success) {
								$cache.existingAccountMessage.addClass('active');
							}
						},
						complete : function() {
							window.initCustomerAccountCheckInProgress = false;
						}
					});
				} else {
					window.initCustomerAccountCheckInProgress = false;
				}
			});
		}
	}

	function initPaymentEvents() {
		if ($('[data-isklarnapromo]').length && $('[data-isklarnapromo]').attr('data-isklarnapromo') == 'true') {
			$('[data-isklarnapromo]').trigger('click');
		}

		var actions = $("#billing-payment-actions");
		if (actions.length == 0) {
			return;
		}

		actions.on("click", "a#add-new-creditcard", function(e) {
			//clear form
			$("#PaymentMethod_CREDIT_CARD input[type=text], textarea").val("");

			if(!app.isMobileUserAgent){
				$("#PaymentMethod_CREDIT_CARD select").select2("val", "");
			} else {
				$('select[name$="creditCardList"] option').each(function() {
				    if ($(this).val() == 'Select Credit Card') {
				        $(this).show().prop('selected', true);
				    }
				});
				$('select[name$="creditCard_month"] option').each(function() {
				    if ($(this).val() == 'Month') {
				    	$(this).show().prop('selected', true);
				    }
				});
				$('select[name$="creditCard_year"] option').each(function() {
				    if ($(this).val() == 'Year') {
				    	$(this).show().prop('selected', true);
				    }
				});

			}
			checkIfCardExpired();

			app.clearpath.init();

			//show form
			$("#PaymentMethod_CREDIT_CARD .form-row-container.payment-cc-formfield").show();

			//hide first header
			$("#PaymentMethod_CREDIT_CARD .enter-cc-header").hide();

			//show add new credit card header
			$("#PaymentMethod_CREDIT_CARD .add-new-cc-header").show();
			$('.card-number-formfield.cc-hidden-input-only').removeClass('cc-hidden-input-only');
		});

		$("#save-cc-button").on("click", function(e) {

			e.preventDefault();
			var theForm = $(this).parents('form');
			theForm.attr('action', app.urls.billingSaveCustomerCreditCard);
			theForm.submit();

		});

		if(app.isMobileUserAgent){
			$("select[name$='creditCardList']").on('change', function(){
				$('select[name$="creditCardList"] option').each(function() {
				    if ($(this).val() == 'Select Credit Card') {
				        $(this).hide();
				    }
				});
				$('select[name$="creditCard_month"] option').each(function() {
				    if ($(this).val() == 'Month') {
				        $(this).hide();
				    }
				});
				$('select[name$="creditCard_year"] option').each(function() {
				    if ($(this).val() == 'Year') {
				        $(this).hide();
				    }
				});
			});
		}

		$("#cancel-cc-button").on("click", function(e) {

			if(!app.isMobileUserAgent){
				$cache.ccList.trigger("change");
			}

			//hide form
			$("#PaymentMethod_CREDIT_CARD .form-row-container.payment-cc-formfield").hide();

			//show first header
			$("#PaymentMethod_CREDIT_CARD .enter-cc-header").show();

			//hide add new credit card header
			$("#PaymentMethod_CREDIT_CARD .add-new-cc-header").hide();


		});

	}

	//EditAddress and AddAdress
	function initBillingAddressEvents() {

		var addresses = $("#billing-address-actions");
		if (addresses.length===0) { return; }

		var billingAddressForm = $("form[id $= '_billing']");
		var originalFormUrl = billingAddressForm.attr('action');

		addresses.on("click keyup", "a#add-new-address", function(e){
			if ((e.type == "click") || (e.keyCode == 13)) {
				//clear form
				$(".address-container input[type=text], textarea").val("");
	
				if(!app.isMobileUserAgent){
					$(".address-container select").select2("val", '');
					$cache.countryCode.select2("val", "US");
				}else {
					$(".address-container select").val('');
					$cache.countryCode.val("US");
					$cache.stateCode.find('option:eq(0)').text($cache.stateCode.data('placeholder'));
				}
	
	
				//Country code not set, so set to US as default
	
				app.util.updateStateOptions($cache.countryCode);
	
				app.clearpath.init();
	
				//show form
				$("#billing-address-form, .form-row-container.address-container, .add-address-header").show();
				$(".billingAddress.useShippingAddress, .edit-address-header").hide();
	
				//add name to button: will be used as action when saving address
				$("#save-address-button").attr('name', 'addCustomerAddress');
	
				$("button[name $= '_billing_save']").hide();
	
				billingAddressForm.attr('action', app.util.appendParamsToUrl(app.urls.billingSaveCustomerAddress, {action:'addCustomerAddress'}));
			}
		});

		addresses.on("click keyup", "a#edit-address", function(e){
			if ((e.type == "click") || (e.keyCode == 13)) {
			    $cache.addressList.trigger('change');
	
				//show form
				$("#billing-address-form, .form-row-container.address-container, .edit-address-header").show();
				$(".billingAddress.useShippingAddress, .add-address-header").hide();
	
				//add name to button
				$("#save-address-button").attr('name', 'editCustomerAddress');
	
				$("button[name $= '_billing_save']").hide();
	
				billingAddressForm.attr('action', app.util.appendParamsToUrl(app.urls.billingSaveCustomerAddress, {action:'editCustomerAddress'}));
			}
		});

		$("#save-address-button").on("click keyup",function(e){
			if ((e.type == "click") || (e.keyCode == 13)) {
				e.preventDefault();
				billingAddressForm.submit();
			}
		});

		$("#cancel-address-button").on("click keyup",function(e){
			if ((e.type == "click") || (e.keyCode == 13)) {
				$cache.addressList.trigger('change');
				$("#billing-address-form, .form-row-container.address-container, .add-address-header, .edit-address-header").hide();
				$(".billingAddress.useShippingAddress").show();
				$("button[name $= '_billing_save']").show();

				billingAddressForm.attr('action', originalFormUrl);
			}
		});

		$cache.addressList.on('change', function() {
			var obj = $(this).find('option:selected').data('address');
			$("#address-fullName").html(obj.firstName + ' ' + obj.lastName);
			$("#address-address1").html(obj.address1 || '');
			$("#address-address2").html(obj.address2 || '');
			$("#address-postalCode").html(obj.postalCode || '');
			$("#address-city").html(obj.city || '');
			var state = !obj.stateCode || obj.stateCode == 'OTHER' ? obj.stateCodeInput : obj.stateCode;
			$("#address-stateCode").html(state);
			var country = app.countries[obj.countryCode] ? app.countries[obj.countryCode].countryName : "";
			$("#address-countryCode").html(country);
			$("#address-phone").html(obj.phone || '');
		});
		
		
		if(app.isMobileUserAgent){
			setTimeout(function(){
				$cache.addressList.trigger("change");
			}, 1000);
		}
				

	}

	//checkout gift message counter
	function initGiftMessageBox() {
		// show gift message box, if shipment is gift
		$cache.giftMessage.toggle($cache.checkoutForm.find("#is-gift-yes")[0].checked);

	}

	function populateSuggestedAddress() {
		$(".activate-suggested-address").on("click", function() {

			//Populate the form fields based on the suggested address
			$cache.address1.val( $(this).data("suggestion-address1") );
			$cache.address2.val( $(this).data("suggestion-address2") );
			$cache.city.val( $(this).data("suggestion-city") );
			$cache.stateCode.val( $(this).data("suggestion-state") );
			$cache.countryCode.val( $(this).data("suggestion-country") );
			$cache.postalCode.val( $(this).data("suggestion-zip") );

			//Submit the shipping form
			$("button[name $= '_shippingAddress_save']").click();
			//$cache.checkoutForm.submit();

		});
	}

	function shippingLoad() {

		$('#add-address-form').hide();

		if(!app.isMobileUserAgent){
			$cache.countryCode.select2();
		}

		if( $cache.countryCode.val() == '') {
			//Country code not set yet, so set to US as default
			if(!app.isMobileUserAgent){
				$cache.countryCode.select2("val", "US");
			} else {
				$cache.countryCode.val("US");
			}

			app.util.updateStateOptions($cache.countryCode);
		}
		//remove/Add Phone number example
		app.util.hidePhoneExample($cache.countryCode.val(), $cache.phone);

		$cache.checkoutForm.on("click", "#is-gift-yes, #is-gift-no", function (e) {
			$cache.checkoutForm.find(".gift-message-text").toggle($cache.checkoutForm.find("#is-gift-yes")[0].checked);
		});
		/**
		 * int_clearpath: Include code/app.js - 4
		 *
		 * Add the new "state" field input box to the "change" function so it will update the shipping method list when called
		 */
		
		$cache.checkoutForm.on("change checkout:updateShippingMethodList",
			"input[name$='_addressFields_address1'], select[name$='_addressFields_states_state'], input[name$='_addressFields_state'], input[name$='_addressFields_city'], input[name$='_addressFields_zip']",
			updateShippingMethodList
		)
		.on("submit",
				function (e) {
					e.preventDefault();
					e.stopPropagation();
					/**
					 * We perform our AVS Validation Here
					 *
					 * First we check to see if the country is domestic (US) if it is
					 * Then we prevent the default action and fire off an ajax call to get the results (html + json)
					 * for the AVS validation. When we perform the check we set the variable "addressSuggested" to be
					 * true so that the next time the user clicks continue the form doesn't try to validate the address
					 * again. If there was a suggested address we show the user a dialog, that will present the suggestion.
					 * When they click continue we check to see which address the chose and submit the form again after
					 * filling in all of the form fields with the selected address.
					 */
					isDomestic = $cache.countryCode.val() === "US";
					if ( isDomestic && !addressSuggested ) {
						var options = {
							width: 620,
							title: ""
						};
						var data = {
							"cid":"ie7-deprecated",
							"format":'html',
							"line1": $cache.address1.val(),
							"line2": $cache.address2.val() || "",
							"city": $cache.city.val(),
							"stateCode": $cache.stateCode.val(),
							"countryCode": $cache.countryCode.val(),
							"postalCode": $cache.postalCode.val()
						};
						var params = {url:app.util.appendParamsToUrl(app.urls.checkAVS, data), options:options};

						$cache.container = app.dialog.create(params);
						app.progress.show();
						app.ajax.load({
							target : $cache.container,
							url : params.url,
							async: false,
							callback : function () {
								app.progress.hide();
								addressSuggested = true;
								$("form.address").off('submit');
								
								
								if($cache.container.dialog("isOpen")) {
									$("button[name $= '_shippingAddress_save']").click();
								}
								if( app.checkout.avsResults && app.checkout.avsResults.avsStatus == "SUGGESTED_ADDRESSES" ){
									$cache.container.dialog("open");
									$('.continue-checkout', $cache.container).on('click', function(){
										var selectedAddress = $('input.activate-suggested-address:checked', $cache.container);
										$cache.address1.val( selectedAddress.data("suggestion-address1") );
										$cache.address2.val( selectedAddress.data("suggestion-address2") );
										$cache.city.val( selectedAddress.data("suggestion-city") );
										$cache.stateCode.val( selectedAddress.data("suggestion-state") );
										$cache.countryCode.val( selectedAddress.data("suggestion-country") );
										$cache.postalCode.val( selectedAddress.data("suggestion-zip") );
										$("button[name $= '_shippingAddress_save']").click();										
									});
								} else {
									$("button[name $= '_shippingAddress_save']").click();									
								}
							}
						});
					}
				}
		);

		// gift message character limitation
		initGiftMessageBox();
		initShippingAddressEvents();
		//updateShippingMethodList();
		initCustomerAccountCheck();
		return null;
	}



	function addressLoad() {
		// select address from list
		$cache.addressList.on("change", function () {
			var selected = $(this).children(":selected").first();
			var data = $(selected).data("address");
			if (!data) { return; }
			for (var p in data) {
				if ($cache[p] && p in data) {
					var value = removeExtraChars(data[p] && data[p].replace("^","'"));

					$cache[p].val(value || "");
					if(!app.isMobileUserAgent){
						$.select2();
					}
				}
			}

			// Can't rely on options being set by the loop above, due to order of array
			app.util.updateStateOptions($cache.countryCode);

			if ($cache.countryCode.val() === "US" || $cache.countryCode.val() === "CA") {
				if(!app.isMobileUserAgent){
					$cache.stateCode.select2("val", data.stateCode);
				} else {
					$cache.stateCode.val(data.stateCode);
				}

                $cache.stateCode.val(data.stateCode);
			}

			app.clearpath.init();
			// re-validate the form
			if ($(".checkout-shipping").length > 0){
				$cache.checkoutForm.validate().form();
			}

			/**
			 * Add the updateShippingMethodList() call here, for when the address is loaded
			 */
			updateShippingMethodList();
		});

		// update state options in case the country changes
		$cache.countryCode.on("change focusout", function () {
			app.util.updateStateOptions(this);
		});
	}

	//changes the payment method form
	function changePaymentMethod(paymentMethodID) {

		$cache.paymentMethods.removeClass("payment-method-expanded");
		var pmc = $cache.paymentMethods.filter("#PaymentMethod_"+paymentMethodID);
		if (pmc.length===0) {
			pmc = $("#PaymentMethod_Custom");
		}
		
		pmc.addClass("payment-method-expanded");
		
		var ifKlarnaMethod = paymentMethodID === 'klarna_payments_pay_over_time';
		$('.billing-enter-address').toggleClass('address-is-hidden', ifKlarnaMethod);
		ifKlarnaMethod ? $('.existing-address-toggle #useShipping').trigger('click') : null;

		// ensure checkbox of payment method is checked
		$("#is-" + paymentMethodID).prop('checked', true);

		var bmlForm = $cache.checkoutForm.find("#PaymentMethod_BML");
		bmlForm.find("select[name$='_year']").removeClass("required");
		bmlForm.find("select[name$='_month']").removeClass("required");
		bmlForm.find("select[name$='_day']").removeClass("required");
		bmlForm.find("input[name$='_ssn']").removeClass("required");

		if (paymentMethodID==="BML") {
			var yr = bmlForm.find("select[name$='_year']");
			bmlForm.find("select[name$='_year']").addClass("required");
			bmlForm.find("select[name$='_month']").addClass("required");
			bmlForm.find("select[name$='_day']").addClass("required");
			bmlForm.find("input[name$='_ssn']").addClass("required");
		}
		// disable validation of credit card form when it is not selected
		if (paymentMethodID!=="CREDIT_CARD") {
			$("#PaymentMethod_CREDIT_CARD").find('input, select').prop("disabled", true);
			if(!app.isMobileUserAgent){
				$("#PaymentMethod_CREDIT_CARD").find('select').select2("enable", false);
			} else {
				$("#PaymentMethod_CREDIT_CARD").find('select').attr("enable", false);
			}

		} else {
			$("#PaymentMethod_CREDIT_CARD").find('input, select').prop("disabled", false);
			if(!app.isMobileUserAgent){
				$("#PaymentMethod_CREDIT_CARD").find('select').select2("enable", true);
			} else {
				$("#PaymentMethod_CREDIT_CARD").find('select').attr("enable", true);
			}

		}

		app.validator.init();
	}

	function setCCFields(data) {
		// fill the form / clear the former cvn input
		$cache.ccOwner.val(data.holder);
	//	$cache.ccType.val(data.type);
		$cache.ccNum.val(data.maskedNumber);
	//	$cache.ccMonth.val(data.expirationMonth);
	//	$cache.ccYear.val(data.expirationYear);
		$cache.ccCcv.val("");

		//populate select2 span with new values
		if(!app.isMobileUserAgent){
			$(".type select").select2("val", data.type);
			$(".month select").select2("val", data.expirationMonth);
			$(".year select").select2("val", data.expirationYear);
		} else {
			$(".type select").val(data.type);
			$(".month select").val(data.expirationMonth);
			$(".year select").val(data.expirationYear);
		}
		checkIfCardExpired();


		// remove error messages
		$cache.ccContainer.find(".errormessage")
						  .toggleClass("errormessage")
						  .filter("span").remove();

		$cache.ccContainer.find(".errorlabel").toggleClass("errorlabel");
	}

	//updates the credit card form with the attributes of a given card
	function populateCreditCardForm(cardID) {
		// load card details
		var url = app.util.appendParamToURL(app.urls.billingSelectCC, "creditCardUUID", cardID);
		 app.ajax.getJson({
			url: url,
			callback: function (data) {
				if(!data) {
					window.alert(app.resources.CC_LOAD_ERROR);
					return false;
				}
				$cache.ccList.data(cardID, data);
				setCCFields(data);
			}
		});
	}

	function checkUseShipping(clearForms) {
		var useOtherRadio = $("[name='dwfrm_billing_billingAddress_addressFields_useShipping']:checked");
        var useOtherAddress = useOtherRadio.val() == 'false' && useOtherRadio[0].checked;

		if ($cache.countryCode && $cache.countryCode.val() != ''){
			$cache.countryCode.defaultText = $cache.countryCode.val();
		}

		if ($cache.stateCode && $cache.stateCode.val() != ''){
			$cache.stateCode.find('option:eq(0)').text($cache.stateCode.data('placeholder'));
			$cache.stateCode.defaultText = $cache.stateCode.val();
		}

		if ( useOtherAddress ) {
			//show form
			$(".useOtherAddress, #billing-address-form, .form-row-container.address-container").show();
			$(".useShippingAddress").hide();

			// disable submit of useShippingAddress form fields when apropriate checkbox is not selected
			$(".useShippingAddress").find('input').prop("disabled", true);

			if (clearForms) {
				//clear form
				$(".address-container input[type=text], textarea").val("");
				if(!app.isMobileUserAgent){
					$(".address-container select").select2("val", '');
					$cache.countryCode.select2("val", "US");
				} else {
					$(".address-container select").val('');
					$cache.countryCode.val("US");
				}


				//Country code not set, so set to US as default

				app.util.updateStateOptions($cache.countryCode);
			}

			app.clearpath.init();
		 } else {
		 	//hide form
			$(".useOtherAddress, #billing-address-form, .form-row-container.address-container").hide();
			$(".useShippingAddress").show();
			//repopulate form with original data
			$cache.firstName.val(removeExtraChars(ShippingAddress.firstName));
			$cache.lastName.val(removeExtraChars(ShippingAddress.lastName));
			$cache.address1.val(removeExtraChars(ShippingAddress.address1));
			$cache.address2.val(removeExtraChars(ShippingAddress.address2));
			$cache.city.val(removeExtraChars(ShippingAddress.city));
			$cache.postalCode.val(removeExtraChars(ShippingAddress.postalCode));
			$cache.phone.val(ShippingAddress.phone);

			if(!app.isMobileUserAgent){
				$cache.countryCode.select2("val", ShippingAddress.country);
			} else {
				$cache.countryCode.val(ShippingAddress.country);
			}


			$cache.statesCode.val(ShippingAddress.state);
			$cache.stateCodeInput.val(ShippingAddress.state);

			if(!app.isMobileUserAgent){
				$cache.stateCode.select2("val", ShippingAddress.state);
			} else {
				$cache.stateCode.val(ShippingAddress.state);
			}



			// enable submit of useShippingAddress form fields when apropriate checkbox is selected
			$(".useShippingAddress").find('input').prop("disabled", false);

			app.clearpath.init();

			//check if its a PO Box
			checkPOBox($("input[name$='_address1']"));
		 }
	}

	//loads billing address, Gift Certificates, Coupon and Payment methods
	function billingLoad() {
		/**
		 * We add this extra validation check in here incase there is a form field that is hidden
		 * and the user won't be able to see the error message
		 */
		$cache.checkoutForm.on("submit",function(e){
			var legend = $cache.checkoutForm.find('legend').first();
			var holder;

			var hiddenErrorFound = false;

			$('select.error, input.error').each(function(){
				var errorElement = $('span[for="'+$(this).attr('id')+'"]');

				if (errorElement.is(':visible')) {
					return;
				}

				hiddenErrorFound = true;

				if (legend.find('.required-indicator').length <= 0) {
					holder = $('<span></span>').addClass('required-indicator');
					legend.append(holder);
				} else {
					holder = legend.find('.required-indicator');
				}

				holder.html('<br>'+errorElement.text());
			});

			if (hiddenErrorFound) {
				app.util.scrollBrowser(0);
			}
		});
		if(!app.isMobileUserAgent){
			$cache.countryCode.select2();
		}

		if( $cache.countryCode.val() == '') {
			//Country code not set yet, so set to US as default
			if(!app.isMobileUserAgent){
				$cache.countryCode.select2("val", "US");
			} else {
				$cache.countryCode.val("US");
			}

			app.util.updateStateOptions($cache.countryCode);
		} else {
			//remove/Add Phone number example
			app.util.hidePhoneExample($cache.countryCode.val(),$cache.phone);
		}

		var stateCode = $cache.stateCode.val();
		app.util.updateStateOptions($cache.countryCode);
		$cache.stateCode.val(stateCode);

		if(!app.isMobileUserAgent){
			$cache.stateCode.select2("val", stateCode);
		} else {
			$cache.stateCode.val(stateCode);
		}


		if( !$cache.paymentMethodId ) return;

		$cache.paymentMethodId.on("click", function () {
			changePaymentMethod($(this).val());
		});

		// get selected payment method from payment method form
		var paymentMethodId = $cache.paymentMethodId.filter(":checked");
		changePaymentMethod(paymentMethodId.length===0 ? "CREDIT_CARD" : paymentMethodId.val());

		// select credit card from list
		$cache.ccList.on("change", function () {
			var cardUUID = $(this).val();
			if(!cardUUID) { return; }
			var ccdata = $cache.ccList.data(cardUUID);
			if (ccdata && ccdata.holder) {
				setCCFields(ccdata);
				return;
			}
			populateCreditCardForm(cardUUID);
		});

		// handle whole form submit (bind click to continue checkout button)
		// append form fields of current payment form to this submit
		// in order to validate the payment method form inputs too

		$cache.save.on('click', function (e) {
			// determine if the order total was paid using gift cert or a promotion
			if ($("#noPaymentNeeded").length > 0 && $(".giftcert-pi").length > 0) {
				// as a safety precaution, uncheck any existing payment methods
				$cache.paymentMethodId.filter(":checked").removeAttr("checked");
				// add selected radio button with gift card payment method
				$("<input/>").attr({
									name:$cache.paymentMethodId.first().attr("name"),
									type:"radio",
									checked:"checked",
									value:app.constants.PI_METHOD_GIFT_CERTIFICATE})
							 .appendTo($cache.checkoutForm);
			}

			var tc = $cache.checkoutForm.find("input[name$='bml_termsandconditions']");
			if ($cache.paymentMethodId.filter(":checked").val()==="BML" && !$cache.checkoutForm.find("input[name$='bml_termsandconditions']")[0].checked) {
				alert(app.resources.BML_AGREE_TO_TERMS);
				return false;
			}

		});



		/******* Toggle Shipping address on Billing Page ********/
		$("[name='dwfrm_billing_billingAddress_addressFields_useShipping'], [name='dwfrm_billing_billingAddress_addressFields_useShipping'] + label").on('click', function (e) {
			checkUseShipping(true);
		});


		$cache.gcCheckBalance.on("click", function (e) {
			e.preventDefault();
			$cache.gcPin = $cache.gcPin || $("#gift-certificate-form").find("input[name*='_giftCertPin']");
			$cache.gcErrorMessages = $('.gift-card-error-messages');
			$cache.gcCode = $cache.gcCode || $("#gift-certificate-form").find("input[name*='_giftCertCode']");
			$cache.balance = $cache.balance || $("#gift-certificate-form").find("div.balance");

			if ($cache.gcCode.val().length===0) {
				var error = $cache.gcErrorMessages.find("span.error");
				if (error.length===0) {
					error = $("<span>").addClass("error").appendTo($cache.balance);
				}
				error.html(app.resources.GIFT_CERT_MISSING);
				return;
			}

			app.giftcard.checkBalance($cache.gcCode.val(), $cache.gcPin.val(), function (data) {
				if(!data || !data.giftCertificate) {
					// error
					var error = $cache.gcErrorMessages.find("span.error");
					if (error.length===0) {
						error = $("<span>").addClass("error").appendTo($cache.balance);
					}
					error.html(app.resources.GIFT_CERT_INVALID);
					return;
				}
				// display details in UI
				$cache.balance.find("span.error").remove();
				var balance = data.giftCertificate.balance;
				$cache.balance.html(app.resources.GIFT_CERT_BALANCE+" "+balance);
			});
		});

		// Test Gift Certificate Code and Pin values on click of apply button
		$cache.addGiftCert.on("click", function(_e){
			$cache.gcPin = $cache.gcPin || $("#gift-certificate-form").find("input[name*='_giftCertPin']");
			$cache.gcErrorMessages = $('.gift-card-error-messages');
			$cache.gcCode = $cache.gcCode || $("#gift-certificate-form").find("input[name*='_giftCertCode']");

			$cache.balance = $cache.balance || $("#gift-certificate-form").find("div.balance");
			$cache.balance.html("");

			var pinNumberRegex = /^\d{1,8}$/, // Four digits
				gcPinValue = $cache.gcPin.val(),
				giftCodeRegex = /^(\d{15,19})$/,
				gcValue = $cache.gcCode.val();

			var error = null;
			// Test gift certificate code
			if (!giftCodeRegex.test(gcValue)) {
				error = $cache.gcErrorMessages.find("span.error");
				if (error.length===0) {
					error = $("<span>").addClass("error").appendTo($cache.balance);
				}
				error.html(app.resources.GIFT_CERT_INVALID);
				return false;
			}
			else {
				$cache.gcErrorMessages.empty();
			}

			// Test gift certificate pin code
			if (!pinNumberRegex.test(gcPinValue)) {
				error = $cache.gcErrorMessages.find("span.error");
				if (error.length===0) {
					error = $("<span>").addClass("error").appendTo($cache.gcErrorMessages);
				}
				error.html(app.resources.GIFT_CERT_PIN_INVALID);
				return false;
			}
			else {
				$cache.gcErrorMessages.empty();
			}

			$(this).closest("form").validate().cancelSubmit = true;

			return true;
		});

		checkUseShipping(false);

		initPaymentEvents();
		initBillingAddressEvents();
		checkIfCardExpired();
	}

	function initializeDom() {
		isShipping = $(".checkout-shipping").length > 0;
		if ($cache.continueBtn) {
			$cache.continueBtn.mouseenter(function(){
				$(":input:focus").blur();
				//$(this).css("margin-top","-11px");
			});
		}
	}

	function initializeCache() {
		$cache.checkoutForm = $("form.address");
		$cache.addressList = $cache.checkoutForm.find(".select-address select[id$='_addressList']");
		$cache.firstName = $cache.checkoutForm.find("input[name$='_firstName']");
		$cache.lastName = $cache.checkoutForm.find("input[name$='_lastName']");
		$cache.address1 = $cache.checkoutForm.find("input[name$='_address1']");
		$cache.address2 = $cache.checkoutForm.find("input[name$='_address2']");
		$cache.city = $cache.checkoutForm.find("input[name$='_city']");
		$cache.postalCode = $cache.checkoutForm.find("input[name$='_zip']");
		$cache.phone = $cache.checkoutForm.find("input[name$='_phone']");
		$cache.countryCode = $cache.checkoutForm.find("select[id$='_country']");
		$cache.countryCodeOriginal = $cache.countryCode.val();
		$cache.stateCode = $cache.checkoutForm.find("select[id$='_states_state']");
		$cache.statesCode = $cache.checkoutForm.find("select[id$='_state']");

		/**
		 * int_clearpath: Include code/app.js - 6
		 *
		 * Add the stateCodeInput field to the cache
		 */
		$cache.stateCodeInput = $cache.checkoutForm.find("input[id$='_state']");
		$cache.addToAddressBook = $cache.checkoutForm.find("input[name$='_addToAddressBook']");
		if ($cache.checkoutForm.hasClass("checkout-shipping")) {
			// shipping only
			$cache.continueBtn = $cache.checkoutForm.find("button[name$='_shippingAddress_save']");
			$cache.useForBilling = $cache.checkoutForm.find("input[name$='_useAsBillingAddress']");
			$cache.giftMessage = $cache.checkoutForm.find(".gift-message-text");
			$cache.shippingMethodList = $("#shipping-method-list");
			$cache.pseudoEmailAddress = $cache.checkoutForm.find("input[name$='_pseudoEmailAddress']");
			$cache.existingAccountMessage = $cache.checkoutForm.find(".existing-account-msg");

			$cache.checkoutForm.bind('invalid-form', function(){
				if (!$('#address-form').is(':visible')) {

					$('.error-message').hide(); //Dupicate Error Message

					 $('#add-address-form, #add-address-form .edit-address-header').show();
					 $('#default-address, #add-address-form .add-address-header').hide();

					 //add name to button
					 $("#add-address-button").attr('name', 'editCustomerAddress');

					$('.edit-address-header').append($('<div>There was a problem with your default saved address.<br>Please resolve any issues below and save.</div>').addClass('error-message'));
				}
			});

		}

		if ($cache.checkoutForm.hasClass("checkout-billing")) {
			// billing only
			$cache.continueBtn = $cache.checkoutForm.find("button[name$='_billing_save']");
			$cache.email = $cache.checkoutForm.find("input[name$='_emailAddress']");
			$cache.save = $cache.checkoutForm.find("button[name$='_billing_save']");
			$cache.paymentMethods = $cache.checkoutForm.find("div.payment-method");
			$cache.paymentMethodId = $cache.checkoutForm.find("input[name$='_selectedPaymentMethodID']");
			$cache.ccContainer = $("#PaymentMethod_CREDIT_CARD");
			$cache.ccList = $("#creditCardList");
			$cache.ccOwner = $cache.ccContainer.find("input[name$='creditCard_owner']");
			$cache.ccType = $cache.ccContainer.find("select[name$='_type']");
			$cache.ccNum = $cache.ccContainer.find("input[name$='_number']");
			$cache.ccMonth = $cache.ccContainer.find("[name$='_month']");
			$cache.ccYear = $cache.ccContainer.find("[name$='_year']");
			$cache.ccCcv = $cache.ccContainer.find("input[name$='_cvn']");
			$cache.BMLContainer = $("#PaymentMethod_BML");
			$cache.gcCheckBalance = $("#gc-checkbalance");
			$cache.addGiftCert = $("#add-gift-cert");
		}

		$cache.addCoupon = $("#add-coupon");
	}

	function initializeEvents() {
		addressLoad();
		if (isShipping) {
			shippingLoad();
			$(document).off('click', '.checkout-shipping.address button[type="submit"]').on('click', '.checkout-shipping.address button[type="submit"]', function(e) {
				if ( typeof( e.isTrigger ) == 'undefined' ) {
					trackGA4CheckoutEvent('Checkout - Shipping', 2);
				}
			});
		}
		else {
			billingLoad();
			//check if its a PO Box
			checkPOBox($("input[name$='_address1']"));
			if ($(".checkout-summary-container").length > 0) {
				trackGA4CheckoutEvent('Checkout - Summary', 4);
			} else {
				trackGA4CheckoutEvent('Checkout - Billing', 3);
			}
		}
		addCouponReInit();
		removeCouponReInit();
	}

	function addCouponReInit() {
		$(document).off('click','.checkout-coupon-code #add-coupon').on('click','.checkout-coupon-code #add-coupon', function(e){
			e.preventDefault();
			$cache.couponCode = $cache.couponCode || $("input[name$='_couponCode']");
			$cache.couponStatus = $cache.couponStatus || $(".coupon-status");

			var showCouponError = function(msg) {

				$cache.couponStatus.removeClass("success");
				$cache.couponStatus.addClass("error");
				$cache.couponStatus.show();

				var statusIcon = $cache.couponStatus.find("span.coupon-status-icon");
				if( statusIcon.length > 0 ) {
					statusIcon.removeClass("success-icon");
					statusIcon.addClass("error-icon");
				}

				var statusMessage = $cache.couponStatus.find("span.coupon-status-message");
				if( statusMessage.length > 0 ) {
					statusMessage.removeClass("success-message");
					statusMessage.addClass("error-message");
					statusMessage.text(msg);
				}
			};

			var val = $cache.couponCode.val();

			if(val.length == 0) {
				showCouponError(app.resources.COUPON_CODE_MISSING);
				return;
			}

			var applyCouponURL;

			if (isShipping) {
				applyCouponURL = app.util.appendParamsToUrl(app.urls.applyCouponAjax, {couponCode:val});
			} else {
				applyCouponURL = app.util.appendParamsToUrl(app.urls.applyCouponAjax, {couponCode:val,isBilling:'true'});
			}

			app.progress.show($('#secondary'));
			
			if (isShipping) {
				$.ajax({
					method: 'post',
					url: applyCouponURL,
					success : function(data){
						app.progress.hide();
						var htmlData = $('<div/>').html(data),
							newSummarySection = htmlData.find(".js-order-totals-sidebar"),
							newCouponBankSection =  htmlData.find(".checkout-coupon-code .coupon-code-bank"),
							newCouponStatus = htmlData.find(".checkout-coupon-code .coupon-status"),
							newSidebarBasket = htmlData.find(".sidebar-basket");

						$(".cart-order-totals .js-order-totals-sidebar").empty().replaceWith(newSummarySection);
						$(".checkout-coupon-code .coupon-code-bank").empty().replaceWith(newCouponBankSection);
						$(".checkout-coupon-code .coupon-status").empty().replaceWith(newCouponStatus);
						$(".sidebar-basket").empty().replaceWith(newSidebarBasket);
						updateShippingMethodList();
					},
					error : function () {
						window.location.href = app.urls.cartShow;
					}
				});
			} else {
				window.location.href = applyCouponURL;
			}
		});
	}

	function removeCouponReInit() {
		$(document).off('click','.js-remove-coupon-checkout').on('click','.js-remove-coupon-checkout', function(e){
			e.preventDefault();
			
			var removeCouponURL;

			if (isShipping) {
				removeCouponURL = app.util.appendParamsToUrl(app.urls.removeCouponAjax, {couponCode: $(this).data('code')});
			} else {
				removeCouponURL = app.util.appendParamsToUrl(app.urls.removeCouponAjax, {couponCode: $(this).data('code'),isBilling:'true'});
			}

			app.progress.show($('#secondary'));

			if (isShipping) {
				$.ajax({
					method: 'post',
					url: removeCouponURL,
					success : function(data){
						app.progress.hide();
						var htmlData = $('<div/>').html(data),
							newSummarySection = htmlData.find(".js-order-totals-sidebar"),
							newCouponBankSection =  htmlData.find(".checkout-coupon-code .coupon-code-bank"),
							newCouponStatus = htmlData.find(".checkout-coupon-code .coupon-status"),
							newSidebarBasket = htmlData.find(".sidebar-basket");

						$(".cart-order-totals .js-order-totals-sidebar").empty().replaceWith(newSummarySection);
						$(".checkout-coupon-code .coupon-code-bank").empty().replaceWith(newCouponBankSection);
						$(".checkout-coupon-code .coupon-status").empty().replaceWith(newCouponStatus);
						$(".sidebar-basket").empty().replaceWith(newSidebarBasket);
						updateShippingMethodList();
					},
					error : function () {
						window.location.href = app.urls.cartShow;
					}
				});
			} else {
				window.location.href = removeCouponURL;
			}
		});
	}

	function checkIfCardExpired() {
		var $cardListSelector = $('#creditCardList'),
			$cardListSelected = $cardListSelector.find('option:selected'),
			expirationMonth = $cardListSelected.data('expiration-month'),
			expirationYear = $cardListSelected.data('expiration-year')
			$cardListError = $('#credit-card-list-error');
		
		var date = new Date(),
			currentMonth = date.getMonth(),
			currentYear = date.getFullYear();

		if (!expirationYear || (expirationYear > currentYear) || ((expirationYear === currentYear) && (expirationMonth >= currentMonth))) {
			$cardListError.removeClass('active');
			$cardListSelector.parent('.styled-select').removeClass('error');
		} else {
			$cardListError.addClass('active');
			$cardListSelector.parent('.styled-select').addClass('error');
		}
	}

	function checkPOBox(inputfield){
		var pattern = new RegExp('p[.\\s]*o[.\\s]+box', 'i');

		if (inputfield.length <= 0) {
			return;
		}

		if(inputfield.val().match(pattern)){
			if(isShipping){
				$("div.label-addBilling label").hide();
			}
			else {
				inputfield.addClass('error');
				inputfield.parent().append($(".poBoxError"));
				$(".poBoxError").show();
			}
		} else {
			$("div.label-addBilling label").show();
			$(".poBoxError").hide();
			inputfield.removeClass('error');
			inputfield.parent().removeClass('error');
		}
	}

	/******* app.checkout public object ********/
	app.checkout = {
		init : function () {
			initializeCache();
			initializeDom();
			initializeEvents();
			/**
			* int_clearpath: Include code/app.js 7
			*
			* Initialize the clearpath namespace
			*
			*	Old Code: (none)
			*
			*	New Code: app.clearpath.init();
			*/
			app.clearpath.init();
		},
		googleAutoField : function(){
			var googleAuto = {
			          initialize : function () {
			            if (typeof google.maps != "undefined") {
			              // Disabled browser autocomplite for google autocomplite field
			              //$('[id$="address1"]').attr('autocomplete', 'off');
			              // Create the autocomplete object, restricting the search
			              // to geographical location types.
			              var autocomplete = new google.maps.places.Autocomplete(
			                  ($('input[id$="address1"]')[0]),
			                  { types: ['geocode'] }
			              );

			              // Hack for mobile devices (tap works for autocomplete)
			              $(document).on({
			                  'DOMNodeInserted': function() {
			                      $('.pac-item, .pac-item span', this).addClass('needsclick');
			                  }
			              }, '.pac-container');
			              // Sets the country restrictions
			              var countryInput = $('select[id$="country"]');

			              if (countryInput.attr('disabled') === 'disabled') {
			                var countryCode = countryInput.val();
			                if (countryCode) { autocomplete.setComponentRestrictions({country : countryCode}); }
			              } else {
			                countryInput.change(function() {
			                  var countryCode = countryInput.val();
			                  if (countryCode && (countryInput.attr('data-changed') !== countryCode)) {
			                    autocomplete.setComponentRestrictions({country : countryCode});
			                    countryInput.attr('data-changed', countryCode);
			                  }
			                });
			              }

			              
			              // When the user selects an address from the dropdown,
			              // populate the address fields in the form.
			              google.maps.event.addListener(autocomplete, 'place_changed', function() {
			                var place = autocomplete.getPlace();
			                // Debug format of place object
			                //console.log(place);
			                if (Object.keys(place).length > 1) {
			                	googleAuto.fillInAddress(autocomplete, place);
			                }
			              });

			              // Set focus trigger and start geolocate
			              if (!($('[id$="address1"]').hasClass('focusonce'))) {
			                $('[id$="address1"]').focus(function() {
			                  $(this).addClass('focusonce');
			                  googleAuto.geolocate(autocomplete);
			                });
			              }

			              // Enable or disable address fields
			             // googleAuto.onOffAddressFields();
			            }
			          },

			          // Checkout address form field data for auto filling
			          componentForm : {
			            address1: {
			              street_number: 'short_name',
			              route: 'long_name'
			            },
			            address2: {},
			            country: {country: 'short_name'},
			            states_state: {administrative_area_level_1: 'short_name'},
			            city: {
			              locality: 'long_name',
			              alternative: {
			                sublocality_level_1: 'long_name'
			              }
			            },
			            zip: {
			              postal_code: 'long_name',
			              postal_code_suffix: 'long_name'
			            }
			          },

			          // Fill address form
			          fillInAddress : function (autocomplete, place) {

			            // Get the place details from the autocomplete object.
			            var compForm = googleAuto.componentForm;
			            var autoFeelFields = [];

			            // Create temporary array with name address component as key and format address component as value
			            for (var component in compForm) {
			              var componentSelector = "[id$='" + component + "']";
			              if ($(componentSelector).attr('disabled') !== 'disabled') {
			                $(componentSelector).val('');
			                //$(componentSelector).prop('disabled', false);
			                for (var subComponent in compForm[component]) {
			                  if (subComponent !== 'alternative') {
			                    autoFeelFields[subComponent] = compForm[component][subComponent];
			                  } else {
			                    for (var sub2Component in compForm[component][subComponent]) {
			                      autoFeelFields[sub2Component] = compForm[component][subComponent][sub2Component];
			                    }
			                  }
			                }
			              }
			            }

			            // Get each component of the address and set to temporary array.
			            for (var i = 0; i < place.address_components.length; i++) {
			              var addressType = place.address_components[i].types[0];

			              if (autoFeelFields[addressType]) {
			                autoFeelFields[addressType] = place.address_components[i][autoFeelFields[addressType]];
			              }
			            }

			            // Get each component of the address from the temporary array
			            // and fill the corresponding field on the form.
			            var isPR = false;
			            for (var component in compForm) {
			            	
			             
			            	
			              var componentSelector = "[id$='" + component + "']";
			              var textAddressPart = '';
			              var i = 0;
			              var flag = false;

			              if ($(componentSelector).attr('disabled') !== 'disabled') {
			                // Set the flag for alternative value
			                for (var subComponent in compForm[component]) {
			                  if (subComponent === 'alternative') {
			                    flag = true;
			                  }
			                }
			                for (var subComponent in compForm[component]) {
			                  if (!flag) {
			                    // For fields without alternative values
			                    if (autoFeelFields[subComponent] !== 'long_name' && autoFeelFields[subComponent] !== 'short_name') {
			                      if (i > 0 && textAddressPart !== '') {
			                        if (component === 'address1') {
			                          textAddressPart += ' ';
			                        } else if (component === 'zip') {
			                          textAddressPart += '-';
			                        }
			                      }
			                      textAddressPart += autoFeelFields[subComponent];
			                    }
			                  } else {
			                    // For fields with alternative values
			                    if (subComponent !== 'alternative') {
			                      if (autoFeelFields[subComponent] !== 'long_name' && autoFeelFields[subComponent] !== 'short_name') {
			                        textAddressPart = autoFeelFields[subComponent];
			                      }
			                    } else if (subComponent === 'alternative' && textAddressPart === '') {
			                      for (var sub2Component in compForm[component][subComponent]) {
			                        if (autoFeelFields[sub2Component] !== 'long_name' && autoFeelFields[sub2Component] !== 'short_name') {
			                          textAddressPart = autoFeelFields[sub2Component];
			                          break;
			                        }
			                      }
			                    }
			                  }

			                  i++;
			                }
			                
			                if(component == 'country' && textAddressPart == 'PR'){
			                	isPR = true;
			                }
			                
			                if (textAddressPart !== '') { 
			                	if(component != 'country'){
			                		$(componentSelector).val(textAddressPart).trigger("change"); 
			                	}
			                	if(component == 'states_state' && isPR){
			                		$(componentSelector).val("PR").trigger("change");
			                	}
			                }			                
			              }
			            }
			            
			            $("[id$='country']").val("US");
			            $("input[name$='_addressFields_zip']").change();			            
			          },

			          // Disabled address fields at checkout form
			          onOffAddressFields: function (flag) {
			            var notEmptyFlag = flag ? flag : true;
			            var compForm = googleAuto.componentForm;

			            for (var component in compForm) {
			              if (notEmptyFlag) {
			                var componentSelector = "[id$='" + component + "']";
			                var inputValue = $(componentSelector).val();
			                if (inputValue != '') { notEmptyFlag = false; }
			              }
			            }
			            for (var component in compForm) {
			              var componentSelector = "[id$='" + component + "']";
			              $(componentSelector).prop('disabled', notEmptyFlag);
			            }
			          },

			          // Global variables for geolocate function
			          autocomplete: {},

			          // Bias the autocomplete object to the user's geographical location,
			          // as supplied by the browser's 'navigator.geolocation' object.
			          geolocate: function (autocomplete) {
			        	  googleAuto.autocomplete = autocomplete;
			             if (navigator.geolocation) {
			              navigator.geolocation.getCurrentPosition(googleAuto.geolocationTrue, googleAuto.handleNoGeolocation);
			            }
			          },

			          // function to do all stuff after succesfull W3C Geolocation from browser
			          geolocationTrue: function (position) {
			                var geolocation = new google.maps.LatLng(
			                    position.coords.latitude, position.coords.longitude);
			                var circle = new google.maps.Circle({
			                  center: geolocation,
			                  radius: position.coords.accuracy
			                });
			                googleAuto.autocomplete.setBounds(circle.getBounds());
			          },

			          // function to do all stuff after fail with W3C Geolocation from browser
			          handleNoGeolocation: function () {
			            //console.log('Fail');
			          }
			};
			googleAuto.initialize();
		}
	};
}(window.app = window.app || {}, jQuery));


// app.quickview
(function (app, $) {
	var $cache = {};

	function bindQvButton() {
		$cache.qvButton.one("click", function (e) {
			e.preventDefault();
			app.quickView.show({
				url : $(this).attr("href"),
				source : "quickview"
			});
		});
	}


	app.quickView = {
		initializeButton : function (container, target) {
			// quick view button
			$(container).on("mouseenter", target, function (e) {
				if(!$cache.qvButton) {
					$cache.qvButton = $("<a id='quickviewbutton'>Quick View</a>");
				}
				bindQvButton();

				var link = $(this).children("a:first");
				$cache.qvButton.attr({
					"href" : link.attr("href"),
					"title" : link.attr("title")
				}).appendTo($(this));
			});
		},
		init : function () {
			if(app.quickView.exists()) {
				return $cache.quickView;
			}
			
			$cache.quickView = $("<div/>").attr("id", "QuickViewDialog").appendTo(document.body);
			return $cache.quickView;
		},
		// show quick view dialog and send request to the server to get the product
		// options.source - source of the dialog i.e. search/cart
		// options.url - product url
		show : function (options) {
			
			options.target = app.quickView.init();
			options.callback = function () {
				app.dialog.create({
					target : "#QuickViewDialog",
					options : {
						height : 'auto',
						width : 928,
						dialogClass : 'quickview' + (options.iswishlistl ? ' wishlist-qv' : ''),
						title : '',
						resizable : false,
						position : 'center',
						open : function () {
							app.progress.hide();
						}
					}
				});
				
				var modal = $("#QuickViewDialog");
				
				modal.dialog('open');
				modal.parent('.ui-dialog').css('margin-top', '10px');
				//initalize stickvogel if you want to use in quickview
				//app.stickvogel.init();
				if(!app.isMobileUserAgent){
					$.select2();
				}
				app.product.init();
				
				//Letting Google Tag Manager now that we opened a quickveiw modal
				if (typeof dataLayer != "undefined"){
					dataLayer.push({
						'event': 'quick-view-init',
						'bloomreachQuickView': {
						      "prod_id" : modal.find("input[name='pid']").val(),
						      "sku": '',
						      "prod_name": modal.find(".product-name .pdp-brand-wrapper").text() + ' ' + modal.find(".product-name .pdp-name-wrapper").text()
						}
					});
				}
				
				/**
				 * Added this in because the second quick view window the description doesn't show automatically
				 * This is probably a timing issue
				 */
				modal.find('.tab-content.description').show();
			};
			app.product.get(options);

			return $cache.quickView;
		},
		// close the quick view dialog
		close : function () {
			if($("#QuickViewDialog").length) {
				$("#QuickViewDialog").dialog('close').empty();
				return $("#QuickViewDialog");
			}
		},
		exists : function () {
			return $("#QuickViewDialog") && ($("#QuickViewDialog").length > 0);
		},
		isActive : function () {
			return $("#QuickViewDialog") && ($("#QuickViewDialog").length > 0) && ($("#QuickViewDialog").children.length > 0);
		},
		container : $("#QuickViewDialog")
	};

}(window.app = window.app || {}, jQuery));

// app.util
//
//
(function (app, $) {

	// sub namespace app.util.* contains utility functions
	app.util = {

		// trims a prefix from a given string, this can be used to trim
		// a certain prefix from DOM element IDs for further processing on the ID
		trimPrefix : function (str, prefix) {
			return str.substring(prefix.length);
		},

		setDialogify: function (e) {
			e.preventDefault();
			var actionSource = $(this),
				dlgAction = $(actionSource).data("dlg-action") || {}, // url, target, isForm
				dlgOptions = $.extend({}, app.dialog.settings, $(actionSource).data("dlg-options") || {});

			dlgOptions.title = dlgOptions.title || $(actionSource).attr("title") || "";

			// url from data
			var url = dlgAction.url ||
					  (dlgAction.isForm ? $(actionSource).closest("form").attr("action") : null) || // or url from form action if isForm=true
					  $(actionSource).attr("href"); // or url from href

			if (!url) { return; }

			var form = jQuery(this).parents('form');
			var method = form.attr("method")||"POST";

			// if this is a content link, update url from Page-Show to Page-Include
			if ($(this).hasClass("attributecontentlink")) {
				var uri = app.util.getUri(url);
				url = app.urls.pageInclude+uri.query;
			}
			if (method && method.toUpperCase() == "POST") {
		         var postData = form.serialize() + "&"+ jQuery(this).attr("name") + "=submit";
		    } else {
		         if (url.indexOf('?') == -1 ) {
		        	 url += '?';
		         } else {
		        	 url += '&';
		         }
		         url += form.serialize();
		         url = app.util.appendParamToURL(url, jQuery(this).attr('name'), "submit");
			}

			var dlg = app.dialog.create({target:dlgAction.target, options : dlgOptions});

			app.ajax.load({
				url:$(actionSource).attr("href") || $(actionSource).closest("form").attr("action"),
				target:dlg, callback: function () {
					dlg.dialog("open");	// open after load to ensure dialog is centered
					app.validator.init(); // re-init validator
					if(!app.isMobileUserAgent){
						$.select2(); //init select2
					}
				},
				data : !$(actionSource).attr("href") ? postData : null

			});
		},

		padLeft : function (str, padChar, len) {
			var digs = len || 10;
			var s = str.toString();
			var dif = digs - s.length;
			while(dif > 0) {
				s = padChar + s;
				dif--;
			}
			return s;
		},
		// appends the parameter with the given name and
		// value to the given url and returns the changed url
		appendParamToURL : function (url, name, value) {
			var c = "?";
			if(url.indexOf(c) !== -1) {
				c = "&";
			}
			return url + c + name + "=" + encodeURIComponent(value);
		},

		appendParamsToUrl : function (url, params) {
			var uri = app.util.getUri(url),
				includeHash = arguments.length < 3 ? false : arguments[2];

			var qsParams = $.extend(uri.queryParams, params);
			var result = uri.path+"?"+$.param(qsParams);
			if (includeHash) {
				result+=uri.hash;
			}
			if (result.indexOf("http")<0 && result.charAt(0)!=="/") {
				result="/"+result;
			}

			return result;
		},

		removeParamFromURL : function (url, parameter) {
			var urlparts = url.split('?');

			if(urlparts.length >= 2) {
				var urlBase = urlparts.shift();
				var queryString = urlparts.join("?");

				var prefix = encodeURIComponent(parameter) + '=';
				var pars = queryString.split(/[&;]/g);
				var i=pars.length;
				while(0 > i--) {
					if(pars[i].lastIndexOf(prefix, 0) !== -1) {
						pars.splice(i, 1);
					}
				}
				url = urlBase + '?' + pars.join('&');
			}
			return url;
		},

		staticUrl : function (path) {
			if(!path || $.trim(path).length === 0) {
				return app.urls.staticPath;
			}

			return app.urls.staticPath + (path.charAt(0) === "/" ? path.substr(1) : path );
		},

		ajaxUrl : function (path) {
			return app.util.appendParamToURL(path, "format", "ajax");
		},

		toAbsoluteUrl : function (url) {
			if (url.indexOf("http")!==0 && url.charAt(0)!=="/") {
				url = "/"+url;
			}
			return url;
		},

		loadDynamicCss : function (urls) {
			var i, len=urls.length;
			for(i=0; i < len; i++) {
				app.util.loadedCssFiles.push(app.util.loadCssFile(urls[i]));
			}
		},

		// dynamically loads a CSS file
		loadCssFile : function (url) {
			return $("<link/>").appendTo($("head")).attr({
				type : "text/css",
				rel : "stylesheet"
			}).attr("href", url); // for i.e. <9, href must be added after link has been appended to head
		},
		// array to keep track of the dynamically loaded CSS files
		loadedCssFiles : [],

		// removes all dynamically loaded CSS files
		clearDynamicCss : function () {
			var i = app.util.loadedCssFiles.length;
			while(0 > i--) {
				$(app.util.loadedCssFiles[i]).remove();
			}
			app.util.loadedCssFiles = [];
		},

		getQueryStringParams : function (qs) {
			if(!qs || qs.length === 0) { return {}; }

			var params = {}, unescapedQS = decodeURI(qs.replace(/\+/g, '%20'));
			// Use the String::replace method to iterate over each
			// name-value pair in the string.
			unescapedQS.replace( new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
						function ( $0, $1, $2, $3 ) {	params[ $1 ] = $3; }
			);
			return params;
		},

		getUri : function (o) {
			var a;
			if (o.tagName && $(o).attr("href")) {
				a = o;
			}
			else if (typeof o === "string") {
				a = document.createElement("a");
				a.href = o;
			}
			else {
				return null;
			}

			return {
				protocol : a.protocol, //http:
				host : a.host, //www.myexample.com
				hostname : a.hostname, //www.myexample.com'
				port : a.port, //:80
				path : a.pathname, // /sub1/sub2
				query : a.search, // ?param1=val1&param2=val2
				queryParams : a.search.length > 1 ? app.util.getQueryStringParams(a.search.substr(1)) : {},
				hash : a.hash, // #OU812,5150
				url : a.protocol+ "//" + a.host + a.pathname,
				urlWithQuery : a.protocol+ "//" + a.host + a.port + a.pathname + a.search
			};
		},

		postForm : function (args) {
			var form = $("<form>").attr({action:args.url,method:"post"}).appendTo("body");
			var p;
			for (p in args.fields) {
				$("<input>").attr({name:p,value:args.fields[p]}).appendTo(form);
			}
			form.submit();
		},

		getMessage : function (key, bundleName, callback) {
			if (!callback || !key || key.length===0) {
				return;
			}
			var params = {key:key};
			if (bundleName && bundleName.length===0) {
				params.bn = bundleName;
			}
			var url = app.util.appendParamsToUrl(app.urls.appResources, params);
			$.getJSON(url, callback);
		},

		hidePhoneExample : function (countryCode,phoneInput){
			//remove/Add Phone number example
			if(countryCode === "US"){
				phoneInput.siblings("span.form-caption").show();
			} else{
				phoneInput.siblings("span.form-caption").hide();
			}
		},

		/**
		 * int_clearpath: Include code/app.js 8
		 *
		 * Added show/hide functionality of the input form depending on if the Country has state options
		 */
		updateStateOptions : function(countrySelect) {
			var country = $(countrySelect),
				countryValue = country.val(),
				form = country.closest("form"),
				phoneInput = form.find("input[name$='_phone']");
			//remove/Add Phone number example
			app.util.hidePhoneExample(countryValue, phoneInput);

			var stateField = country.data("stateField") ? country.data("stateField") : form.find("select[name$='_states_state']"),
				stateFieldInput = form.find("input[name$='_state']");

			if (stateField.length===0) {
				return;
			}

			var c = app.countries[countryValue],
				arrHtml = [],
				labelSpan = form.find("label[for='"+stateField[0].id+"'] span").not(".required-indicator");

			if (c) {
				// set the label text
				labelSpan.html(c.label);
			}

			//reset select2 label span
			$(stateField).siblings(".select2-container").find(".select2-choice span").text("");

			if(c && c.regions){

				var s,//region value
					i=0;//array counter
				for (s in c.regions) {
					arrHtml[i++]='<option value="'+s+'">'+c.regions[s]+'</option>';
				}
				// clone the empty option item and add to stateSelect
				var o1 = stateField.children().first().clone();

				stateField.html(arrHtml.join("")).removeAttr("disabled").children().first().before(o1);
				stateField[0].selectedIndex=0;
				app.clearpath.setStateRequired(stateField,stateFieldInput);
			} else {
				stateField.find('[value="OTHER"]').prop('selected',true);
				app.clearpath.setStateRequired(stateFieldInput,stateField);
			}
		},

		limitCharacters : function () {
			$('form').find('textarea[data-character-limit]').each(function(){
				var characterLimit = $(this).data("character-limit");
				var charCountHtml = String.format(app.resources.CHAR_LIMIT_MSG,
										'<span class="char-remain-count">'+characterLimit+'</span>',
										'<span class="char-allowed-count">'+characterLimit+'</span>');
				var charCountContainer = $(this).next('div.char-count');
				if (charCountContainer.length===0) {
					charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
				}
				charCountContainer.html(charCountHtml);
				// trigger the keydown event so that any existing character data is calculated
				$(this).change();
			});
		},

		setDeleteConfirmation : function(container, message) {
			$(container).on("click", ".delete", function(e){
				return confirm(message);
			});
		},

		scrollBrowser : function (xLocation) {
			$('html, body').animate({ scrollTop: xLocation }, 500);
		},

		/**
		 * @function
		 * @description Returns event of css transition ending
		 */
		getTransEndEvent: function() {
			var t,
				el = document.createElement("fakeelement"),
				transitions = {
					"transition"      : "transitionend",
					"OTransition"     : "oTransitionEnd",
					"MozTransition"   : "transitionend",
					"WebkitTransition": "webkitTransitionEnd"
				};

			for (t in transitions){
				if (el.style[t] !== undefined){
					return transitions[t];
				}
			}
		}
	};
}(window.app = window.app || {}, jQuery));

// app.page
(function (app, $) {

	app.page = {
		title : "",
		type : "",
		setContext : function (o) {
			$.extend(app.page, o);
		},
		params : app.util.getQueryStringParams(window.location.search.substr(1)),
		redirect : function(newURL) {
			var t=setTimeout("window.location.href='"+newURL+"'",0);
		},
		refresh : function() {
			var t=setTimeout("window.location.assign(window.location.href);",500);

		}
	};
}(window.app = window.app || {}, jQuery));

// app.registry
(function (app, $) {
	var $cache = {};

	function populateBeforeAddressForm(addressID) {
		// load address details
		var url = app.urls.giftRegAdd + addressID;
		 app.ajax.getJson({
			url: url,
			callback: function (data) {
				if(!data || !data.address) {
					window.alert(app.resources.REG_ADDR_ERROR);
					return false;
				}
				// fill the form
				$cache.addressBeforeFields.filter("[name$='_addressid']").val(data.address.ID);
				$cache.addressBeforeFields.filter("[name$='_firstname']").val(removeExtraChars(data.address.firstName));
				$cache.addressBeforeFields.filter("[name$='_lastname']").val(removeExtraChars(data.address.lastName));
				$cache.addressBeforeFields.filter("[name$='_address1']").val(removeExtraChars(data.address.address1));
				$cache.addressBeforeFields.filter("[name$='_address2']").val(removeExtraChars(data.address.address2));
				$cache.addressBeforeFields.filter("[name$='_city']").val(removeExtraChars(data.address.city));
				$cache.addressBeforeFields.filter("[name$='_zip']").val(removeExtraChars(data.address.postalCode));
				$cache.addressBeforeFields.filter("[name$='_state']").val(removeExtraChars(data.address.stateCode));
				$cache.addressBeforeFields.filter("[name$='_country']").val(removeExtraChars(data.address.countryCode));
				$cache.addressBeforeFields.filter("[name$='_phone']").val(removeExtraChars(data.address.phone));
				$cache.registryForm.validate().form();
			}
		});
	}

	//updates the after address form with the attributes of a given address
	function populateAfterAddressForm(addressID) {
		// load address details
		var url = app.urls.giftRegAdd + addressID;
		 app.ajax.getJson({
			url: url,
			callback: function (data) {
				if(!data || !data.address) {
					window.alert(app.resources.REG_ADDR_ERROR);
					return false;
				}
				// fill the form
				$cache.addressAfterFields.filter("[name$='_addressid']").val(data.address.ID);
				$cache.addressAfterFields.filter("[name$='_firstname']").val(removeExtraChars(data.address.firstName));
				$cache.addressAfterFields.filter("[name$='_lastname']").val(removeExtraChars(data.address.lastName));
				$cache.addressAfterFields.filter("[name$='_address1']").val(removeExtraChars(data.address.address1));
				$cache.addressAfterFields.filter("[name$='_address2']").val(removeExtraChars(data.address.address2));
				$cache.addressAfterFields.filter("[name$='_city']").val(removeExtraChars(data.address.city));
				$cache.addressAfterFields.filter("[name$='_zip']").val(removeExtraChars(data.address.postalCode));
				$cache.addressAfterFields.filter("[name$='_state']").val(removeExtraChars(data.address.stateCode));
				$cache.addressAfterFields.filter("[name$='_country']").val(removeExtraChars(data.address.countryCode));
				$cache.addressAfterFields.filter("[name$='_phone']").val(data.address.phone);
				$cache.registryForm.validate().form();
			}
		});
	}

	//copy address before fields to address after fields
	function copyBeforeAddress() {
		$cache.addressBeforeFields.each(function () {
			var fieldName = $(this).attr("name");
			var afterField = $cache.addressAfterFields.filter("[name='"+fieldName.replace("Before","After")+"']");
			afterField.val($(this).val());
		});
	}

	// disable the address after fields
	function setAfterAddressDisabled(disabled) {
		if (disabled) {
			$cache.addressAfterFields.attr("disabled", "disabled");
		}
		else {
			$cache.addressAfterFields.removeAttr("disabled");
		}
	}

	function initializeCache() {
		$cache = {
			registryForm : $("form[name$='_giftregistry']"),
			registryItemsTable : $("form[name$='_giftregistry_items']"),
			registryTable : $("#registry-results")
		};
		$cache.copyAddress = $cache.registryForm.find("input[name$='_copyAddress']");
		$cache.addressBeforeFields = $cache.registryForm.find("fieldset[name='address-before'] input:not(:checkbox), fieldset[name='address-before'] select");
		$cache.addressAfterFields = $cache.registryForm.find("fieldset[name='address-after'] input:not(:checkbox), fieldset[name='address-after'] select");
	}

	function initializeDom() {
		$cache.addressBeforeFields.filter("[name$='_country']").data("stateField", $cache.addressBeforeFields.filter("[name$='_state']"));
		$cache.addressAfterFields.filter("[name$='_country']").data("stateField", $cache.addressAfterFields.filter("[name$='_state']"));

		if ($cache.copyAddress.length && $cache.copyAddress[0].checked) {
			// fill the address after fields
			copyBeforeAddress();
			setAfterAddressDisabled(true);
		}
	}

	function initializeEvents() {
		app.sendToFriend.initializeDialog("div.list-table-header", ".send-to-friend");
		app.util.setDeleteConfirmation("table.item-list", String.format(app.resources.CONFIRM_DELETE, app.resources.TITLE_GIFTREGISTRY));

		$cache.copyAddress.on("click", function () {
			if (this.checked) {
				// fill the address after fields
				copyBeforeAddress();
			}
		});
		$cache.registryForm.on("change", "select[name$='_addressBeforeList']", function (e) {
			var addressID = $(this).val();
			if (addressID.length===0) { return; }
			populateBeforeAddressForm(addressID);
			if ($cache.copyAddress[0].checked) {
				copyBeforeAddress();
			}
		})
		.on("change", "select[name$='_addressAfterList']", function (e) {
			var addressID = $(this).val();
			if (addressID.length===0) { return; }
			populateAfterAddressForm(addressID);
		})
		.on("change", $cache.addressBeforeFields.filter(":not([name$='_country'])"), function (e) {
			if (!$cache.copyAddress[0].checked) { return; }
			copyBeforeAddress();
		});


		$("form").on("change", "select[name$='_country']", function(e) {
			app.util.updateStateOptions(this);

			if ($cache.copyAddress.length > 0 && $cache.copyAddress[0].checked && this.id.indexOf("_addressBefore") > 0) {
				copyBeforeAddress();
				$cache.addressAfterFields.filter("[name$='_country']").trigger("change");
			}
		});

		$cache.registryItemsTable.on("click", ".item-details a", function (e) {
			e.preventDefault();
			var productListID = $('input[name=productListID]').val();
			app.quickView.show({
				url : e.target.href,
				source : "giftregistry",
				productlistid : productListID
			});
		});
	}


	app.registry = {
		init : function () {
			initializeCache();
			initializeDom();
			initializeEvents();
			app.product.initAddToCart();
		}
	};

}(window.app = window.app || {}, jQuery));

// app.progress
(function (app, $) {
	var loader;
	app.progress = {
		show: function (container) {
			var target = (!container || $(container).length===0) ? $("body") : $(container);
			loader = target.find(".loader");

			if (loader.length===0) {
				loader = $("<div/>").addClass("loader")
									.append($("<div/>").addClass("loader-indicator"), $("<div/>").addClass("loader-bg"));
				loader.appendTo(target);
			}
			return loader.show();
		},
		hide: function () {
			$(".loader").hide();
		},
		reposition: function (container) {
			var target = (!container || $(container).length===0) ? $("body") : $(container);
			var loader = target.find(".loader");

			var header = $("#header");
			var footer = $("#footer");

			var height = $(window).height();
			var scrollTop = $(window).scrollTop();
			var loaderIndicator = loader.find(".loader-indicator");
			var docViewBottom = scrollTop + height;

			var footerTop = footer.offset().top;
    		var footerBottom = footerTop + footer.height();

    		var headerfooter = header.height();
    		if ((footerBottom <= docViewBottom) && (footerTop >= scrollTop)) {
    			headerfooter = headerfooter + (docViewBottom - footerTop);
    		}

    		var newheight = (scrollTop - header.height()) + (height - headerfooter - loaderIndicator.height())/2;
			$(loaderIndicator).css("top", newheight + "px");
		}
	};
}(window.app = window.app || {}, jQuery));

// app.components
(function (app, dw, $) {
	// capture recommendation of each product when it becomes visible in the carousel

	function captureCarouselRecommendations(c, li, index, state) {
		if (!dw) { return; }

		$(li).find(".capture-product-id").each(function () {
			dw.ac.capture({
				id : $(this).text(),
				type : dw.ac.EV_PRD_RECOMMENDATION
			});
		});
	}

	app.components = {
		carouselSettings : {
			scroll : 1,
			itemFallbackDimension: '100%',
			itemVisibleInCallback : app.captureCarouselRecommendations
		},
		init : function () {
			setTimeout(function(){
				//renders horizontal/vertical carousels for product slots
				//TODO: rebuild carousel actions
				//false && $('#vertical-carousel').jcarousel($.extend({vertical : true}, app.components.carouselSettings));
				//false && $('#horizontal-carousel').jcarousel(app.components.carouselSettings);
			}, 1000);
		}
	};
}(window.app = window.app || {}, window.dw, jQuery));

// app.cart
(function (app, $) {
	var $cache = {};

	function updateCart(postdata, callback) {
		var url = app.util.ajaxUrl(app.urls.addProduct);
		$.post(url, postdata, callback || app.cart.refresh);
	}

	function initializeCache() {
		$cache = {
			cartTable : $("#cart-table"),
			itemsForm : $("#cart-items-form"),
			addCoupon : $("#add-coupon"),
			couponCode : $("form input[name$='_couponCode']"),
			quantity : $("form input[name$='_quantity']"),
			form : $("#cart-items-form"),
			main : $("#main"),
			mobileCount : $(".js-mobile-count")

		};
	}

	function initializeEvents() {
		$cache.cartTable.on("click", ".item-user-actions a.editproduct", function (e) {
			e.preventDefault();
			app.quickView.show({
				url : e.target.href,
				source : "cart"
			});
		});

		function getCookie(name) {
			  var matches = document.cookie.match(new RegExp(
			    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
			  ));
			  return matches ? decodeURIComponent(matches[1]) : undefined;
		}

		var isFreeGift = $(".bonus-item-actions a.select-bonus").length,
			bonusProductsLength = $("#cart-table .bonus-product").length,
			ponusPopupShow = getCookie('bonusPopupShow'),
			productsLength = $('.cart-row').length,
			oldProductLength = getCookie('productsLength'),
			closeCount = sessionStorage.getItem('bonusPopupCloseCount');

		if(oldProductLength && (productsLength != oldProductLength)){
			ponusPopupShow = "true";
		}
		if(closeCount && closeCount >=2){
			ponusPopupShow = "false";
		}

		$(".bonus-item-actions").on("click", "a", function (e) {
			e.preventDefault();
			app.bonusProductsView.show(this.href);
		});

		if(isFreeGift && !bonusProductsLength && ponusPopupShow !== "false"){//bonus prod not choosed
			$(".bonus-item-actions a.select-bonus").trigger('click');
		}

		if(!app.isMobileUserAgent){
			$cache.quantity.on('change', function(e){
				app.cart.updateForm();
			});
		}

		// override enter key for coupon code entry
		$cache.couponCode.on("keydown", function (e) {
			if (e.which === 13 && $(this).val().length===0) { return false; }
		});

		trackGA4CheckoutEvent('Checkout - Cart', 1);
	}

	app.cart = {
		add : function (postdata, callback) {
			updateCart(postdata, callback);
		},
		remove : function () {
			return;
		},
		update : function (postdata, callback) {
			updateCart(postdata, callback);
		},
		refresh : function () {
			// refresh without posting
			app.page.refresh();
		},
		init : function () {
			// edit shopping cart line item
			initializeCache();
			initializeEvents();
		},
		updateForm : function(){
			app.progress.show($("#cart-table"));
			var data = $("#cart-items-form").serialize();
			$.ajax({
				'type' : 'POST',
				'url' : $cache.form.data("ajax-url"),
				'data' : data,
				 success : function(data){
					 var newForm = $(data).find("#main");
					 var newCount = parseInt($(data).find(".js-mobile-count").first().text());

					 $cache.main.empty().append(newForm.html());
					 $cache.mobileCount.text(newCount);

					 app.cart.init();
					 app.stickvogel.init();
					 
					 setTimeout(function(){
						 window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
						 window.KlarnaOnsiteService.push({eventName: 'refresh-placements'});
					 }, 1500);
					 
					 app.validator.init();
					 app.progress.hide();

					 $(".mobile-bar-title").css("visibility", "initial");
					 
					var estimatedTotal = +$(data).find('[data-estimated-total]').attr('data-estimated-total');
					var klarnaMin = +$(data).find('[data-klarna-min]').attr('data-klarna-min');
					
					if(!isNaN(estimatedTotal) && !isNaN(klarnaMin) && estimatedTotal >= klarnaMin){
						 if(typeof Klarna != "undefined" && typeof Klarna.ExpressButton != "undefined"){
							 Klarna.ExpressButton.refreshButtons();
						 }
					}
				 }
			});
		}

	};

}(window.app = window.app || {}, jQuery));

// app.account
(function (app, $) {
	var $cache = {};

	function initializeAddressForm(form) {
		var form = $("#edit-address-form"),
			$applyButton = form.find('.apply-button'),
			$cancelButton = form.find('.cancel-button'),
			$closeButton = form.find('.close-button'),
			$deleteButton = form.find('.delete-button');

		form.find("input[name='format']").remove();
		app.tooltips.init();
		//$("<input/>").attr({type:"hidden", name:"format", value:"ajax"}).appendTo(form);

		//init select2
		if(!app.isMobileUserAgent){
			$.select2();
		}
		$applyButton.on('click', function(e) {
			e.preventDefault();
			var addressId = form.find("input[name$='_addressid']");

			addressId.val(addressId.val().replace(/[^\w+-]/g, "-"));

			if (!form.valid()) {
				return false;
			}

			var url = app.util.appendParamsToUrl(form.attr('action'),{format:"ajax"});
			var applyName = form.find('.apply-button').attr('name');
			var options = {
				url: url,
				data: form.serialize()+"&"+applyName+'=x',
				type: "POST"
			};
			$.ajax( options ).done(function(data){				
				app.page.refresh();
			});
		});

		$cancelButton.on("click", function(e){
			app.dialog.close();
			e.preventDefault();
		});

		$closeButton.on("click", function(e){
			app.dialog.close();
			e.preventDefault();
		});

		$deleteButton.on("click", function(e){
			e.preventDefault();
			if (confirm(String.format(app.resources.CONFIRM_DELETE, app.resources.TITLE_ADDRESS))) {
				var url = app.util.appendParamsToUrl(app.urls.deleteAddress, {AddressID:form.find("#addressid").val(),format:"ajax"});
				$.ajax({
					url: url,
					method: "POST",
					dataType:"json"
				}).done(function(data){
					if (data.status.toLowerCase()==="ok") {
						app.dialog.close();
						app.page.refresh();
					}
					else if (data.message.length>0) {
						alert(data.message);
						return false;
					}
					else {
						app.dialog.close();
						app.page.refresh();
					}
				});
			}
		});

		$cache.countrySelect = form.find("select[id$='_country']");
		app.validator.init();

		setTimeout(() => {
			if(!app.isMobileUserAgent){
				$("#edit-address-form").find("select").select2("val", '');
				$cache.countrySelect.select2("val", "US");
			} else {
				$("#edit-address-form").find("select").val('');
				$cache.countrySelect.val("US");
			}
			app.util.updateStateOptions($cache.countrySelect);
		}, 3000);
		/**
		 * int_clearpath: Include - code/app.js - 9
		 *
		 * Initialize the clearpath namespace
		 */
		app.clearpath.init();
	}

	function toggleFullOrder () {
		$('.order-items')
			.find('li.hidden:first')
				.prev('li')
					.append('<a class="toggle">View All</a>')
					.children('.toggle')
						.click(function() {
							$(this).parent().siblings('li.hidden').show();
							$(this).remove();
						});
	}

	function initAddressEvents() {
		var addresses = $("#addresses");
		if (addresses.length===0) { return; }

		addresses.on("click", "a.address-edit, a.address-create", function(e){
			e.preventDefault();
			var options = {open: initializeAddressForm};
			app.dialog.open({url:this.href, options:options});
		});//.on("click", ".delete", function(e){
//			e.preventDefault();
//			if (confirm(String.format(app.resources.CONFIRM_DELETE, app.resources.TITLE_ADDRESS))) {
//				$.ajax({
//					url: app.util.appendParamsToUrl($(this).attr("href"), {format:"ajax"}),
//					dataType:"json"
//				}).done(function(data){
//					if (data.status.toLowerCase()==="ok") {
//						app.page.redirect(app.urls.addressesList);
//					}
//					else if (data.message.length>0) {
//						alert(data.message);
//					}
//					else {
//						app.page.refresh();
//					}
//				});
//			}
//		});
	}
	function initPaymentEvents() {
		var paymentList = $(".payment-list");
		if (paymentList.length===0) { return; }

		app.util.setDeleteConfirmation(paymentList, String.format(app.resources.CONFIRM_DELETE, app.resources.TITLE_CREDITCARD));

		$("form[name='payment-remove']").on("submit", function(e){
			e.preventDefault();
			// override form submission in order to prevent refresh issues
			var button = $(this).find("button.delete");
			$("<input/>").attr({type:"hidden", name:button.attr("name"), value:button.attr("value")||"delete card"}).appendTo($(this));
			var data = $(this).serialize();
			$.ajax({
				type: "POST",
				url: $(this).attr("action"),
				data: data
			})
			.done(function(response) {
				app.page.redirect(app.urls.paymentsList);
			});
		});
	}

	function reloadRewardPoints() {
		var reload = setInterval(function(){
			var $pointsCnt = $('.js-points-balance');
			if ($pointsCnt && $pointsCnt.length) {
				if(parseInt($pointsCnt.text()) > 0){
					clearInterval(reload);					
				}
				var url = app.urls.reloadRewardPoints;
				app.ajax.load({
					url:url,
					target: $pointsCnt,
					callback: function(data){
						if (parseInt(data) >= 1000) {
							$pointsCnt.addClass('show-rewards').text('$' + Math.floor((data/1000)));
						} else {
							$pointsCnt.removeClass('show-rewards');
						}
					}
				});
			}
		}, 2000);

	}

	function initializeEvents() {
		toggleFullOrder();
		initAddressEvents();
		initPaymentEvents();
	}

	app.account = {
		init : function () {
			initializeEvents();
			reloadRewardPoints();
			app.giftcert.init();
		}
	};
}(window.app = window.app || {}, jQuery));

// app.wishlist
(function (app, $) {
	var initialized = false;

	function getCookie(name) {
		var matches = document.cookie.match(new RegExp(
		"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
		));
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays*24*60*60*1000));
		var expires = "expires="+ d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function eraseCookie(name) {
		document.cookie = name+'=; Max-Age=-99999999;path=/';
	}

	function initializeEvents() {
		initialized = true;

		// wishlist landing tile btn add-to-cart
		$(document).on('click', '.wishlist-btn-add:not(.disabled)', function(e){
			var that = this;
			e.stopPropagation;
			var data= {
				Quantity:"1",
				cartAction:"add",
				pid:$(that).data('pid'),
				pid_master:$(that).data('master')
			};
			app.cart.update(data, function (response) {
				if (typeof dataLayer != "undefined"){
					dataLayer.push({'event': 'add-to-card'});
				}
				app.minicart.show(response);
				$(that).addClass('disabled').text(app.resources.WISHLIST_ITEM_IN_CART);
				
				dataLayer.push({
					event: 'wishlist_plp_add_to_cart',
					wishlist_item_id: $(that).data('pid') + ''
				});
			});
		});

		// wishlist landing tile btn quickview
		$(document).on('click', '.wishlist-btn-quickview:not(.disabled)', function(e){
			e.preventDefault();
			var productId = $(this).data("product-id");
	    	var productUrl = $(this).data("product-url");
	    	if (!productId && !productUrl) return false;
	    	if (!productUrl && productId) {
	    		productUrl = app.util.appendParamToURL(app.urls.getProductUrl, 'pid', productId);
	    	}
	    	app.quickView.show({
				url : productUrl,
				source : "quickview",
				iswishlistl : 'true',
			});
		});

		// header section remove event
		$(document).on('click', '.js-remove-from-wishlist', function(e){
			e.stopPropagation;
			var pid = $(this).data('pid');
			app.wishlist.removeProduct(pid);
		});

		// wishlist instance change state
		$(document).on('click', '.js-item-wishlist .product-wishlist-icon', function(e){
			var $container = $(this).closest('.js-item-wishlist');
			actionToDo = $container.hasClass('active'); // has class = remove product
			var pid = $container.data('itemid');
			if (actionToDo) {
				app.wishlist.removeProduct(pid, $container);
			} else {
				app.wishlist.addProduct(pid, $container);
			}
		});

		// show share link popup
		$(document).on('click', '.js-share-wishlist', function(e){
			var $popup = $(this).next().clone();
			var options = {
				height:'auto',
				position: {
					my: "center center",
					at: "center center",
					of: window,
				},
			}
			if (app.isMobileUserAgent) {
				options.classes = {
					"ui-dialog": "ui-dialog ui-share-wishlist"
				}
			}
			var dlg = app.dialog.create({target: $popup, options: options});
			dlg.dialog("open");
		});

		// copy wishlist link handler
		$(document).on('click', '.wishlist-share-popup a', function(e){
			e.preventDefault();
			navigator.clipboard.writeText($(this).attr('href'));
			$(this).addClass('active');
			dataLayer.push({
				event: 'wishlist_share',
				wishlist_id: $(this).data('wishlist-id') + ''
			});
		});

		$(document).on('click', '#mini-wishlist .wishlist-link, .mobile-icons-wrapper .wishlist-link', function(e){
			dataLayer.push({
				event: 'wishlist_my_fav_cta',
			});
		});
		app.wishlist.ajaxLoad();
	}

	app.wishlist = {
		init : function () {
			if (!initialized) {
				initializeEvents();
			}
		},
		ajaxLoad : function() {
			if (Boolean($('#myAccountLink .user-account').length)) {
				eraseCookie('wl_items');
			}
			var itemIDsArray = '',
				cookieItems = getCookie('wl_items');

			if (cookieItems && cookieItems.length > 0) {
				itemIDsArray = cookieItems;
			}
			
			var isLandingPage = Boolean($('#wrapper.pt_wishlist').length),
				showOtherWL = Boolean($('#is-show-other-wishlist').length),
				url = app.util.appendParamsToUrl(app.urls.wishlistAjaxLoad, {pids : itemIDsArray, loadmain: isLandingPage && !showOtherWL});

			$.ajax({
				method: 'post',
				url : url,
				success : function(res) {
					var $html = $('<div/>').append(res),
						newItemsIds = $(res).find('.js-w-items-list').text(),
						newItemsIdsArray = newItemsIds.split('|');

					$('.js-wl-count-mobile').replaceWith($html.find('.js-wl-count-mobile'));
					$('#mini-wishlist .wishlist-link').replaceWith($html.find('.wishlist-link'));
					$('.mini-wishlist-content').replaceWith($html.find('.mini-wishlist-content'));

					app.miniwishlist.init();
					
					dataLayer.push({
						wishlist_view: app.isMobileUserAgent ? 'mobile' : 'desktop',
						wishlist_items: newItemsIdsArray.join(','),
						wishlist_items_count: newItemsIdsArray.length,
						wishlist_logged_in: ($('#myAccountLink .user-account').length > 0) + '',
					});
					dataLayer.push({event: 'items_in_wishlist'});

					if (!showOtherWL) {
						$('.wishlist-header-section').append($html.find('.wishlist-share-container'));
						$('.wishlist-main-section').replaceWith($html.find('.wishlist-main-section'));
						app.wishlist.updateContainersState(newItemsIds);
					}
				}
			});
		},
		addProduct : function (pid, $container) {
			var url = app.util.appendParamsToUrl(app.urls.addProductWishlist, {pid : pid});
			$.ajax({
				method: 'post',
				url : url,
				success : function(res){
					var html = $(res);
					app.miniwishlist.show(html);
					app.wishlist.updateContainersState(html.find('.js-w-items-list').text(), $container);
					dataLayer.push({
						event: 'add_to_wishlist',
						wishlist_item_id: pid + '',
						wishlist_page_type: $container.data('instance-type'),
					});
				}
			});
		},
		removeProduct : function (pid, $container, silent) {
			var url = app.util.appendParamsToUrl(app.urls.removeProductWishlist, {pid : pid});
			$.ajax({
				method: 'post',
				url : url,
				success : function(res){
					var html = $(res);
					app.miniwishlist.show(html, silent);
					app.wishlist.updateContainersState(html.find('.js-w-items-list').text());
					dataLayer.push({
						event: 'remove_from_wishlist',
						wishlist_item_id: pid + '',
						wishlist_page_type: $container.data('instance-type'),
					});
				}
			});
		},
		updateContainersState: function (itemIDsArray, $container) {
			var containersList = $('.js-item-wishlist');

			containersList.removeClass('ajax-added');

			if ($('#myAccountLink .user-account').length) {
				containersList.addClass('wl-user-registered');
			} else {
				setCookie('wl_items', itemIDsArray, 365);
				containersList.addClass('wl-user-anonymous');
			}

			if (itemIDsArray.length > 0) {
				itemIDsArray.split('|').forEach(function (pid) {
					containersList.filter('[data-itemid=' + pid + ']').addClass('setToActiveState');
				});
			}
			
			containersList.filter('.setToActiveState').addClass('active');
			containersList.filter(':not(.setToActiveState)').removeClass('active');
			containersList.removeClass('setToActiveState');
			if ($container) {
				$container.addClass('ajax-added');
			}
		},
	};
}(window.app = window.app || {}, jQuery));

// app.minicart
(function (app, $) {
	// sub name space app.minicart.* provides functionality around the mini cart

	var $cache = {},
		initialized = false;

	var timer = {
		id : null,
		clear : function () {
			if(timer.id) {
				window.clearTimeout(timer.id);
				delete timer.id;
			}
		},
		start : function (duration) {
			timer.id = setTimeout(app.minicart.close, duration || 0);
		}
	};

	app.previousScrollTop = 0;
	app.scrollLock = false;
	var scrollLockInit = false;
	app.minicart = {
		url : "", // during page loading, the Demandware URL is stored here

		// initializations
		init : function () {
			$cache.minicart = $("#mini-cart");
			$cache.mcTotal = $cache.minicart.find(".mini-cart-total");
			$cache.mcContent = $cache.minicart.find(".mini-cart-content");
			$cache.mcClose = $cache.minicart.find(".mini-cart-close");
			$cache.mcProductList = $cache.minicart.find(".mini-cart-products");
			$cache.mcProducts = $cache.mcProductList.children(".mini-cart-product");

			//var collapsed = $cache.mcProductList.children().not(":first").addClass("collapsed");


			// bind hover event to the cart total link at the top right corner
			$cache.minicart.on("mouseenter", ".mini-cart-total", function () {
				if($cache.mcContent.not(":visible")) {
					//app.minicart.slide();
				}
			});

			//$cache.mcProducts.append('<div class="mini-cart-toggler">&nbsp;</div>');

			//$cache.mcProductList.toggledList({toggleClass : "collapsed", triggerSelector:".mini-cart-toggler", eventName:"click"});

			_utils.push(["init"]);

			initialized = true;
		},
		// shows the given content in the mini cart
		show : function (html) {
			if(app.isMobileUserAgent){
				console.log(html);
				$(".mobile-bar-item-3-3.cart").empty().html(html);
				var dlg = app.dialog.create({target:".mobile-bar-item-3-3.cart .mini-cart-content", options:{
					height:'auto'
				}});
				dlg.dialog("open");
				app.scrollLock = true;
			}else {
				$("#mini-cart").html(html);
			}

			app.minicart.init();
			app.minicart.slide();
			if(!app.isMobileUserAgent){
				app.bonusProductsView.loadBonusOption();
			}
			else{
				$(".bonus-discount-container").hide();
			}

			$cache.minicart.find('.trackEventLoad').trigger('trackEvent');
			$(".mobile-bar-title").css("visibility", "initial");
		},
		// slide down and show the contents of the mini cart
		slide : function () {
			if(!initialized) {
				app.minicart.init();
			}

			if(app.minicart.suppressSlideDown && app.minicart.suppressSlideDown()) {
				return;
			}

			timer.clear();

			// show the item
			$cache.minicart.addClass("menuHover");

			// after a time out automatically close it
			timer.start(6000);
		},
		// closes the mini cart with given delay
		close : function () {
			timer.clear();
			//$cache.mcContent.slideUp();
			$cache.minicart.removeClass("menuHover");
		},
		// hook which can be replaced by individual pages/page types (e.g. cart)
		suppressSlideDown : function () {
			return false;
		}
	};
}(window.app = window.app || {}, jQuery));

// app.miniwishlist
(function (app, $) {
	// sub name space app.miniwishlist.* provides functionality around the mini cart
	var $cache = {},
	initialized = false;

	var timer = {
		id: null,
		clear: function () {
			if (timer.id) {
				window.clearTimeout(timer.id);
				delete timer.id;
			}
		},
		start: function (duration) {
			timer.id = setTimeout(app.miniwishlist.close, duration || 0);
		},
	};

	app.previousScrollTop = 0;
	app.scrollLock = false;
	app.miniwishlist = {
		// initializations
		init: function () {
			$cache.miniwishlist = $("#mini-wishlist");
			_utils.push(["init"]);
			initialized = true;
		},
		// shows the given content in the mini wishlist
		show: function (html, silent) {
			$("#mini-wishlist").html(html);
			$('.mobile-menu .js-wl-count-mobile').replaceWith($(html).find('.js-wl-count-mobile'));
			app.miniwishlist.init();
			if (!silent) {
				app.miniwishlist.slide();
			}
		},
		// slide down and show the contents of the mini wishlist
		slide: function () {
			if (!initialized) {
				app.miniwishlist.init();
			}
			timer.clear();
			// show the item
			$cache.miniwishlist.addClass("menuHover");
			// after a time out automatically close it
			timer.start(6000);
		},
		// closes the mini wishlist with given delay
		close: function () {
			timer.clear();
			$cache.miniwishlist.removeClass("menuHover");
		},
	};
})((window.app = window.app || {}), jQuery);

// app.dialog
(function (app, $) {
	// private

	var $cache = {};
	// end private
	
	var width = '740';
	
	if ($('.pt_account').length > 0) {
		width = '540';
	}

	app.dialog = {
		create : function (params) {
			// options.target can be an id selector or an jquery object
			var id = "dialog-container";
			var target = $(params.target || "#dialog-container");

			// if no element found, create one
			if(target.length === 0) {
				id = params.target;
				if (id !== undefined && id !== null) {
					if(id.charAt(0) === "#") {
						id = id.substr(1);
					}
				}
				target = $("<div>").attr("id", id).addClass("dialog-content").appendTo("body");
			}
			
			
			try {
				if(params && typeof params.options != 'undefined'){
					params.options.position = { my: "center center",
				            at: "center center",
				            of: window,			            
					}; 
				}
			}catch(e){}
			
			// create the dialog
			$cache.container=target;
			$cache.container.dialog($.extend(true, {}, app.dialog.settings, params.options || {}));
			return $cache.container;
		},

		// opens a dialog using the given url
		open : function (params) {
			if (!params.url || params.url.length===0) { return; }

			$cache.container = app.dialog.create(params);
			params.url = app.util.appendParamsToUrl(params.url, {format:"ajax"});

			// finally load the dialog
			app.ajax.load({
				target : $cache.container,
				url : params.url,
				callback : function () {

					if($cache.container.dialog("isOpen")) {	return;	}
					$cache.container.dialog("open");
				}
			});

		},
		// closes the dialog and triggers the "close" event for the dialog
		close : function () {

			if(!$cache.container) {
				return;
			}
			$cache.container.dialog("close");
		},
		// triggers the "apply" event for the dialog
		triggerApply : function () {
			$(this).trigger("dialogApplied");
		},
		// attaches the given callback function upon dialog "apply" event
		onApply : function (callback) {
			if(callback) {
				$(this).bind("dialogApplied", callback);
			}
		},
		// triggers the "delete" event for the dialog
		triggerDelete : function () {
			$(this).trigger("dialogDeleted");
		},
		// attaches the given callback function upon dialog "delete" event
		onDelete : function (callback) {
			if(callback) {
				$(this).bind("dialogDeleted", callback);
			}
		},
		// submits the dialog form with the given action
		submit : function (action) {
			var form = $cache.container.find("form:first");
			// set the action
			$("<input/>").attr({
				name : action,
				type : "hidden"
			}).appendTo(form);

			// serialize the form and get the post url
			var post = form.serialize();
			var url = form.attr("action");

			// post the data and replace current content with response content
			$.ajax({
				type : "POST",
				url : url,
				data : post,
				dataType : "html",
				success : function (data) {
					$cache.container.html(data);
				},
				failure : function (data) {
					window.alert(app.resources.SERVER_ERROR);
				}
			});
		},
		align : function (elClass) {
			try {
				$el = $(elClass);
				parseInt($el.css("top")) < 0 ? $el.css("top", "0") : "";
				$('html, body').stop().animate({
					scrollTop: ($el.offset().top)
				}, 1000);
			} catch(e){}
		},
		settings : {
			autoOpen : false,
			resizable : false,
			bgiframe : true,
			modal : true,
			height : 'auto',
			width : width,
			buttons : {},
			title : '',
			position : 'center',
			overlay : {
				opacity : 0.5,
				background : "black"
			},
			close : function (event, ui) {
				$(this).dialog("destroy");
				$(this).remove();
				app.scrollLock = false;
			}
		}
	};
}(window.app = window.app || {}, jQuery));

// app.faq
(function (app, $) {
	var scrollToTop = function() {
		$('body,html').animate({scrollTop:0},800);
	};

	$('.faq').on('click', 'faq-top', function(e){
		e.preventDefault();
		scrollToTop();
	});

}(window.app = window.app || {}, jQuery));

// app.validator
(function (app, $) {

	var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/,
		regex = {
			phone : {
				us : naPhone,
				ca : naPhone
			},
			postal : {
				us : /^\d{5}(-\d{4})?$/,
				ca : /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
				gb : /^GIR?0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]|[A-HK-Y][0-9]([0-9]|[ABEHMNPRV-Y]))|[0-9][A-HJKS-UW])?[0-9][ABD-HJLNP-UW-Z]{2}$/
			},
			email : /^[\w.%+\-]+@[\w]+([\.\-][\w]+)*\.[\w]{2,6}$/
		},
		settings = {
			ignore: '.suppress, .select2-input, :hidden:not(select)',
			// global form validator settings
			errorClass : 'error',
			errorElement : 'span',
			errorPlacement: function(error, element) {
				var $element = $(element),
					$formRow = $element.closest('.form-row');

				if ($element.is('select')) {
					if(!app.isMobileUserAgent){
						$.select2();
					}
				}

				if ($formRow.length) {
					$formRow.append(error);
				} else {
					$element.after(error);
				}
			},
			success: function ($label, element) {
				if ($(element).is('select')) {
					if(!app.isMobileUserAgent){
						$.select2();
					}
				}
			},
			onkeyup: false,
			onfocusout : function (element) {
				if(!this.checkable(element)) {
					this.element(element);
				}
			}
		};

	function validatePhone(value, el) {
		var country = $(el).closest("form").find(":input.country");
		if(country.length === 0 || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
			return true;
		}

		value = value.replace(/-/g, '').replace(/_/g,'');
		var rgx = regex.phone[country.val().toLowerCase()];
		var isOptional = this.optional(el);
		var isValid = rgx.test($.trim(value));

		return isOptional || isValid;
	}

	function validateEmail(value, el) {
		var isOptional = this.optional(el);
		var isValid = regex.email.test($.trim(value));
		return isOptional || isValid;
	}

	function validateAddress(value){
		var pattern = new RegExp('p[.\\s]*o[.\\s]+box', 'i');
		if(!value.match(pattern)){
			return true;
		}
		return false;
	}
	
	function validateCity(value){
		if(value.length >= 3){
			return true;
		}
		return false;
	}
	
	$.validator.addMethod("city", validateCity, app.resources.INVALID_CITY_LENGTH);
	
	/**
	 * Add phone validation method to jQuery validation plugin.
	 * Text fields must have 'address1' css class to be validated as phone
	 */
	$.validator.addMethod("address1", validateAddress, app.resources.INVALID_ADDRESS1);

	/**
	 * Add phone validation method to jQuery validation plugin.
	 * Text fields must have 'phone' css class to be validated as phone
	 */
	$.validator.addMethod("phone", validatePhone, app.resources.INVALID_PHONE);

	/**
	 * Add email validation method to jQuery validation plugin.
	 * Text fields must have 'email' css class to be validated as email
	 */
	$.validator.addMethod("email", validateEmail, app.resources.INVALID_EMAIL);

	/**
	 * Add gift cert amount validation method to jQuery validation plugin.
	 * Text fields must have 'gift-cert-amount' css class to be validated
	 */
	$.validator.addMethod("gift-cert-amount", function(value, el){
		var isOptional = this.optional(el);
		var isValid = (!isNaN(value)) && (parseFloat(value)>=5) && (parseFloat(value)<=5000);
		return isOptional || isValid;
	}, app.resources.GIFT_CERT_AMOUNT_INVALID);

	/**
	 * Add positive number validation method to jQuery validation plugin.
	 * Text fields must have 'positivenumber' css class to be validated as positivenumber
	 */
	$.validator.addMethod("positivenumber", function (value, element) {
		if($.trim(value).length === 0) { return true; }
		return (!isNaN(value) && Number(value) >= 0);
	}, "");

	// "" should be replaced with error message if needed

	$.validator.messages.required = function ($1, ele, $3) {
		var requiredText = $(ele).closest('.form-row').attr('data-required-text');
		return requiredText||"";
	};


	$.validator.addMethod("zipUS", function(value, element) {
		var country = $(element).closest("form").find(":input.country");

		return !country.length || country.val() !== 'US' || /\d{5}-\d{4}$|^\d{5}$/.test(value);
	}, app.resources.INVALID_POSTAL);

	/**
	 * Add month-year validation for credit cards, and default apply via element attribute
	 */
	var date = new Date(),
		currentMonth = date.getMonth(),
		currentYear = date.getFullYear();

	$.validator.addMethod("creditcardmonth", function (value, element) {
		var $element = $(element),
			$form = $element.closest('form'),
			attribute = $element.attr('data-creditcard-month-target'),
			$year = attribute ? $(attribute + ':first') : $form.find('[data-creditcard-year]:first'),
			yearValue = $year.val(),
			monthValue = parseInt(value, 10) - 1;

		if(app.isMobileUserAgent && yearValue == "Year"){
			return true;
		}

		return !yearValue || monthValue === null || yearValue > currentYear || monthValue >= currentMonth;
	}, app.resources.INVALID_CREDIT_CARD_EXPIRY);

	/**
	 * Add credit card type validation
	 */

	$.validator.addMethod("creditcardtype", function (value, element) {
		var $element = $(element),
			$form = $element.closest('form'),
			$cardNumber = $form.find('[data-creditcard-number]'),
			cardPattern = {
	            'MasterCard': /^5[1-5][0-9]{14}$/,
	            'Visa':       /^4[0-9]{12}(?:[0-9]{3})?$/,
	            'Amex':       /^3[47][0-9]{13}$/,
	            'Discover':   /^6(?:011|5[0-9]{2})[0-9]{12}$/
	        },
	        cardNumber = $cardNumber.val().replace(/ /g, '').replace(/X/g, '');

			if(app.isMobileUserAgent){
				cardTypeValue = $("select[name$='card_type']").val();
			}else {
				cardTypeValue = $form.find('[data-creditcard-type]').parent().find('.select2-choice span').text();
			}

	        isValid = cardPattern[cardTypeValue].test(cardNumber);

		return isValid;

	}, app.resources.INVALID_CREDIT_CARD);

	app.validator = {
		regex : regex,
		settings : settings,
		initMasks : function(){

			$("input[name*='phone']").on('paste', function(e){
			    var pastedData = e.originalEvent.clipboardData.getData('text');
			    pastedData = pastedData.replace(/\D/g,'').slice(-10);
			    $(this).attr('data-pasted',pastedData);
			});

			var keydownEvent = false;
			$("input[name*='phone']").on('keydown input', function(e){
				if(typeof $(this).attr('data-pasted') !== 'undefined'){
				 	$(this).val($(this).attr('data-pasted'));
					$(this).removeAttr('data-pasted');
				}
				if(e.type !== 'keydown'){
					if(!keydownEvent){
						var data = $(this).val();
					    data = data.replace(/\D/g,'').slice(-10);
					    $(this).val(data);
					}
					keydownEvent = false;
				} else {
					if(e.keyCode !== undefined){
						keydownEvent = true;
					}
				}
			});

			$("input[name*='phone']").mask("999-999-9999");

			//$("input[name*='customer_birthday']").mask("99/99/9999");

			//add if was error show we should show correct mask
			var existValue = $("select[name$='paymentMethods_creditCard_type']").length ? $("select[name$='paymentMethods_creditCard_type']").val() : $("select[name$='creditcards_newcreditcard_type']").val();
			if(existValue == 'Amex'){
				$("input[name*='creditCard_number'], input[name*='newcreditcard_number']").mask('9999 9999 9999 999');
			} else {
				$("input[name*='creditCard_number'], input[name*='newcreditcard_number']").mask('9999 9999 9999 9999');
			}

			$("select[name$='paymentMethods_creditCard_type'], select[name$='creditcards_newcreditcard_type']").on('change', function(){
				$("input[name*='creditCard_number'], input[name*='newcreditcard_number']").unmask();
				if($(this).val() == 'Amex'){
					$("input[name*='creditCard_number'], input[name*='newcreditcard_number']").mask('9999 9999 9999 999');
				}else {
					$("input[name*='creditCard_number'], input[name*='newcreditcard_number']").mask('9999 9999 9999 9999');
				}
			});
		},
		init : function () {
			try {
				$("form:not(.suppress)").each(function () {
					$(this).validate(app.validator.settings);
				});

				$('[data-phone-number]').each(function () {
					$(this).rules('add', {
						phone: true
					});
				});

				$('[data-postal-code]').each(function () {
					$(this).rules('add', {
						zipUS: true
					});
				});

				$('[data-email]').each(function () {
					$(this).rules('add', {
						email: true
					});
				});

				$('[data-creditcard-month], [data-creditcard-month-target]').each(function () {
					$(this).rules('add', {
						creditcardmonth: true
					});
				});
				$('[data-creditcard-year]')
					.off('.creditcardexpiration')
					.on('change.creditcardexpiration', function () {
						$(this).valid();
					});

				// creditcardtype validation
				$('[data-creditcard-type]').each(function () {
					$(this).rules('add', {
						creditcardtype: true
					});
				});
				$('[data-creditcard-number]').off('creditcardtype')
					.on('change.creditcardtype', function () {
						$(this).valid();
				});
				this.initMasks();
			}catch(e){
				
			}
			
			
		},
		initForm : function(f) {
			$(f).validate(app.validator.settings);
		}
	};
}(window.app = window.app || {}, jQuery));

// app.ajax
(function (app, $) {

	var currentRequests = [];
	// request cache

	// sub namespace app.ajax.* contains application specific ajax components
	app.ajax = {
		// ajax request to get json response
		// @param - async - boolean - asynchronous or not
		// @param - url - String - uri for the request
		// @param - data - name/value pair data request
		// @param - callback - function - callback function to be called
		// @param - type - String - type of request, POST, GET, etc. Defaults to GET
		getJson : function (options) {
			options.url = app.util.toAbsoluteUrl(options.url);
			// return if no url exists or url matches a current request
			if(!options.url || currentRequests[options.url]) {
				return;
			}

			currentRequests[options.url] = true;

			// make the server call
			$.ajax({
				dataType : "json",
				type : (typeof options.type==="undefined" || options.type===null) ? "GET" : options.type,
				url : options.url,
				async : (typeof options.async==="undefined" || options.async===null) ? true : options.async,
				data : options.data || {}
			})
			// success
			.done(function (response) {
				if(options.callback) {
					options.callback(response);
				}
			})
			// failed
			.fail(function (xhr, textStatus) {
				if(textStatus === "parsererror") {
					window.alert(app.resources.BAD_RESPONSE);
				}
				if(options.callback) {
					options.callback(null);
				}
			})
			// executed on success or fail
			.always(function () {
				// remove current request from hash
				if(currentRequests[options.url]) {
					delete currentRequests[options.url];
				}
			});
		},
		// ajax request to load html response in a given container

		// @param - url - String - uri for the request
		// @param - data - name/value pair data request
		// @param - callback - function - callback function to be called
		// @param - target - Object - Selector or element that will receive content
		load : function (options) {
			options.url = app.util.toAbsoluteUrl(options.url);
			// return if no url exists or url matches a current request
			if(!options.url || currentRequests[options.url]) {
				return;
			}

			currentRequests[options.url] = true;

			// make the server call
			$.ajax({
				dataType : "html",
				url : app.util.appendParamToURL(options.url, "format", "ajax"),
				data : options.data
			})
			.done(function (response) {
				// success
				if(options.target) {
					$(options.target).empty().html(response);
				}
				if(options.callback) {
					options.callback(response);
				}

			})
			.fail(function (xhr, textStatus) {
				// failed
				if(textStatus === "parsererror") {
					window.alert(app.resources.BAD_RESPONSE);
				}
				options.callback(null, textStatus);
			})
			.always(function () {
				app.progress.hide();
				// remove current request from hash
				if(currentRequests[options.url]) {
					delete currentRequests[options.url];
				}
			});
		}
	};
}(window.app = window.app || {}, jQuery));

// app.searchsuggest
(function (app, $) {
	var qlen = 0,
		listTotal = -1,
		listCurrent = -1,
		delay = 300,
		fieldDefault = null,
		suggestionsJson = null,
		$searchForm,
		$searchField,
		$searchContainer,
		$resultsContainer;

	function handleArrowKeys(keyCode) {
		switch (keyCode) {
			case 38:
				// keyUp
				listCurrent = (listCurrent <= 0) ? (listTotal - 1) : (listCurrent - 1);
				break;
			case 40:
				// keyDown
				listCurrent = (listCurrent >= listTotal - 1) ? 0 : listCurrent + 1;
				break;
			default:
				// reset
				listCurrent = -1;
				return false;
		}

		$resultsContainer.children().removeClass("selected").eq(listCurrent).addClass("selected");
		$searchField.val($resultsContainer.find(".selected div.suggestionterm").first().text());
		return true;
	}

	app.searchsuggest = {
		// configuration parameters and required object instances
		init : function (container, defaultValue) {
			// initialize vars
			$searchContainer = $(container);
			$searchForm = $searchContainer.find("form[name='simpleSearch']");
			$searchField = $searchForm.find("input[name='q']");
			fieldDefault = defaultValue;

			// disable browser auto complete
			$searchField.attr("autocomplete", "off");

			// on focus listener (clear default value)
			$searchField.focus(function () {
				if(!$resultsContainer) {
					// create results container if needed
					$resultsContainer = $("<div/>").attr("id", "suggestions").appendTo($searchContainer).css({
						"top" : $searchContainer[0].offsetHeight,
						"left" : 0,
						"width" : $searchField[0].offsetWidth
					});
				}
				if($searchField.val() === fieldDefault) {
					$searchField.val("");
				}
			});
			// on blur listener
			$searchField.blur(function () {
				setTimeout(app.searchsuggest.clearResults, 200);
			});
			// on key up listener
			$searchField.keyup(function (e) {

				// get keyCode (window.event is for IE)
				var keyCode = e.keyCode || window.event.keyCode;

				// check and treat up and down arrows
				if(handleArrowKeys(keyCode)) {
					return;
				}
				// check for an ENTER or ESC
				if(keyCode === 13 || keyCode === 27) {
					app.searchsuggest.clearResults();
					return;
				}

				var lastVal = $searchField.val();

				// if is text, call with delay
				setTimeout(function () { app.searchsuggest.suggest(lastVal); }, delay);
			});
			// on submit we do not submit the form, but change the window location
			// in order to avoid https to http warnings in the browser
			// only if it's not the default value and it's not empty
			$searchForm.submit(function (e) {
				e.preventDefault();
				var searchTerm = $searchField.val();
				if(searchTerm === fieldDefault || searchTerm.length === 0) {
					return false;
				}
				window.location = app.util.appendParamToURL($(this).attr("action"), "q", searchTerm);
			});
		},
		// trigger suggest action
		suggest : function (lastValue) {
			// get the field value
			var part = $searchField.val();

			// if it's empty clear the resuts box and return
			if(part.length === 0) {
				app.searchsuggest.clearResults();
				return;
			}

			// if part is not equal to the value from the initiated call,
			// or there were no results in the last call and the query length
			// is longer than the last query length, return
			// #TODO: improve this to look at the query value and length
			if((lastValue !== part) || (listTotal === 0 && part.length > qlen)) {
				return;
			}
			qlen = part.length;

			// build the request url
			var reqUrl = app.util.appendParamToURL(app.urls.searchsuggest, "q", part);

			// get remote data as JSON
			$.getJSON(reqUrl, function (data) {
				// get the total of results
				var suggestions = data,
					ansLength = suggestions.length,
					listTotal = ansLength;

				// if there are results populate the results div
				if(ansLength === 0) {
					app.searchsuggest.clearResults();
					return;
				}
				suggestionsJson = suggestions;
				var html = "";
				var i, len=ansLength;
				for(i=0; i < len; i++) {
					html+='<div><div class="suggestionterm">'+suggestions[i].suggestion+'</div><span class="hits">'+suggestions[i].hits+'</span></div>';
				}

				// update the results div
				$resultsContainer.html(html).show().on("hover", "div", function () {
					$(this).toggleClass = "selected";
				}).on("click", "div", function () {
					// on click copy suggestion to search field, hide the list and submit the search
					$searchField.val($(this).children(".suggestionterm").text());
					app.searchsuggest.clearResults();
					$searchForm.trigger("submit");
				});
			});
		},
		clearResults : function () {
			if (!$resultsContainer) { return; }
			$resultsContainer.empty().hide();
		}
	};
}(window.app = window.app || {}, jQuery));

// app.searchplaceholder
(function (app, $) {

	function initializeEvents() {
		$('#q').focus(function () {
			var input = $(this);
			if (input.val() === input.attr("placeholder")) {
				input.val("");
			}
		})
		.blur(function () {
			var input = $(this);
			if (input.val() === "" || input.val() === input.attr("placeholder")) {
				input.val(input.attr("placeholder"));
			}
		})
		.blur();
	}

	app.searchplaceholder = {
		init : function () {
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));
/**
 * int_clearpath: Include - code/app.js - 10
 *
 * Add the Clearpath namespace
 */
//app.clearpath
(function (app, $) {
	var $cache = {};

	function initializeCache() {
		$cache.checkoutForm = $("form.address");
		$cache.countryCode = $cache.checkoutForm.find("select[id$='_country']");
		$cache.stateCode = $cache.checkoutForm.find("select[id$='_states_state']");
		$cache.stateCodeInput = $cache.checkoutForm.find("input[id$='_state']");
	}

	function addRequiredToStates(){
		var reqSpan = $('<span/>').addClass('required-indicator').html('&#8226;');

		$cache.stateCode.closest('div').addClass('required');
		$cache.stateCode.closest('div').find('label').prepend(reqSpan);

		$cache.stateCodeInput.closest('div').addClass('required');
		$cache.stateCodeInput.closest('div').find('label').prepend(reqSpan.clone());
	}

	app.clearpath = {
		setStateRequired : function(fieldToShow,fieldToHide){
			//removed div to .form-rom class
			//since select is being wrapped in a div
			fieldToShow.closest('.form-row').show();
			fieldToHide.closest('.form-row').hide();
			$("select").find("[value='OTHER']").hide();
			if(!app.isMobileUserAgent){
				$.select2();
			}
		},
		init : function () {
			initializeCache();

			var countryValue = $cache.countryCode && $cache.countryCode.val();

			if (countryValue && app.countries[countryValue] && app.countries[countryValue].regions) {
					app.clearpath.setStateRequired($cache.stateCode, $cache.stateCodeInput);
			} else {
				$cache.stateCode.find('[value="OTHER"]').prop('selected',true);
				app.clearpath.setStateRequired($cache.stateCodeInput, $cache.stateCode);
			}

			//addRequiredToStates();
			$cache.countryCode.trigger('checkout:updateShippingMethodList');
			//$cache.stateCodeInput.closest('div').hide();
		}
	};
}(window.app = window.app || {}, jQuery));
// jquery extensions
(function ($) {
	// params
	// toggleClass - required
	// triggerSelector - optional. the selector for the element that triggers the event handler. defaults to the child elements of the list.
	// eventName - optional. defaults to 'click'
	$.fn.toggledList = function (options) {
		if (!options.toggleClass) { return this; }

		var list = this;
		function handleToggle(e) {
			e.preventDefault();
			var classTarget = options.triggerSelector ? $(this).parent() : $(this);
			classTarget.toggleClass(options.toggleClass);
			// execute callback if exists
			if (options.callback) { options.callback(); }
		}

		return list.on(options.eventName || "click", options.triggerSelector || list.children(), handleToggle);
	};

	$.fn.syncHeight = function () {
		function sortHeight(a, b) {
			return $(a).height() - $(b).height();
		}

		var arr = $.makeArray(this);
		arr.sort(sortHeight);
		return this.height($(arr[arr.length-1]).height());
	};
}(jQuery));

// general extension functions
(function () {
	String.format = function() {
		var s = arguments[0];
		var i,len=arguments.length - 1;
		for (i = 0; i < len; i++) {
			var reg = new RegExp("\\{" + i + "\\}", "gm");
			s = s.replace(reg, arguments[i + 1]);
		}
		return s;
	};
})();


// add to cart from recommendations
(function () {
	//window.onload = function() {
		$(document).on('click', '.js-add-to-cart', function(e){
			e.preventDefault();
			
			if ($('.build-bundle').length == 0) {
				var data= {
						Quantity:"1",
						cartAction:"add",
						pid:$(this).attr('data-pid'),
						pid_master:$(this).attr('data-master')
				};
				app.cart.update(data, function (response) {
					if (typeof dataLayer != "undefined"){
						dataLayer.push({
							'event': 'add-to-card',
							'bloomreachAddToCart': {
								"prod_id": data.pid,
								"sku": ''
							}
						});
					}
					if($(".cart-order-totals").length){
						window.location.reload();
					}else {
						app.minicart.show(response);
					}

				});
			}
		});
	//};
	$(document).on('recommender-init', function () {
		$('.recommendationtocart').each(function (i, elem) {
			if (!$(elem).hasClass('inited')) {
				$(elem).addClass('inited').on('click', function(e) {
					e.preventDefault();
					
					var data= {
							Quantity:"1",
							cartAction:"add",
							pid:$(this).attr('data-pid'),
							pid_master:$(this).attr('data-master')
					};
					app.cart.update(data, function (response) {
						if (typeof dataLayer != "undefined"){
							dataLayer.push({
								'event': 'add-to-card',
								'bloomreachAddToCart': {
									"prod_id": data.pid,
									"sku": ''
								}
							});
						}
						if($(".cart-order-totals").length){
							window.location.reload();
						}else {
							app.minicart.show(response);
						}

					});
				});	
			}
		});
	});
})();


(function () {
	$(document).on('click', '#cart-table .product-quantity', function(e){
		e.preventDefault();
		var value = $(this).siblings("input").val();
		if (this.getAttribute("data-type")=="+") {
			value++;
		}
		else {
			value--;
		}
		if (value < 1){
			value = 1;
		}
		$(this).siblings("input").val(value);
		app.cart.updateForm();
	});
	$(document).on('keyup', '#cart-table .product-quantity, label.fancyCheck, label.fancyRadio, .button-fancy-large[type="submit"], label.email-policy', function(e){
	    if(e.keyCode == 13){
	        $(this).click();
	    }
	});

})();

//initialize app

jQuery(document).ready(function () {
	app.init();
});


(function () {

	if ($("#cspAssetID").length > 0){
		  var assetID = $('#cspAssetID').text();
		  $('.'+assetID).addClass('activeAsset');
		  $('.'+assetID).parent().parent().prev().addClass('activeFolder');

		  $(document).on('click', '.customerservice-secondary-navigation span.toggle', function(e){
		   $(this).toggleClass('activeFolder');
		  });
	}

})();
(function () {

$(document).on("change", "input[name $= '_address1'], input[name $= '_address2'], input[name $= '_city'], input[name $= '_zip']", function(){
	$(this).val($(this).val().replace(/[^\d\s\-\/A-Za-z]/g,''));
});

})();

function fixADAAcessibilityIssues() {
	// Set aria-label and alt attributes according ADA compliance
	var $moatTrackElements = $('img[id^="moat"]');
	if ($moatTrackElements.length) {
		$moatTrackElements.each(function() {
			$(this).attr('alt', 'moat');
			$(this).attr('aria-hidden', 'true');
		});
	}
	// Set aria-label and alt attributes for olapic-track images
	var $olapicTrackElements = $('img[id*="olapic-track-img"]');
	if ($olapicTrackElements.length) {
		$olapicTrackElements.each(function() {
			$(this).attr('alt', 'ot');
			$(this).attr('aria-hidden', 'true');
		});
	}
	// Set alt text for Tangiblee "Try me" images
	var $tryMeImages = $('.product-gallery-thumbnails').find('.tangiblee-button img');
	if($tryMeImages.length) {
		$tryMeImages.each(function() {
			$(this).attr('alt', 'Try me');
		});
	}

	// Set alt text for TTTrackEvent images
	var $tTTrackImages = $('img[id*="TTTrack"]');
	if($tTTrackImages.length) {
		$tTTrackImages.each(function() {
			$(this).attr('alt', 'tttrack');
			$(this).attr('aria-hidden', 'true');
		});
	}

	var $monetateLightboxImages = $('#monetate_lightbox_content img');
	if($monetateLightboxImages.length) {
		$monetateLightboxImages.each(function() {
			$(this).attr('alt', 'Map area image');
		});
	}

	// var $monetateBadgeImages = $('.product-tile div[id^="monetate_badge"] img');
	// if($monetateBadgeImages.length) {
	// 	$monetateBadgeImages.each(function() {
	// 		$(this).attr('alt', 'Map area image');
	// 	});
	// }

	if ($('.pt_checkout').length) {
		var $pageTitle = $('title[data-mod-by="thrive"]').text();
		if ($pageTitle == "") {
			$('title[data-mod-by="thrive"]').text('Checkout');
		}
	}

	var $lincSynergy = $('head img[src*="track.linksynergy.com"]');
	if ($lincSynergy.length) {
			$lincSynergy.attr('alt', '#');
	}
}

function bindCategoryInfoButtons() {
	try {
		if ($("#plp-category-info").length > 0) {
			
			var $catInfoCnt = $("#plp-category-info .category-info--text"),
			$catInfoW = $catInfoCnt.find(".info-text-w"),
			$showMore = $catInfoCnt.find(".show-more-btn"),
			$showLess = $catInfoCnt.find(".show-less-btn"),
			fullText = $catInfoW.html(),
			slicedText = "";
			
			if (fullText.indexOf('.') < 0) {
				slicedText = fullText;
			} else if (!app.isMobileUserAgent && (fullText.indexOf('.', fullText.indexOf('.') + 1) >= 0)) {
				slicedText = fullText.substring(0, fullText.indexOf('.', fullText.indexOf('.') + 1) + 1);
			} else {
				slicedText = fullText.substring(0, fullText.indexOf('.') + 1);
			}
			
			$catInfoW.attr('data-full-text',fullText);
			
			if (fullText.length == slicedText.length) {
				$catInfoW.html(fullText);
			} else {
				$catInfoW.html(slicedText);
				$showMore.addClass('is-visible');
			}
			$catInfoCnt.addClass('is-visible');

			$showMore.click(function() {
				$catInfoW.html(fullText);
				$showMore.removeClass('is-visible');
				$showLess.addClass('is-visible');
			});
			$showLess.click(function() {
				$catInfoW.html(slicedText);
				$showMore.addClass('is-visible');
				$showLess.removeClass('is-visible');
			});
		}
	}catch(e){}
}

trackGA4CheckoutEvent = function (eventAction, step) {
	var data = window.ga4Data || null;
	if (!data) {return};

	var dataLayerObj = {
		event: data.event,
		eventCategory: data.eventCategory,
		eventAction: eventAction,
		eventLabel: '',
		nonInteractive: data.nonInteractive,
		ecommerce: {
			currencyCode: data.ecommerceCurrencyCode,
		},
		customMetrics:  data.customMetrics,
		customVariables: data.customVariables,
		_clear: data._clear
	}

	dataLayerObj.ecommerce.checkout = {
		actionField: {
			shippingMethod: data.shippingMethod,
			shippingAmount: data.shippingAmount,
			paymentType: data.paymentType,
			productPromoAmounts: data.productPromoAmounts,
			orderPromoAmounts: data.orderPromoAmounts,
			shippingPromoAmounts: data.shippingPromoAmounts,
			productPromoCodes: data.productPromoCodes,
			orderPromoCodes: data.orderPromoCodes,
			shippingPromoCodes: data.shippingPromoCodes,
			productPromoIDs: data.productPromoIDs,
			orderPromoIDs: data.orderPromoIDs,
			shippingPromoIDs: data.shippingPromoIDs,
			step: step
		},
		products: data.products
	}

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(dataLayerObj);
	return;
}

$(window).on('load',function() {
	bindCategoryInfoButtons();
	setTimeout(function() {
		fixADAAcessibilityIssues();
	}, 2000);
});
