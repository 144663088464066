megaMenuInit();

function megaMenuInit(){

    //variables to hold current button information
    var mWidth, //top nav width to set line
    	mPos, //top nav li position to set mm margin if not full menu
    	mName = 'noName', //mega menu id name
    	mmOpen = false, //check to see if mega menu container is displayed
    	i, //close timer variable
    	focusedTab, //top nav element
    	focusedTabA,
      mmCenterLi; //amount of central li elms to define to hide central menu

    //on hover function
    $(document).on("mouseover focusin", "#navigation-menu li.top", function(){
        //close menu if another is already open
        if (mmOpen === true) {
            $(mName).fadeOut(0);
            //remove top tab selected state
            $(focusedTabA).removeClass('selectOver');
        }

        //define this li element
        focusedTab = this;
        focusedTabA = focusedTab.getElementsByTagName('a');
        $(focusedTabA).addClass('selectOver');
        //get the button width,position, name and prepend for the megamenu name
        mWidth = $(focusedTab).width();
        mPos = $(focusedTab).position();
        mName = '#mm-' + $(focusedTab).attr('id');
        //reset megamenu position to 0
        $('div.megaMenu.single').css('left', mPos.left - 40); //accounts for megamenu left position,padding, and margin

        //position line for full width
        //$('div.megaMenu #topline').css('width', mWidth);
        //$('div.megaMenu #topline').css('margin-left', mPos.left); //accounts for topnav left position,padding, and margin
        //$('div.megaMenu.single #topline').css('margin-left', 40);
        
        //add class to resize MegaMenu if no mm-center elements
       /* if ( $(mName).find('.mmCenter h3').html() == 'SHOP BY CATEGORY:' ) {

          mmCenterLi = $(mName).find('.mmCenter ul li');
          if (mmCenterLi.length == 0) {
            $(mName).addClass('single-mmLeft');
            $(mName).find('.mmCenter').addClass('mmCenter-hide');
            $('div.megaMenu').css('left', 0);
            $('div.megaMenu #topline').css('margin-left', -25);
          }
        }*/
        
        
        //define if mm is open
        mmOpen = true;
        //fade the menu in
        $(mName).stop(true, true).fadeIn({
            duration: 0,
            queue: false
        }).css('display', 'none').fadeIn(0);
        	mmOpen = true;
        if($(".lazyload").length){
            app.lazyloadImage(".lazyload");
        }

    
    });
    $(document).on("mouseout", "#navigation-menu li.top", function(){
        $('div.megaMenu').mouseleave(function () {
            $(focusedTabA).removeClass('selectOver');
            mmOpen = false;
            $(mName).fadeOut(0);
            if($(".lazyload").length){
            	app.lazyloadImage(".lazyload");
    	    }
        });
        if($(".lazyload").length){
        	app.lazyloadImage(".lazyload");
	    }
    });
  
    $('.expandedright').hover(function(){
    	var height = $(this).closest('div.megaMenu').height();
    	var height2 = $(this).children('.rightcolumn').height();    	
    	if (height <  height2){
    		$(this).closest('div.megaMenu').css({'height': height2});
    	}
    	else {
    		$(this).children('.rightcolumn').css({'height': height});
    	}
    	$(this).children('.rightcolumn').css({'left': $(this).closest('.mmCenter').width()+20});
    	
    });
    
    //set mm close timer when mouse is off main navigation
    $('#navigation-menu').mouseleave(function () {
        i = window.setTimeout(function () {
            timedClose();
        }, 300);
    });

    //close mm once timer is expired
    function timedClose() {
        $(focusedTabA).removeClass('selectOver');
        mmOpen = false;

        //if no megamenu name has been added due to hoverIntent delay, don't fade it out.
        if ($(mName) !== 'noName') {
            $(mName).fadeOut(0);
        }
        window.clearInterval(i);
    }

    //if container is open and mouse is hovered, stop timedClose timer
    $('div.megaMenu').mouseover(function () {
        window.clearInterval(i);
    });
    //$('#nav-new').on('click', function(){
   // 	window.open($(this).attr('href'),"_self");
    //});
    
}