$(function(){
	$(".product-image img").on('error', function(){
		$(this).attr('src', $(".js-no-image").val());
	});

	$(".js-tap-mobile-footer-banner").click(function(e){
		e.preventDefault();
		$("#footer-banner-sticky").toggleClass('opened', "slow");
	});
	if(app.isMobileUserAgent){
		  $(window).scroll(function(event) {
			    if(app.scrollLock) {
			    	$(window).scrollTop(app.previousScrollTop);
			    }
			    app.previousScrollTop = $(window).scrollTop();
			});
	}

	$(document).on('click', '.js-open-accordion-content', function(event){
		event.preventDefault();
        $(this).toggleClass('active');
        $(this)
            .next('.product-info-accordion-content')
            .toggleClass('active');
	});


	$(document).on('click', '.js-monogram-mobile', function(e){
		e.preventDefault();
		if($(".svconfigurator").length){
			if($(".product-set-details").length){
				$(this).parents(".product-set-details").find(".svconfigurator").trigger("click");
			}else {
				$(".svconfigurator").trigger('click');
			}
		}
		return false;
	});

	$(document).on('click', '.js-action', function(e){
		e.preventDefault();
		if($(".product-set-details").length){
			var input = $(this).parents('.mobile-quantity-parent').find(".js-mobile-quantity");
		}else {
			var input = $(".js-mobile-quantity");
		}

		var currentValue = parseInt(input.val()),
			maxValue = input.data('max'),
			type = $(this).data('type');
		if(type == '+'){
			var newVal = currentValue + 1;
			if(newVal >= 1 && newVal <= maxValue){
				currentValue = newVal;
			}
		}else {
			var newVal = currentValue - 1;
			if(newVal >= 1){
				currentValue = newVal;
			}
		}
		input.val(currentValue);
		if($(".product-set-details").length){
			$(this).parents(".product-set-details").find('select[name="Quantity"]').val(currentValue);
		}else {
			$('select[name="Quantity"]').val(currentValue);
		}

		return false;
	});

	$(document).on('click', '.js-action-cart', function(e){
		e.preventDefault();
		var input = $(this).parents('div.checkout-mobile-quantity').find('input.mobile-quantity-input');
		var currentValue = parseInt(input.val()),
			maxValue = input.data('max'),
			type = $(this).data('type');
		if(type == '+'){
			var newVal = currentValue + 1;
			if(newVal >= 1 && newVal <= maxValue){
				currentValue = newVal;
			}
		}else {
			var newVal = currentValue - 1;
			if(newVal >= 1){
				currentValue = newVal;
			}
		}
		input.val(currentValue);

		app.cart.updateForm();

	});
	
	$(document).on('keyup', '.js-action, .js-action-cart', function(e){
	    if(e.keyCode == 13){
	        $(this).click();
	    }
	});
	
		$(document).on('click', '.js-toggle-filter', function(event){
			 event.preventDefault();
			    if($(this).hasClass('active')){
			    	$(this).removeClass('active');
			    	$('.filter-box').removeClass('active');
			    }else {
			    	var openBlock = $(this).data('open');
				    $('.js-toggle-filter').removeClass('active');
				    $(this).addClass('active');

				    $('.filter-box').removeClass('active');
				    $('.' + openBlock).addClass('active');
			    }
		});

		$(document).on('click', '.js-filter-box-link', function(event){
	    event.preventDefault();
	    $(this).toggleClass('active');
	  });

	if(app.isMobileUserAgent){
		$(document).on('click', '.swatches.Color li.emptyswatch', function(){
			$('html, body').animate({scrollTop: $('.pdp-main').offset().top},500);
		})
	}
	if(app.isMobileUserAgent) {
		$(document).on('ready', function(){
			$('.checkout-step-title.active ~ form').slideDown(1000);

			return false;
		})
	}
});
