/**
 * app.gridswitcher
 *
 * @param app
 * @param $
 */
(function(app, $) {
	var $cache = {};

	app.gridswitcher = {
		/**
		 * Initialization
		 * Init the cache, events, and any other modules that are dependent
		 * on this module.
		 */
		init : function() {
			this.initCache();
			this.initEvents();
		},

		/**
		 * Initialize the cache object
		 *
		 * The cache object contains attributes and properties that are used
		 * repeatedly throughout this module.
		 */
		initCache : function() {
			$cache = {
				document : $(document),
				gridWrapSel : '.js-change_view',
				gridItemSel : '.js-change_view-type',
				activeCls : 'active',
				expiration : new Date(),
				transitionEvent : app.util.getTransEndEvent()
			};
			$cache.expiration.setTime($cache.expiration.getTime() + 1000 * 365 * 24 * 60 * 60);
		},

		/**
		 * Initialize related events
		 */
		initEvents : function() {

			$cache.document.on('click', $cache.gridItemSel, function(e){

				var $this = $(this),
					$wrapper = $cache.document.find('.js-change_view'),
					$productTiles = $cache.document.find(".product-tile"),
					$productList = $cache.document.find('#search-result-items'),
					gridClass = $this.data('grid-class');

				if(!$this.hasClass($cache.activeCls)) {
					$wrapper.find($cache.gridItemSel).removeClass($cache.activeCls);
					$this.addClass($cache.activeCls);
					console.log($cache.gridItemSel.length);

					$wrapper.removeClass(function(index, classes){
						var matches = classes.match(/\m-columns-\S+/ig);
						return matches ? matches.join(' ') : '';
					});
					$wrapper.addClass(gridClass);
					$productTiles.css('height', 'auto');		
					setTimeout(function(){
						setCookie('gridViewClass', gridClass, {expires: 5184000000, path: '/'});
					}, 1000);					
					$productTiles.one($cache.transitionEvent, function(){
						$productTiles.add('.grid-tile>.content-asset').syncHeight();
					});
				}
			});
		}
	};

}(window.app = window.app || {}, jQuery));
