/* PAYPAL */
function paypal(paypalToken) {
	
	if (paypalToken) {
	
		var checkoutForm = $("form.address");
		var countrySelect = $(checkoutForm).find("select[id$='_country']");
		
		if (countrySelect.val() != 'US') {
			countrySelect.trigger('change');
		}
	
	}
	
}

