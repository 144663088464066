$(document).ready(function(){

	if($('.gift-finder--container').length){

		var url_catalog = window.url_catalog_gift_finder,
			url_to_check = window.url_to_check,
			url_catalog_category = window.url_catalog_gift_finder,
			url_to_check_category = window.url_to_check,
	        $gift_finder_container = $('.gift-finder--container'),
	        $js_gift_finder_step = $('.js-gift-finder-step'),
	        $gift_finder_carousel_container = $('.gift-finder--carousel-container');

	    updateUrlButton();
	
	    $('.js-gift-finder-button-select').click(function(e){

	        e.preventDefault();

	        var step = $(this).closest('.js-gift-finder-step');
	
	        if($(this).hasClass('js-disabled')){
	            return false;
	        }

	        $(this).toggleClass('js-selected');
	        step.find('.js-gift-finder-button-select').not($(this)).removeClass('js-selected');

           	if($(this).hasClass('js-selected')){
            	$gift_finder_carousel_container.slick('slickNext');
            }

            updateUrlButton();

            if(!step.hasClass('js-gift-finder-last-step')){
            	updateDisabledOptions();
    		}

	    });
	    
	    function updateUrlButton(){
    		
	    	var firstStepSelected = $('.backpack-finder-button-select.js-selected[data-url-attr-name="category-url"]');
	    	
    		if(firstStepSelected.length){
	        	url_catalog = firstStepSelected.data('url-attr-value');
	        	url_to_check = firstStepSelected.data('url-to-check');
	        	url_catalog_category = firstStepSelected.data('url-attr-value');
	        	url_to_check_category = firstStepSelected.data('url-to-check');
	        } else {
	        	url_catalog = window.url_catalog_gift_finder;
				url_to_check = window.url_to_check;
				url_catalog_category = window.url_catalog_gift_finder;
				url_to_check_category = window.url_to_check;
	        }
	    	
	    	$('.backpack-finder-button-select.js-selected').each(function(){
		        
		        if($(this).data('url-attr-name') === 'price'){
		        	url_catalog += $(this).data('url-attr-value');
		        	url_to_check += $(this).data('url-attr-value');
		        }
		        
		        if($(this).data('url-attr-name') === 'cat'){
		        	url_catalog += $(this).data('url-attr-value');
			    	url_to_check += $(this).data('url-attr-value');
		        }

		        if($(this).data('url-attr-name') === 'backPackColorTypeFour'){
		        	url_catalog += $(this).data('url-attr-value');
		        	url_to_check += $(this).data('url-attr-value');
		        }

	    	});
	    	
        	if (url_catalog.indexOf('categoryFilter=true') === -1) {
	    		url_catalog += '&display=9999&sz=9999&categoryFilter=true';
	    	}

	    	$('.js-gift-finder-link-to-catalog-button').attr('href',url_catalog);

	    }

	    function updateDisabledOptions(){

	    	var stepToCheck = $('.gift-finder--carousel-container .slick-current');
			var stepToCheckButtons = stepToCheck.find('.js-gift-finder-button-select');

			if(stepToCheck.length){

				var total = stepToCheckButtons.length,
					total_check = 0;
				
				stepToCheck.addClass('loader');
				
		    	stepToCheckButtons.each(function (i,elem){
		    		var urlToCheck = url_to_check + $(elem).data('url-attr-value');
		    		$.ajax({
		    			method: 'GET',
		    			url: urlToCheck,
		    			success: function(data){
		    				if(data.count <= 0 && data.count !== undefined){
		    					$(elem).addClass('js-disabled');
		    				} else {
		    					$(elem).removeClass('js-disabled');
		    				}
		    				total_check++;
		    				if (total_check >= total){
		    					stepToCheck.removeClass('loader');
		    				}
		    				if(!stepToCheck.find('.js-gift-finder-button-select').not('.js-disabled').length){
		    					stepToCheck.find('.js-gift-finder-link-to-catalog-button').hide();
		    					stepToCheck.find('.js-gift-finder-next-button').hide();
		    				} else {
		    					stepToCheck.find('.js-gift-finder-link-to-catalog-button').show();
		    					stepToCheck.find('.js-gift-finder-next-button').show();
		    				}
		    			}
		    		});
		    	});

		    }

	    }
	
	    $('.js-gift-finder-start-button').click(function(e){
	        e.preventDefault();
	        $gift_finder_container.addClass('finder__active');
	        screenScroll();
	    });
	
	    $('.js-gift-finder-next-button').click(function(e){
	        e.preventDefault();
	        updateUrlButton();
            updateDisabledOptions();
	        $gift_finder_carousel_container.slick('slickNext');
			screenScroll();
	    });
	    
	    $('.js-gift-finder-prev-button').click(function(e){
	        e.preventDefault();
	        $(this).closest('.js-gift-finder-step').find('.js-selected').removeClass('js-selected');
	        updateUrlButton();
            updateDisabledOptions();
	        $gift_finder_carousel_container.slick('slickPrev');
			screenScroll();
	    });

	}

	function screenScroll(){
		$('html, body').animate({
            scrollTop: $(".gift-finder--carousel-container").offset().top - $('#header').outerHeight()
        },800);
	}
	
	function getCookie(name) {
	    var nameEQ = name + "=";
	    var ca = document.cookie.split(';');
	    for(var i=0;i < ca.length;i++) {
	        var c = ca[i];
	        while (c.charAt(0)==' ') c = c.substring(1,c.length);
	        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	    }
	    return null;
	}

	function eraseCookie(name) {   
	    document.cookie = name+'=; Max-Age=-99999999;';  
	}
	
	if (window.location.href.indexOf('isGiftFinder') !== -1) {
		var loadmore_gift_button = getCookie('loadmore_gift_button');
		//eraseCookie('loadmore_gift_button');
		
		$('.grid-tile').each(function (i, elem) {
	        if (i > 5) {
	            $(elem).addClass('hidden-gift-more');
	        }
	    });
		$(".js-change_view-type.type-3 ").click();

	    if ($('.hidden-gift-more').length > 0) {
	        $('[id="search-result-items"]').append('<a href="" class="js-load-more-gifts"><img alt="Load more" class="gift-finder-load-more" src="'+loadmore_gift_button+'" /></a>');
	    }

	    $(document).on('click', '.js-load-more-gifts', function (e) {
	        e.preventDefault();

	        $('.grid-tile.hidden-gift-more').each(function (i, elem) {
	            if (i < 6) {
	                $(elem).removeClass('hidden-gift-more');
	            }
	        });

	        if ($('.grid-tile.hidden-gift-more').length === 0) {
	            $('.js-load-more-gifts').remove();
	        }
	    });
	    
	    if (window.matchMedia("(max-width: 960px)").matches) {
	    	$('.pt_product-search-result #main #primary').css('min-height', '0');
	    }
	}
	
	var referrer = document.referrer;
	
    if (referrer.indexOf('gift-finder') > -1 && $('.search-result-content').length > 0) {
    	var bpFinderButtom = '<div class="bp-finder__reset-button gift-finder__reset-button"><a href="/gift-finder/">Gift Finder</a></div>';
        $('.search-result-content').css('position', 'relative').addClass('gift-finder-btn-added').append(bpFinderButtom);
    }
});
