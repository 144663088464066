$(document).ready(function(){
	
	$('.backpak-finder--carousel-container').slick({
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		fade: true,
		swipe: false,
		adaptiveHeight: true
	});
	
	if($(".js-isbackpackfinder").length){
		
	    var result_arr = window.buildbackpack,
	        options_arr = [],
	        url_catalog = window.backpackmainurl,
	        $backpack_finder_container = $('.backpack-finder--container'),
	        $js_backpack_finder_step = $('.js-backpack-finder-step'),
	        $backpak_finder_carousel_container = $('.backpak-finder--carousel-container');
	
	    var updateResultArray = function(){
	        result_arr = [];
	        for (var i in window.buildbackpack) {
	        	var passed = [];
	        	for (var type in options_arr) {
	        		var a = window.buildbackpack[i][type];
	        		var b = options_arr[type];
	        		var values = [];
	        		for(var value in b) {
	        			values.push(a.indexOf(b[value]) > -1);
	        		}
	        		passed.push(values.indexOf(false) === -1);
	
	        	}
	        	if(passed.indexOf(false) === -1){
	        		result_arr[i] = window.buildbackpack[i];
	        	}
	        }
	        return true;
	    }
	
	    var updateOptions = function () {
	        options_arr = [];
	
	        if ($js_backpack_finder_step.length > 0) {
	            $js_backpack_finder_step.each(function (i, elem) {
	            	var $js_selected = $(elem).find('.js-selected');
	                if ($js_selected.length > 0) {
	                    //Name of search in array
	                    var name = $(elem).data('compare-js-name');
	                    $js_selected.each(function (j, selected) {
	                        //Value to search in array
	                        var value = $(selected).data('compare-js-value');
	                        if (!options_arr[name]) {
	                            options_arr[name] = [];
	                        }
	                        if (options_arr[name].indexOf(value) === -1) {
	                            options_arr[name].push(value);
	                        }
	                    });
	                }
	            });
	        }
	
	        return true;
	    }
	
	    var updateDisabledButtons = function (step) {
	    	var $js_backpack_finder_button_select = step.find('.js-backpack-finder-button-select');
	    	$js_backpack_finder_button_select.removeClass('js-disabled');
	
	        for (var i in result_arr) {
	            for (var k in result_arr[i]) {
	                if (step.data('compare-js-name') === k) {
	                	$js_backpack_finder_button_select.each(function (r, elem) {
	                		if (Array.isArray(result_arr[i][k])) {
	                			if (result_arr[i][k].includes($(elem).data('compare-js-value'))) {
	                				$(elem).addClass('js-not-disabled');
	                			}
	                		} else {
	                			if ($(elem).data('compare-js-value') === result_arr[i][k]) {
	                                $(elem).addClass('js-not-disabled');
	                            }
	                		}
	                    });
	                }
	            }
	        }
	
	        step.find('.js-backpack-finder-button-select:not(.js-not-disabled)').addClass('js-disabled');
	        step.find('.js-backpack-finder-button-select.js-disabled.not-active-step').removeClass('js-disabled');
	        step.find('.js-backpack-finder-button-select.js-not-disabled').removeClass('js-not-disabled');
	
	        return true;
	    }
	
	    var updateUlrTarget = function () {
	        var url = url_catalog,
	        	sep_url = '&',
	        	step_url = 1;
	
	        $js_backpack_finder_step.each(function(i, elem) {
	        	var url_value = '',
	        		url_name = '';
	        	$(elem).find('.js-selected').each(function (j, selected) {
	        		url_name = $(selected).data('url-attr-name'),
	                url_value += $(selected).data('url-attr-value');
	        	});
	
	        	if (url_name !== '' && url_value !== '') {
	        		var lastChar = url_value.slice(-1);
	        		if (lastChar == '|') {
	        			url_value = url_value.slice(0, -1);
	        		}
	        		url += sep_url + 'prefn' + step_url.toString() + '=' + url_name + '&prefv' + step_url.toString() + '=' + url_value;
	        		sep_url = '&';
	        		step_url++;
	        	}
	        });
	
	        $('.js-backpack-finder-link-to-catalog-button').attr('href', url);
	
	        return true;
	    }
	
	    $('.js-backpack-finder-button-select').click(function (e) {
	        e.preventDefault();
	
	        if ($(this).hasClass('js-disabled')) {
	            return false;
	        }
	
	        if ($(this).hasClass('js-backpack-finder-button-select-only-one')) {
	            $(this).closest('.js-backpack-finder-step').find('.js-backpack-finder-button-select').removeClass('js-selected');
	
	            $backpak_finder_carousel_container.slick('slickNext');
	        }
	
	        if ($(this).hasClass('not-active-step')) {
	            return false;
	        }
	
	        $(this).toggleClass('js-selected');
	
	        if (updateOptions()) {
	            if ($(this).hasClass('js-backpack-finder-button-select-only-one')) {
	                if (updateResultArray()) {
	                    var next = $(this).closest('.js-backpack-finder-step').next();
	                    if (next !== '' && next !== undefined && next !== null) {
	                        updateDisabledButtons(next);
	                    }
	                    updateUlrTarget();
	                }
	            } else {
	            	updateResultArray();
	            	updateDisabledButtons($(this).closest('.js-backpack-finder-step'));
	                updateUlrTarget();
	            }
	        }
	    });
	
	    $('.js-backpack-finder-prev-button').click(function (e) {
	        e.preventDefault();
	
	        $(this).closest('.js-backpack-finder-step').find('.js-backpack-finder-button-select').removeClass('js-selected');
	        var prev = $(this).closest('.js-backpack-finder-step').prev();
	        prev.find('.js-backpack-finder-button-select').removeClass('js-selected');
	        updateOptions();
	        updateResultArray();
	        updateUlrTarget();
	
	        if (prev !== '' && prev !== undefined && prev !== null) {
	            updateDisabledButtons(prev);
	        }
	
	        $backpak_finder_carousel_container.slick('slickPrev');
	    });
	
	    $('.js-backpack-finder-start-button').click(function (e) {
	        e.preventDefault();
	
	        $backpack_finder_container.addClass('finder__active');
	    });
	
	    $('.js-backpack-finder-next-button').click(function (e) {
	        e.preventDefault();
	
	        var next = $(this).closest('.js-backpack-finder-step').next();
	
	        if (next !== '' && next !== undefined && next !== null) {
	            updateDisabledButtons(next);
	        }
	
	        $backpak_finder_carousel_container.slick('slickNext');
	    });
	    
	}
	
});
