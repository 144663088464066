$(document).ready(function () {
    if ($('.nowtrending-landing').length > 0) {
        /**REMOVE BREADCRUMBS**/
        $('.breadcrumb').remove();

        /**HEADER FIX**/
        $('[id="header"]').css({
            'position': 'fixed',
            'left': '0',
            'right': '0',
            'top': '0'
        });

        $('body').css({
            'margin-top': $('[id="header"]').height().toString() + 'px'
        });

        /**REMOVE BLACK FOOTER**/
        $('[id="footer-banner-sticky"] .footer-banner-navigation-main').remove();
        
        /**TALKABLE REMOVE**/
        $('body').addClass('talkable-hide');

        if (window.matchMedia("(max-width: 600px)").matches) {
            /**
             * ----------------
             * MOBILE ANIMATION
             * ----------------
             */

            /**
             * FIRST SCREEN
             */

            /**
             * First element animation
             */
            var topTurn = parseInt($('.nowtrending-landing-main-img .text').css('bottom')) + $(window).height() - $('.nowtrending-landing-main-img .text').offset().top + 10,
                heightEl = $(window).height() - $('.nowtrending-landing-main-img .text').offset().top + 10;

            topTurn *= -1;
            heightEl *= -1;

            $('.nowtrending-landing-main-img .text').css({
                'bottom': parseInt(heightEl).toString() + 'px'
            });

            const path1 = {x: 0, y: topTurn};

            const tween1 = new TimelineMax();

            tween1
                .to('.nowtrending-landing-main-img .text', 1, path1, 0);

            const controller1 = new ScrollMagic.Controller();

            var triggerHook = (($('[id="header"]').height() * 100) / $(window).height()) / 100;

            const scene1 = new ScrollMagic.Scene({
                triggerElement: '.nowtrending-landing-hero',
                duration: 300,
                triggerHook: triggerHook
            })
                .setTween(tween1)
                .setPin(".nowtrending-landing-hero")
                .addTo(controller1)

            /**
             * Third element animation
             */
            var img = $('.nowtrending-landing-section-mobile-images .nowtrending-landing-section-mobile-image-1 img');

            img.clone().load(function () {
                img.Dwidth = $(this).width();
                var img_w = img.Dwidth;

                var leftTurn = img_w - ($(window).width() * 0.30),
                    widthEl = img_w - $(window).width();

                $('.nowtrending-landing-section-mobile-images .nowtrending-landing-section-mobile-image-1 img').css({
                    'right': parseInt(widthEl).toString() + 'px'
                });

                const path3 = {x: leftTurn, y: 0};

                const tween3 = new TimelineMax();

                tween3
                    .to('.nowtrending-landing-section-mobile-images .nowtrending-landing-section-mobile-image-1 img', 1, path3, 0);

                const controller3 = new ScrollMagic.Controller();

                var triggerHook = (($('[id="header"]').height() * 100) / $(window).height()) / 100;

                const scene3 = new ScrollMagic.Scene({
                    triggerElement: '.nowtrending-landing-section-mobile-images',
                    duration: 400,
                    triggerHook: triggerHook
                })
                    .setTween(tween3)
                    .addTo(controller3)

            }).appendTo(img.parent()).hide();

            /**
             * Fifth element animation
             */
            var img5 = $('.nowtrending-landing-section-2-add-img img');

            img5.clone().load(function () {
                img5.Dwidth = $(this).width();
                var img_w = img5.Dwidth;

                var leftTurn = $(window).width(),
                    leftTurn_1 = img_w,
                    widthEl = img_w;

                $('.nowtrending-landing-section-2-add-img img').css({
                    'right': parseInt(widthEl).toString() + 'px'
                });

                const path5 = {x: leftTurn, y: 0};
                const path5_1 = {x: leftTurn_1, y: 0};

                const tween5 = new TimelineMax();

                tween5
                    .to('.nowtrending-landing-section-2-add-img img', 1, path5, 0);

                const controller5 = new ScrollMagic.Controller();

                var triggerHook = (($('[id="header"]').height() * 100) / $(window).height()) / 100;

                const scene5 = new ScrollMagic.Scene({
                    triggerElement: '.nowtrending-landing-section-mobile-images .nowtrending-landing-section-mobile-image-2',
                    duration: 300,
                    triggerHook: triggerHook
                })
                    .setTween(tween5)
                    .addTo(controller5)

                const tween5_1 = new TimelineMax();

                tween5_1
                    .to('.nowtrending-landing-section-2-add-img img', 1, path5_1, 0);

                const controller5_1 = new ScrollMagic.Controller();

                const scene5_1 = new ScrollMagic.Scene({
                    triggerElement: '.nowtrending-landing-section-2',
                    duration: 500,
                    triggerHook: triggerHook
                })
                    .setTween(tween5_1)
                    .setPin(".nowtrending-landing-section-2")
                    .addTo(controller5_1)

            }).appendTo(img5.parent()).hide();
            /**
             * Six element animation
             */
            var img6 = $('.nowtrending-landing-section-3-image-1 img');

            img6.clone().load(function () {
                var img_w = $(window).width();

                var leftTurn = img_w,
                    widthEl = img_w;

                widthEl *= -1;
                leftTurn *= -1;

                $('.nowtrending-landing-section-3-image-1 img').css({
                    'right': parseInt(widthEl).toString() + 'px'
                });

                const path6 = {x: leftTurn, y: 0};

                const tween6 = new TimelineMax();

                tween6
                    .to('.nowtrending-landing-section-3-image-1 img', 1, path6, 0);

                const controller6 = new ScrollMagic.Controller();

                var triggerHook = ((($('.nowtrending-landing-section-2-add-img img').height() - ($('.nowtrending-landing-section-2-add-img img').height() / 4) + $('[id="header"]').height()) * 100) / $(window).height()) / 100;
                console.log(triggerHook);

                const scene6 = new ScrollMagic.Scene({
                    triggerElement: '.nowtrending-landing-section-3-image-1',
                    duration: 300,
                    triggerHook: triggerHook
                })
                    .setTween(tween6)
                    .addTo(controller6)
            }).appendTo(img6.parent()).hide();

            /**
             * Seven element animation
             */
            var img7 = $('.nowtrending-landing-section-3-image-2 img');

            img7.clone().load(function () {
                var img_w = $(window).width();

                var leftTurn = img_w,
                    widthEl = img_w;

                $('.nowtrending-landing-section-3-image-2 img').css({
                    'right': parseInt(widthEl).toString() + 'px'
                });

                const path7 = {x: leftTurn, y: 0};

                const tween7 = new TimelineMax();

                tween7
                    .to('.nowtrending-landing-section-3-image-2 img', 1, path7, 0);

                const controller7 = new ScrollMagic.Controller();

                //var triggerHook = ((($('.nowtrending-landing-section-3-image-1 img').height() + ($('.nowtrending-landing-section-3-image-1 img').height() / 3) + $('[id="header"]').height()) * 100) / $(window).height()) / 100;
                
                const scene7 = new ScrollMagic.Scene({
                    triggerElement: '.nowtrending-landing-section-3-image-2',
                    duration: 300,
                    triggerHook: 1
                })
                    .setTween(tween7)
                    .addTo(controller7)
            }).appendTo(img7.parent()).hide();
        } else {
            /**
             * -----------------
             * DESKTOP ANIMATION
             * -----------------
             */

            /**
             * FIRST SCREEN
             */

            /**
             * First element animation
             */

            var leftTurn = $('.nowtrending-landing-main-img img').width(),
                widthEl = $('.nowtrending-landing-main-img .text').width();

            leftTurn *= -1;
            widthEl *= -1;

            $('.nowtrending-landing-main-img .text').css({
                'right': parseInt(widthEl).toString() + 'px'
            });

            const path1 = {x: leftTurn, y: 0};

            /**
             * Second element animation
             */
            var leftTurn = $('.nowtrending-landing-main-img img').offset().left - $('.nowtrending-landing-add-images-1').parent().offset().left + 10,
                widthEl = $('.nowtrending-landing-add-images-1').width() + 25;

            widthEl *= -1;

            $('.nowtrending-landing-add-images-1').css({
                'left': parseInt(leftTurn).toString() + 'px'
            });

            const path2 = {x: widthEl, y: 0};

            /**
             * Third element animation
             */
            var leftTurn = $('.nowtrending-landing-main-img img').offset().left - $('.nowtrending-landing-add-images-2').parent().offset().left + $('.nowtrending-landing-main-img img').width() - $('.nowtrending-landing-add-images-2').width() - 10,
                widthEl = $('.nowtrending-landing-add-images-2').width() + 25;

            $('.nowtrending-landing-add-images-2').css({
                'left': parseInt(leftTurn).toString() + 'px'
            });

            const path3 = {x: widthEl, y: 0};

            const tween1 = new TimelineMax();

            tween1
                .to('.nowtrending-landing-main-img .text', 1, path1, 0)
                .to('.nowtrending-landing-add-images-1', 1, path2, 1)
                .to('.nowtrending-landing-add-images-2', 1, path3, 1);

            const controller1 = new ScrollMagic.Controller();

            var triggerHook = (($('[id="header"]').height() * 100) / $(window).height()) / 100;

            const scene1 = new ScrollMagic.Scene({
                triggerElement: '.nowtrending-landing-hero',
                duration: 500,
                triggerHook: triggerHook
            })
                .setTween(tween1)
                .setPin(".nowtrending-landing-hero")
                .addTo(controller1)

            /**
             * SECOND SCREEN
             */

            /**
             * First element animation
             */
            var leftTurn = $('.nowtrending-landing-section-2-add-img img').width() + 10,
                widthEl = $('.nowtrending-landing-section-2-add-img img').width() + 10;

            widthEl *= -1;

            $('.nowtrending-landing-section-2-add-img').css({
                'left': parseInt(widthEl).toString() + 'px'
            });

            const path2_1 = {x: leftTurn, y: 0};

            const tween2 = new TimelineMax();

            tween2
                .to('.nowtrending-landing-section-2-add-img', 1, path2_1);

            const controller2 = new ScrollMagic.Controller();

            if (window.matchMedia("(max-width: 960px)").matches) {
                var triggerHook = (($('[id="header"]').height() * 100) / $(window).height()) / 100;
            } else {
                var triggerHook = ((($('[id="header"]').height() - 100) * 100) / $(window).height()) / 100;
            }

            const scene2 = new ScrollMagic.Scene({
                triggerElement: '.nowtrending-landing-section-2',
                duration: 500,
                triggerHook: triggerHook
            })
                .setTween(tween2)
                .setPin(".nowtrending-landing-section-2")
                .addTo(controller2)

            /**
             * THIRD SCREEN
             */

            /**
             * First element animation
             */
            var topTurn = $('.nowtrending-landing-section-3').height() + 50,
                heightEl = $('.nowtrending-landing-section-3').height() + 50;

            heightEl *= -1;

            $('.nowtrending-landing-section-3').css({
                'top': parseInt(heightEl).toString() + 'px'
            });

            const path3_1 = {
                x: 0,
                y: topTurn,
                onStart: function () {
                    $('.nowtrending-landing-section-3').css({
                        'opacity': '1'
                    });
                },
                onReverseComplete: function () {
                    $('.nowtrending-landing-section-3').css({
                        'opacity': '0'
                    });
                }
            };

            const tween3 = new TimelineMax();

            tween3
                .to('.nowtrending-landing-section-3', 1, path3_1, 0);

            const controller3 = new ScrollMagic.Controller();

            if (window.matchMedia("(max-width: 960px)").matches) {
                var triggerHook = (($('[id="header"]').height() * 100) / $(window).height()) / 100;
            } else {
                var triggerHook = ((($('[id="header"]').height() - 100) * 100) / $(window).height()) / 100;
            }

            const scene3 = new ScrollMagic.Scene({
                triggerElement: '.trigger-nowtrending-landing-section-3',
                duration: 350,
                triggerHook: triggerHook
            })
                .setTween(tween3)
                .addTo(controller3)
        }
    }
});